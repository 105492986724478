import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { isMobile } from "react-device-detect";

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.purchaseAnnual = this.purchaseAnnual.bind(this);
    this.purchaseMonthly = this.purchaseMonthly.bind(this);
    this.purchaseQuarterly = this.purchaseQuarterly.bind(this);
    this.purchaseBiAnnually = this.purchaseBiAnnually.bind(this);
  }

  componentDidMount() {
    //console.log("Called Here Too ******");
    const { hash } = window.location;
    if (hash !== "") {
      setTimeout(
        () => {
          const id = hash.replace("#", "");
          //console.log("Pricing ID is " + id );
          if (id === "pricing") {
            if (this.refs.pricing) {
              this.refs.pricing.scrollIntoView({ behavior: "smooth" });
              this.refs.pricing.scrollTop += 10;
            }
          }
        },
        isMobile ? 1000 : 1000
      );
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    //console.dir(this.props);
    if (newProps.purchaseItem && newProps.purchaseItem.name) {
      this.props.history.push("/purchase");
    }
  }

  checkIfLoggedIn() {
    switch (this.props.auth) {
      case false:
        //console.log("Not Logged In");
        break;
      case null:
        //console.log("Not Logged In");
        break;
      default:
        //console.log("Logged In");
        break;
    }
  }

  purchaseAnnual() {
    if (
      this.props.state &&
      this.props.state.auth &&
      this.props.state.auth.authenticated === true
    ) {
      this.props.history.push({
        pathname: "/account",
        state: {
          showingAddress: false,
          showingCard: false,
          showingUpgrades: true,
          showingInfo: false,
        },
      });
    } else {
      this.props.actions.purchaseItem({
        name: "Annual Plan",
        price:
          this.props.defaults.annualPlanRouterCost +
          this.props.defaults.annualPlanServiceCost,
      });
    }
  }

  purchaseMonthly() {
    if (
      this.props.state &&
      this.props.state.auth &&
      this.props.state.auth.authenticated === true
    ) {
      this.props.history.push({
        pathname: "/account",
        state: {
          showingAddress: false,
          showingCard: false,
          showingUpgrades: true,
          showingInfo: false,
        },
      });
    } else {
      this.props.actions.purchaseItem({
        name: "Monthly Plan",
        price:
          this.props.defaults.monthlyPlanRouterCost +
          this.props.defaults.monthlyPlanServiceCost,
      });
    }
  }

  purchaseQuarterly() {
    if (
      this.props.state &&
      this.props.state.auth &&
      this.props.state.auth.authenticated === true
    ) {
      this.props.history.push({
        pathname: "/account",
        state: {
          showingAddress: false,
          showingCard: false,
          showingUpgrades: true,
          showingInfo: false,
        },
      });
    } else {
      this.props.actions.purchaseItem({
        name: "3 Month Plan",
        price:
          this.props.defaults.shortTermPlanRouterCost +
          this.props.defaults.shortTermPlanServiceCost,
      });
    }
  }

  purchaseBiAnnually() {
    if (
      this.props.state &&
      this.props.state.auth &&
      this.props.state.auth.authenticated === true
    ) {
      this.props.history.push({
        pathname: "/account",
        state: {
          showingAddress: false,
          showingCard: false,
          showingUpgrades: true,
          showingInfo: false,
        },
      });
    } else {
      this.props.actions.purchaseItem({
        name: "6 Month Plan",
        price:
          this.props.defaults.midTermPlanRouterCost +
          this.props.defaults.midTermPlanServiceCost,
      });
    }
  }

  render() {
    if (this.props && this.props.defaults) {
      const {
        annualPlanServiceCost,
        annualPlanRouterCost,
        shortTermPlanServiceCost,
        shortTermPlanRouterCost,
        monthlyPlanServiceCost,
        monthlyPlanRouterCost,
        midTermPlanServiceCost,
        midTermPlanRouterCost,
      } = this.props.defaults;
      return (
        <section id="pricing" ref="pricing">
          <div className="container">
            <header className="text-center mb-60">
              <h2>Our Pricing</h2>
              <p className="lead font-lato">
                Choose the package that suits you best, all include a Pre
                configured plug and play, hi-speed, wireless router
              </p>
              <hr />
            </header>

            <div className="row">
              <div className="col-md-3 col-sm-6">
                <div className="price-clean">
                  <h4>
                    <sup>€</sup>
                    {annualPlanServiceCost}
                    <em>/year</em>
                  </h4>
                  <h5> Annual Plan </h5>
                  <hr />
                  <p>
                    + €{annualPlanRouterCost} for your router. For people
                    looking for the most cost effective price.
                  </p>
                  <hr />
                  <button
                    className="btn btn-3d btn-teal"
                    onClick={this.purchaseAnnual}
                  >
                    Buy Now
                  </button>
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="price-clean price-clean-popular">
                  <div className="ribbon">
                    <div className="ribbon-inner">POPULAR</div>
                  </div>
                  <h4>
                    <sup>€</sup>
                    {monthlyPlanServiceCost}
                    <em>/month</em>
                  </h4>
                  <h5> Monthly Plan </h5>
                  <hr />
                  <p>
                    + €{monthlyPlanRouterCost} for your router. Our On/Off post
                    Brexit solution - start and stop as needed .
                  </p>
                  <hr />
                  <button
                    className="btn btn-3d btn-primary"
                    onClick={this.purchaseMonthly}
                  >
                    Buy Now
                  </button>
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="price-clean">
                  <h4>
                    <sup>€</sup>
                    {midTermPlanServiceCost}
                    <em> 6 months</em>
                  </h4>
                  <h5> Mid Term </h5>
                  <hr />
                  <p>
                    + €{midTermPlanRouterCost} for your router. For people who
                    need a mid term solution.
                  </p>
                  <hr />
                  <button
                    className="btn btn-3d btn-teal"
                    onClick={this.purchaseBiAnnually}
                  >
                    Buy Now
                  </button>
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="price-clean">
                  <h4>
                    <sup>€</sup>
                    {shortTermPlanServiceCost}
                    <em> 3 months</em>
                  </h4>
                  <h5> Short Term </h5>
                  <hr />
                  <p>
                    + €{shortTermPlanRouterCost} for your router. For people who
                    need a short term solution.
                  </p>
                  <hr />
                  <button
                    className="btn btn-3d btn-teal"
                    onClick={this.purchaseQuarterly}
                  >
                    Buy Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    } else {
      return <div></div>;
    }
  }
}

function mapStateToProps(state) {
  //console.dir(state)
  if (state && state.auth && state.auth.purchaseItem) {
    //console.log(state.auth.purchaseItem);
  }

  const props = {
    state: state,
    purchaseItem: state.auth.purchaseItem,
  };
  return props;
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Pricing));
