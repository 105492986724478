import React, {Component} from 'react';
import {Modal, Button} from 'react-bootstrap'

class Footer extends Component  {

  state = {showModal: false}

  handleHideModal(){
      this.setState({showModal: false})
  }

  handleShowModal(){
      this.setState({showModal: true})
  }

  modalView() {
    if(this.state.showModal === true) {
      return (
          <Modal.Dialog bsSize="large" style={{marginTop:'100px'}}>
            <Modal.Header>
              <h2>Terms and Conditions</h2>
            </Modal.Header>

            <Modal.Body style={{'maxHeight': 'calc(100vh - 400px)', 'overflowY': 'auto'}}>

                  <p>
                  By making use of the UK TV Router provision of service you agree to our Terms and Conditions of Use, Privacy and Refund.
                  </p>


                  <h3>Article 1  General</h3>
                  <p>
                  1.1 These terms and conditions apply to the agreement between UK TV ROUTER and the user related to the delivery of the VPN service by UK TV ROUTER. These terms and conditions form an integral part of the agreement.
                  </p><p>
                  1.2 The user of UK TV ROUTER’s VPN service agrees without any proviso to all Terms and Conditions and the Privacy Statement of UK TV ROUTER.
                  </p><p>
                  1.3 General terms and conditions or stipulations on the part of the user are expressly rejected by UK TV ROUTER.
                  </p><p>
                  1.4 Derogations from these provisions will only be valid if these have been expressly agreed and recorded in writing between UK TV ROUTER and the user.
                  </p><p>

                  </p>
                  <h3>Article 2 Coming into effect of the agreement</h3>
                  <p>
                  2.1 An offer and/or quotation from UK TV ROUTER will be an invitation to the potential user to choose one of the subscriptions. The agreement between UK TV ROUTER and the user will come into effect when UK TV ROUTER accepts the choice of the user electronically. UK TV ROUTER will not be obliged to acceptance thereof.
                  </p><p>
                  2.2 The user must generate an account to be able to make use of the VPN service, which account will provide UK TV ROUTER with the necessary data for the provision of the service, the payment and the statutory obligations UK TV ROUTER must comply with.
                  </p><p>
                  2.3 The user must pay an amount as set out on the website for the verification of the data in the account.
                  </p><p>
                  2.4 Following the verification of the account and the acceptance by UK TV ROUTER the VPN service will immediately be made available for the user within the preconditions of the chosen subscription.
                  </p><p>
                  2.5 The user guarantees the provision of correct personal data to UK TV ROUTER, including the address, place of residence, telephone and account numbers. Changes of personal data must be passed electronically to UK TV ROUTER as soon as possible.
                  </p><p>

                  </p>
                  <h3>Article 3 Duration, amendment and termination of the agreement</h3>
                  <p>
                  3.1 The agreement is entered into for a fixed or indefinite period.
                  </p><p>
                  3.2 Following the end of the first agreed period, the agreement for a fixed period will be converted into an agreement for an indefinite period. Unless UK TV ROUTER or the user state electronically or in writing prior to the expiry of the agreed period that they do not wish the agreement to continue.
                  </p><p>
                  3.3 The user can choose another subscription at any desired time. It is possible to extend subscription.
                  </p><p>
                  3.4 The user can cancel the subscription without statement of reason by means of email, within the first 30 days. This arrangement applies only to new users. Commercial users as well as consumers can make use of this arrangement.
                  </p><p>
                  3.5 In all other cases cancellation can take place at any desired time within the duration of the chosen subscription. In that case the user will not have the right to refund of the subscription costs paid in advance.
                  </p><p>

                  </p>
                  <h3>Article 4 Amendments</h3>
                  <p>
                  4.1 UK TV ROUTER has the right at any time to adjust these terms and conditions, the privacy statement, and the technical characteristics of the service and rates.
                  </p><p>
                  4.2 Amendments will be electronically notified to the user.
                  </p><p>
                  4.3 Amendments will come into effect 1 month after notification.
                  </p><p>
                  4.4 If the amendment(s) objectively disadvantage the user, the user will have the right to cancel the service within the month referred to.
                  </p><p>
                  4.5 If a judicial decision, or a statutory provision, and/or measures ensuing therefrom for UK TV ROUTER, compel UK TV ROUTER to adjust these terms and conditions, privacy statement, service and/or rates, the user will not have the right to interim termination of the service.
                  </p><p>
                  4.6 If one of these provisions under these terms and conditions is wholly or in part in conflict with a statutory provision, or is null and void, the remaining terms and conditions will remain in full force. A new provision will replace the old earlier provision, whereby the original intention will be approached as closely as possible.
                  </p><p>

                  </p>
                  <h3>Article 5 Obligations on the part of the user</h3>
                  <p>
                  5.1 The user will not breach any legal rules.
                  </p><p>
                  5.2 The user will refrain from conduct whereby, or as a result of which, other internet users will (can) experience hindrance, or whereby, or as a result of which, third party rights are infringed.
                  </p><p>
                  5.3 The user will not undertake any activities as a result of which damage can be caused to UK TV ROUTER’s system.
                  </p><p>
                  5.4 PEER TO PEER (P2P)
                  The use of P2P applications (eg BitTorrent) is strictly prohibited. P2P applications are commonly used to download copyrighted material.
                  </p><p>

                  Copyright law
                  </p><p>
                  It is an offence under UK law to make copies of commercially-produced music recordings, videos, images and computer software. You are not permitted to make copies for personal use. You must have permission from the rights holder to copy any such material.
                  </p><p>
                  Many P2P applications automatically make downloaded files available to other P2P users. This is dealing in digital contraband, resulting in increased seriousness of the copyright breach committed.
                  </p><p>
                  Security risks
                  </p><p>
                  P2P applications can lead to abuse either by those supplying the software or by third parties. Applications can include license agreements whereby the licensee allows the licensor the right to use all available CPU and network resources on the installed machine for any reason they see fit. We cannot accept such a condition.
                  </p><p>
                  Many P2P downloads contain malware (viruses, worms, trojans). It is often impossible to know whether a file being downloaded via P2P is really what it claims.
                  </p><p>
                  Users of P2P can also share more than they intended to with other users. Such as sharing your documents folder by accident and putting your data at risk.
                  </p><p>
                  Legitimate use
                  </p><p>
                  If you have a legitimate use for P2P applications, please use your ISP (internet service provider) network
                  </p><p>
                  Sanction
                  </p><p>
                  Sanctions for inappropriate use may include:
                  </p><p>
                  Immediate cancelation of account with no rights to a refund.
                  </p><p>

                  5.5 The user will be obliged, as an internet user, to behave responsibly and carefully.
                  </p><p>
                  5.6 The user will not make his/her account available to third parties. The user will be responsible for all activities, which take place via or with the aid of the user’s account, whether or not with the user’s permission.
                  </p><p>
                  5.7 If it has been noticed or is suspected that the user does not, or does not sufficiently, comply with the obligations set out, UK TV ROUTER will have the right to close down the service, whether or not entirely, for this user. In that event UK TV ROUTER will not be liable for potential damage on the part of the user or a third party resulting from the closing down.
                  </p><p>
                  5.8 If the user does not act in accordance with the provisions above, UK TV ROUTER will be entitled to impose instructions on the user. The user will be obliged to follow these. Any financial consequences will be at the user’s expense. If this situation occurs the user cannot make claim to any refund.
                  </p><p>
                  5.9 UK TV ROUTER has the right to take all measures that are necessary to protect its network, its users and third parties, against impermissible conduct – in whatsoever form – on the part of specific users.
                  </p><p>

                  </p>
                  <h3>Article 6 Availability and fair use policy</h3>
                  <p>
                  6.1 UK TV ROUTER arranges the service as it deems most suitable. UK TV ROUTER will be entitled to change procedures and the technical aspects of the service.
                  </p><p>
                  6.2 UK TV ROUTER makes endeavours to be of service to its users in the best possible manner in the field of speed, availability, security and usability. However, UK TV ROUTER cannot guarantee this due to inter alia breakdowns, necessary maintenance and adjustments of the system for improvement.
                  </p><p>
                  6.3 UK TV ROUTER does not apply a strict bandwidth limitation in its subscription. However, a fair use policy does apply. Users, who use more than 0.5% of the entire bandwidth of the UK TV ROUTER network, will be approached by UK TV ROUTER to reduce the use, or to make a higher payment.
                  </p><p>

                  </p>
                  <h3>Article 7 Rates and payment</h3>
                  <p>
                  7.1 UK TV Router charges a payment for the use of the service in accordance with the rates published in UK TV ROUTER’s website.
                  </p><p>
                  7.2 All rates referred to on the website are including IVA and any other duties or taxes imposed by authorities.
                  </p><p>
                  7.3 If the agreement is converted into an agreement for an indefinite period the collection of the payment will take place by means of credit/debit card monthly automatic payment.
                  </p><p>
                  7.4 The collection will take place prior to the provision of the service.
                  </p><p>
                  7.5 It is assumed for the recording of the amount of the collection that the user continues with his/her choice of package, unless the user has notified otherwise.
                  </p><p>
                  7.6 UK TV ROUTER will notify you each time you have made a subscription payment. If you have a recurring subscription and your card changes you must login to your account and change your payment card prior to the next payment date or the service will cease when payment fails. You can however login at uktvrouter.com and re-subscribe and the service will resume.
                  </p><p>
                  7.7 The user will be in default without further notice of default if the payment collection cannot take place. The service will terminate until the user renews their subscription.
                  </p><p>
                  7.8 The user must make objections against the amount of the collection apparent within 30 days. The user will be deemed to agree to the amount of the collection after this period.
                  </p><p>

                  </p>
                  <h3>Article 8 Force Majeure</h3>
                  <p>
                  8.1 If UK TV ROUTER, due to a non-attributable failure (force majeure) cannot fulfil its obligations, the fulfilment of these obligations will be suspended for the duration of the force majeure.
                  </p><p>
                  8.2 A failure in the provision of service cannot be attributed to UK TV ROUTER if this cannot be blamed on fault on its part, or a cause for which UK TV ROUTER is not accountable by law, by legal act or according to generally accepted standards. These circumstances also include, but are not limited to: power failures, war, environmental disasters, breakdowns of the internet connection, breakdowns in the telecommunication infrastructure, breakdowns of networks, failures on the part of engaged third parties, or suppliers, strike actions and business closures.
                  </p><p>
                  8.3 In the event of force majeure the obligation on the part of UK TV ROUTER of fulfilment of the agreement will lapse. In that event UK TV ROUTER will not owe any compensation. UK TV ROUTER will be exclusively obliged for a consumer-user to provide refund of the subscription fee pro rata the duration of the breakdown, unless this cannot be required of it in all reasonableness, with due regard to the duration and the circumstances.
                  </p><p>
                  8.4 If the force majeure lasts for more than four weeks, both parties will have the right to terminate the agreement wholly or in part, insofar as this is justified by the force majeure. The user will not have the right to any compensation in the event of force majeure on the part of UK TV ROUTER.
                  </p><p>

                  </p>
                  <h3>Article 9 Default and termination</h3>
                  <p>
                  9.1 In the event of default on the part of the user, or in one of the cases below under article 9.4 all UK TV ROUTER’s claims against the user, on whatsoever basis, will be immediately due and payable in full, and UK TV ROUTER will be entitled to suspend the provision of the service. The above will not affect any of UK TV ROUTER’s other rights on the basis of the law or the agreement.
                  </p><p>
                  9.2 UK TV ROUTER as well as the user can terminate the agreement if the other party does not properly fulfil one or more of the other party’s obligations on the basis of the agreement, insofar as this failure justifies the termination. If fulfilment is still possible the agreement can only be terminated after the other party has been given demand in writing and the failure is not eliminated within the period stated thereby.
                  </p><p>
                  9.4 In the event of (provisional) moratorium, bankruptcy, cessation, or liquidation of (the business of) the user, or if the user is or has become legally incompetent, is placed under a regime of administration, or in any other manner whatsoever loses the free disposition over his/her assets, the agreement will be terminated by operation of law.
                  </p><p>

                  </p>
                  <h3>Article 10 Complaints</h3>
                  <p>
                  10.1 Complaints regarding defects/breakdowns in the service must be reported to UK TV ROUTER electronically as soon as possible after the discovery thereof. The user will be obliged, after noticing any breakdown, to do, and to refrain from, anything that is reasonably possible for the prevention of damage. The user will provide all cooperation necessary for the inspection and the solution of the compliant, inter alia by providing UK TV ROUTER with the opportunity to conduct an inspection of the circumstances of the use of the service and the factors related thereto.
                  </p><p>
                  10.2 If the user makes a complaint regarding defects in the service correctly, justifiably and in a timely manner, UK TV ROUTER will make endeavours to resolve the defect as soon as possible. UK TV ROUTER will state, if possible, when the defect has presumably been solved. If the user does not make a compliant in a timely manner the user will loss his/her rights.
                  </p><p>
                  10.3 The costs of the breakdown inspection and the costs for eliminating the breakdown will be at UK TV ROUTER’s expense. If the breakdown is the result of an attributable failure on the part of the user, UK TV ROUTER can charge on these costs to the user.
                  </p><p>
                  10.4 UK TV ROUTER will not be obliged to any payment (of compensation) if UK TV ROUTER has fulfilled the obligation included in this article.
                  </p><p>

                  </p>
                  <h3>Article 11 Liability</h3>
                  <p>
                  11.1 UK TV ROUTER will not be liable for any consequential loss, which the user or a third party might suffer due to the use of the service. This also includes, but is not limited to: trading loss, lost profits, loss or theft of data, intangible loss, unauthorised access to confidential information. Consequential loss is also loss or damage related to, or resulting from, interruptions or blocking of the access to UK TV ROUTER’s network, the internet of UK TV ROUTER or third parties, a defect in the security of the information stored by the user, acts on the part of other users, or other internet users, and lastly, changes in login procedures and account data.
                  </p><p>
                  11.2 In any event the contractual and legal liability on the part of UK TV ROUTER towards the user, non-consumer, will be at all times limited to the payments excluding VAT actually made by the user to UK TV ROUTER during the ongoing contract year, which will be with a maximum of EURO 100 per incident, whereby a related series of incidents will apply as one single incident.
                  </p><p>
                  11.3 UK TV ROUTER will only be liable towards the user per incident to a maximum of the (annual) subscription owed by the user to UK TV ROUTER. UK TV ROUTER will only be liable towards one and the same user per year to a maximum of twice the (annual) subscription owed by the user to UK TV ROUTER.
                  </p><p>
                  11.4 If several users are affected at the same time by damage caused by UK TV ROUTER, UK TV ROUTER will be liable in that case towards all users jointly up to a maximum amount of EURO 500.
                  </p><p>
                  11.5 Damage must be reported as soon as possible to UK TV ROUTER in writing or electronically, but no later than within four weeks after the arising thereof. Damage that has not been brought to the attention of UK TV ROUTER within this period will not be eligible for compensation.
                  </p><p>

                  </p>
                  <h3>Article 12 Indemnification by the user</h3>
                  <p>
                  The user indemnifies UK TV ROUTER against claims by third parties for compensation of damage, which these third parties could (attempt to) recover from UK TV ROUTER in any manner whatsoever, due to the fact that use has been made of UK TV ROUTER’s service, or as the case may be due to the user not having fulfilled the user’s obligations towards UK TV ROUTER.
                  </p><p>

                  </p>
                  <h3>Article 13 Intellectual property</h3>
                  <p>
                  13.1 The user exclusively acquires a non-exclusive and non-transferable right to use UK TV ROUTER’s service together with the documentation and software forming part thereof. All intellectual property rights thereto are vested in UK TV ROUTER and/or its suppliers.
                  </p><p>
                  13.2 Copying the documentation for normal personal use and backup purposes is permitted. When making copies all references determining the ownership and origin must remain unaltered.
                  </p><p>

                  </p>
                  <h3>Article 14 Contract takeover</h3>
                  <p>
                  14.1 The user will not be entitled to transfer rights and obligations under the agreement to a third party without permission in writing from UK TV ROUTER. Conditions can be attached to this permission.
                  </p><p>
                  14.2 UK TV ROUTER will be entitled to transfer its rights and obligations under the agreement to a third party, if this takes place in the context of the transfer of (a part of) the enterprise run by UK TV ROUTER.
                  </p><p>

                  </p>
                  <h3>Article 15 Time limit</h3>
                  <p>
                  All causes of action ensuing for the user, who is not a consumer, on the basis of this agreement will lapse by means of the expiry of two years, to be calculated from the day following the day on which the claim has become due and payable.
                  </p><p>

                  </p>
                  <h3>Article 16 Applicable law and disputes</h3>
                  <p>
                  16.1 The law of the Spain applies to these Terms and Conditions as well as to the agreement.
                  </p><p>
                  16.2 The applicability of the Vienna Sales Convention 1980 is expressly excluded.
                  </p><p>
                  16.3 If the user and UK TV ROUTER are in a dispute in the course of which it is not feasible to reach a mutually satisfactory solution the dispute will be made pending before the court with competent jurisdiction in Alicante.
                  </p><p>

                  </p>
                  <h3>Contact</h3>
                  <p>
                  Business name:
                  </p><p>
                  UK TV ROUTER
                  </p><p>
                  Part of Base Satellite
                  </p><p>
                  Business address :
                  </p><p>
                  Miraflor 22A
                  03700 Denia
                  Alicante
                  Spain
                  </p><p>
                  Phone: +34962800807
                  Note: We don’t offer support by telephone. If you need support, please contact our support team via support@uktvrouter.com
                  </p>
                  <p>
                  Email address: <a href="emailto:support@uktvrouter.com">support@uktvrouter.com</a>
                  </p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.handleHideModal}>Close</Button>
            </Modal.Footer>
          </Modal.Dialog>
      )
    }
  }

  constructor(props) {
    super(props);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.modalView = this.modalView.bind(this);
  }

 componentDidMount() {
   var siteSeal = document.createElement("script");
   siteSeal.type = "text/javascript";
   siteSeal.async = true;
   siteSeal.src = "https://seal.godaddy.com/getSeal?sealID=I4XDRHD0RSzgbBIY2UYywUDwAT3DmjHfqFMCOZYeuaV5zIkoY3XIqzpJ96NY";

   var siteSealSpan = document.createElement("span");
   siteSealSpan.id = "siteseal";
   siteSealSpan.setAttribute("style","margin-top:.50rem");
   siteSealSpan.appendChild(siteSeal);

   var siteSealLi = document.createElement("li");
   siteSealLi.appendChild(siteSealSpan);

   var siteSealLoc = document.getElementById("footer-list");
   if (siteSealLoc) {
       siteSealLoc.appendChild(siteSealLi);
   }
 }

  render() {

    return (
      <footer id="footer" className="footer-fixed footer-light opacity-9" style={{overlay: {zIndex: 100}}}>
        {this.modalView()}

  				<div className="container mb-0 p-0 clearfix text-center fs-12">

  					<div className="clearfix float-left mobile-block text-center">

  						<a href="https://www.facebook.com/UKTVR0UTER/" rel="noopener noreferrer" target="_blank" className="social-icon social-icon-sm social-icon-border social-facebook float-left" data-toggle="tooltip" data-placement="top" title="Facebook">
  							<i className="icon-facebook"></i>
  							<i className="icon-facebook"></i>
  						</a>

  						<span className="float-left footer-links hidden-xs-down">
  							<span>(+34) 667 427 271</span>
  							<a href="/contact">Contact</a>
  						</span>

              <span className="float-left footer-links hidden-xs-down">
  							<span></span>
  							<a href={() => false} onClick={this.handleShowModal}>Terms</a>
  						</span>

  					</div>

  					<img className="float-right hidden-xs-down mb-0 ml-0" src="assets/images/uktvrouter/payments.jpg" width="178" height="30" alt="" />
            <div className="float-right hidden-xs-down mb-0 ml-0" id="footer-list" name="footer-list"/>
  					<span className="hidden-xs-down fs-14 line-h-30" style={{lineHeight:"30px !important"}}>
  						&copy; Copyright 2021 UK TV Router. All Rights Reserved.
  					</span>
            <div className="trustpilot-widget mt-10" data-locale="en-GB" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="5b5acf86e84b250001ac5f74" data-style-height="35px" data-style-width="100%" data-theme="light">
              <a href="https://uk.trustpilot.com/review/www.uktvrouter.com" target="_blank" rel="noopener noreferrer">Trustpilot</a>
            </div>

  				</div>
  			</footer>
    );
  }
};

export default Footer;
