import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as actions from './accountActions';
import { withRouter } from 'react-router-dom';
//import Menu, {SubMenu, MenuItem} from 'rc-menu';
//import animate from 'css-animation';
import 'rc-menu/assets/index.css';
//import Moment from 'moment';
//import momentLocalizer from 'react-widgets-moment'
//import { DateTimePicker } from 'react-widgets'
import 'react-widgets/dist/css/react-widgets.css'
//import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
//import {Elements,CardElement,injectStripe} from 'react-stripe-elements';
import { ToastContainer, toast } from 'react-toastify';
import { Loader } from 'react-overlay-loader';
import Header from './Header'
import Footer from './Footer'
//import SecureIframe from './SecureIframe'
//import Modal from 'react-modal';

class Success extends Component {

    toastId = 1234;
    toastIdSuccess = 3456;

    notify = (success, value) => {
        if (success) {
            if (! toast.isActive(this.toastIdSuccess)) {
                    this.toastId = toast.success(value, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        } else {
            if (! toast.isActive(this.toastId)) {
                    this.toastId = toast.error(value, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        console.log("Props");
        console.dir(nextProps);
        if(nextProps && nextProps.state && nextProps.state.account && nextProps.state.account.subscription === true) {
            this.notify(true,"Congratulations, We have setup your subscription");
            setTimeout(() => {
                this.props.history.push("/account")
            }, 5000);
        }
    }

    componentDidMount() {
      console.log("Checking Subscription");
        this.props.checkSubscription()
    }
    
    render() {
      return (
          <div>
          <Header/>
          <section id="wrapper" className="container">
            <ToastContainer/>
            <Loader fullPage loading text="Please wait..." textStyle={{color:"black"}} />
          </section>
          <Footer/>
          </div>
        );
    }
}



function mapStateToProps(state) {
    console.log("ME TOO");
    //console.dir(state)
    return {
      state:state
    };
}
  
export default connect(mapStateToProps,actions)(withRouter(Success));
  