import React, { Component } from "react";
import { Media, Player, utils, withMediaProps } from "react-media-player";
// import PlayPause from "./PlayPause";
// import MuteUnmute from "./MuteUnmute";
// import Repeat from "./Repeat";
// import Fullscreen from "./Fullscreen";
// const { CurrentTime, SeekBar, Duration } = controls;
const { keyboardControls } = utils;

class MediaPlayer extends Component {
  _handlePrevTrack = () => {
    this.props.onPrevTrack();
  };

  _handleNextTrack = () => {
    this.props.onNextTrack();
  };

  _handleRepeatTrack = () => {
    this.props.onRepeatTrack();
  };

  _handleEnded = () => {
    this.props.onNextTrack();
  };

  render() {
    const { src, repeatTrack, autoPlay } = this.props;
    return (
      <Media>
        {(mediaProps) => (
          <div
            className={
              "media-player" +
              (mediaProps.isFullscreen ? " media-player--fullscreen" : "")
            }
            onKeyDown={keyboardControls.bind(null, mediaProps)}
            tabIndex="0"
          >
            <div
              className="media-player-element col-sm-12"
              onClick={() => mediaProps.playPause()}
            >
              <Player
                src={src}
                loop={repeatTrack}
                autoPlay={autoPlay}
                onEnded={this._handleEnded}
                style={{ width: "100%", backgroundColor: "black" }}
              />
            </div>
            {/* <div className="media-controls media-controls--full col-sm-12 col-md-12 col-lg-12">
              <div className="row text-center">
                <CurrentTime className="media-control media-control--current-time col-md-4" />
                <div className="col-md-4">{currentTrack}</div>
                <Duration className="media-control media-control--duration col-md-4" />
              </div>
              <div className="row text-center">
                <SeekBar
                  className="media-control media-control--seekbar col-md-12"
                  style={{ marginLeft: 0 }}
                />
              </div>
              <br />
              <div className="media-control-group row">
                <MuteUnmute className="media-control media-control--mute-unmute" />
                <div style={{ width: "10px" }} />
                <PlayPause className="media-control media-control--play-pause" />
                <div style={{ width: "10px" }} />
                <Repeat
                  className="media-control media-control--repeat"
                  isActive={repeatTrack}
                  onClick={this._handleRepeatTrack}
                />
                <div style={{ width: "10px" }} />
                <Fullscreen />
              </div>
            </div> */}
          </div>
        )}
      </Media>
    );
  }
}

export default withMediaProps(MediaPlayer);
