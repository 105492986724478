import React, { Component } from "react";
import { connect } from "react-redux";
//import { bindActionCreators } from "redux";
//import { withRouter } from "react-router-dom";
import { isMobile } from "react-device-detect";
import * as actions from "../actions";

class NowTv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRoku: true,
      haveDefaults: false,
    };
  }

  componentDidMount() {
    //console.log("Called Here ******");
    this.props.getDefaults();
    const { hash } = window.location;
    if (hash !== "") {
      setTimeout(
        () => {
          const id = hash.replace("#", "");
          //console.log("About ID is " + id );
          if (id === "about") {
            if (this.refs.about) {
              this.refs.about.scrollIntoView({ behavior: "smooth" });
              this.refs.about.scrollTop += 40;
            }
          }
        },
        isMobile ? 1000 : 1000
      );
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(`RECEIVED PROPS ${nextProps}`);
    if (
      this.props &&
      this.props.state &&
      this.props.state.defaults &&
      this.props.state.defaults.length > 0 &&
      !this.props.state.defaults[0].nowtvStock &&
      !this.state.haveDefaults
    ) {
      console.log("Have No Roku's");
      this.setState({ showRoku: false, haveDefaults: true });
    } else {
      this.setState({ showRoku: true, haveDefaults: true });
    }
  }

  render() {
    console.log(this.props);
    console.log(this.props.state.defaults[0]);
    var nowTv1 = 0;
    var nowTv2 = 0;
    if (this.props.state.defaults && this.props.state.defaults.length > 0) {
      nowTv1 = this.props.state.defaults[0].nowtv1;
      nowTv2 = this.props.state.defaults[0].nowtv2;
    }
    console.log(`Rate 1 ${nowTv1}`);
    console.log(`Rate 2 ${nowTv2}`);
    return (
      <section id="roku" ref="roku">
        {this.state.showRoku ? (
          <div className="container">
            <header className="text-center mb-60">
              <h2>Grab yourself a Roku Streaming Stick 4K!</h2>
              <p className="lead font-lato">
                Introducing the Roku Streaming Stick with HD 4K HDR Dolby
                Vision® as an add-on option!
              </p>
              <hr />
            </header>

            <div className="row">
              <div
                className="col-sm-6 owl-carousel buttons-autohide controlls-over nomargin"
                data-plugin-options='{"singleItem": true, "autoPlay": 4000, "navigation": true, "pagination": true, "transitionStyle":"backSlide"}'
              >
                <div>
                  <img
                    className="img-responsive"
                    src="assets/images/uktvrouter/roku4k1.webp"
                    width="400"
                    height="300"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    className="img-responsive"
                    src="assets/images/uktvrouter/roku4k2.webp"
                    width="400"
                    height="300"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    className="img-responsive"
                    src="assets/images/uktvrouter/roku4k3.webp"
                    width="400"
                    height="300"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    className="img-responsive"
                    src="assets/images/uktvrouter/roku4k4.webp"
                    width="400"
                    height="300"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    className="img-responsive"
                    src="assets/images/uktvrouter/roku4k5.webp"
                    width="400"
                    height="300"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    className="img-responsive"
                    src="assets/images/uktvrouter/roku4k6.webp"
                    width="400"
                    height="300"
                    alt=""
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <h3 className="lead font-lato">Streams HD - 4K - HDR</h3>
                <ul>
                  <li>More powerful than ever with super-fast startup</li>
                  <li>All-new design that hides behind your TV</li>
                  <li>Spectacular Dolby Vision® picture quality</li>
                  <li>Long-range Wi-Fi®, now up to 2x faster</li>
                  <li>
                    Share videos, photos, and music from your Apple device using
                    AirPlay
                  </li>
                  <li>
                    Stream a massive selection of free, live, and premium TV
                  </li>
                  <li>Voice remote with TV controls</li>
                </ul>
                <p>
                  Now only €{nowTv1} or €{nowTv2} for 2!
                </p>
                <hr />

                <div className="row countTo-sm text-center">
                  <div className="col-xs-6 col-sm-4">
                    <i className="fa fa-television fs-20"></i> &nbsp;
                    <span
                      className="countTo"
                      data-speed="3000"
                      style={{ color: "#C02942" }}
                    >
                      40
                    </span>
                    <span style={{ fontSize: "20pt" }}>+</span>
                    <h6>Number of Channels</h6>
                  </div>

                  <div className="col-xs-6 col-sm-4">
                    <i className="fa fa-usb fs-20"></i> &nbsp;
                    <span
                      className="countTo"
                      data-speed="3000"
                      style={{ color: "#C02942" }}
                    >
                      1
                    </span>
                    <span style={{ fontSize: "20pt" }}></span>
                    <h6>USB power</h6>
                  </div>

                  <div className="col-xs-6 col-sm-4">
                    <i className="fa fa-file-text-o fs-20"></i> &nbsp;
                    <span
                      className="countTo"
                      data-speed="3000"
                      style={{ color: "#C02942" }}
                    >
                      0
                    </span>
                    <span style={{ fontSize: "20pt" }}></span>
                    <h6>Contracts</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </section>
    );
  }
}

function mapStateToProps(state) {
  console.log("Got Props");
  console.dir(state);
  const props = {
    state: state,
  };
  return props;
}

NowTv = connect(mapStateToProps, actions)(NowTv);

export default NowTv;
