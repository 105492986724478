import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { withRouter } from 'react-router-dom';
import Header from './Header'
import Footer from './Footer'


class Processing extends Component  {

  componentDidMount() {
    // var pi = this.props.match.params.pi;
    // console.log(pi);
    // //this.props.processingPurchase(pi);
    window.top.postMessage('3DS-authentication-complete');
  }

  render() {
    return (
      <div>
        <Header/>
        <div className="section">

        </div>
        <Footer/>
      </div>
    )
  }
}

function mapStateToProps(state) {
  console.log("Called Map Props");
  return {
    state:state,
    purchasedItem : state.auth.purchaseItem,
    paymentIntent: state.auth.paymentIntent,
    redirectURL:state.auth.redirectURL,
    defaults:state.defaults[0]
  };
}

export default connect(mapStateToProps,actions)(withRouter(Processing));
