import React, { Component } from "react";
import { isMobile } from "react-device-detect";

class Newfeatures extends Component {
  componentDidMount() {
    //console.log("Called Here Too ******");
    const { hash } = window.location;
    if (hash !== "") {
      setTimeout(
        () => {
          const id = hash.replace("#", "");
          //console.log("Revolution ID is " + id );
          if (id === "home") {
            if (this.refs.home) {
              this.refs.home.scrollIntoView({ behavior: "smooth" });
            }
          }
        },
        isMobile ? 1000 : 1000
      );
    }
  }

  render() {
    return (
      <section
        id="features"
        ref="features"
        className="callout-light heading-title heading-arrow-bottom"
        style={{ height: "220px", zIndex: 1 }}
      >
        <div className="display-table">
          <div className="display-table-cell vertical-align-middle">
            <div className="container">
              <div className="slider-featured-text text-center">
                <div
                  className="btn btn-primary btn-lg wow fadeInDown"
                  data-wow-delay="1.5s"
                >
                  <h4>Works with Sky Streaming Puck!</h4>
                </div>
                <br />
                <br />
                <div
                  className="btn btn-primary btn-lg wow fadeInUp"
                  data-wow-delay="1.5s"
                >
                  <h4>Shipped from EUROPE!</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Newfeatures;
