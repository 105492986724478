import axios from 'axios';

import {
  COLLECTED_USERS,
  FAILED_COLLECTED_USERS,
  COLLECTED_MESSAGES,
  FAILED_COLLECTED_MESSAGES,
  SAVED_USER,
  FAILED_SAVED_USER,
  RESET_ROUTERS,
  CREATE_USER,
  FAILED_CREATE_USER,
  RESET_USER_ERROR,
  COLLECT_DISCOUNTS,
  CREATE_DISCOUNT_FAILED,
  DELETE_DISCOUNT_FAILED,
  CREATE_DISCOUNT,
  SEND_EMAIL,
  SEND_EMAIL_FAILED,
  UPDATE_DEFAULTS,
  UPDATE_DEFAULTS_FAILED,
  GET_DEFAULTS,
  GET_DEFAULTS_FAILED,
  COLLECTED_HARDWARE_SALES,
  FAILED_COLLECTED_HARDWARE_SALES,
  COLLECTED_SOFTWARE_SALES,
  FAILED_COLLECTED_SOFTWARE_SALES,
  SEND_SALES,
  SEND_SALES_FAILED,
  CREATE_AFFILIATE,
  CREATE_AFFILIATE_FAILED,
  COLLECT_AFFILIATES,
  DELETE_AFFILIATE_FAILED,
} from '../actions/types';

export const collectAllUsers = ({skip,limit}) => async dispatch => {
  var token = sessionStorage.getItem('jwtToken');
  //console.log("COLLECT ALL USERS");
  axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
  axios.defaults.headers.post['Accept'] = 'application/json';
  //console.log("/api/users?skip=" + skip + "&limit=" + limit);
  axios.get("/api/users?skip=" + skip + "&limit=" + limit)
  .then(function (response) {
      //console.log("DATA RESPONSE");
      //console.dir(response);
      dispatch({type:COLLECTED_USERS, payload:response.data});
  })
  .catch(function (error) {
      //console.log("DATA ERROR");
      //console.dir(error);
      dispatch({type:FAILED_COLLECTED_USERS, payload:error.data});
  });
}

export const sendSales = () => async dispatch => {
  //console.log(card);
  var token = sessionStorage.getItem('jwtToken');
  //console.log("Delete My Card");
  axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
  axios.defaults.headers.post['Accept'] = 'application/json';
  axios.get("/api/sales")
  .then(function (response) {
      //console.log("DATA RESPONSE");
      //console.dir(response);
      dispatch({type:SEND_SALES, payload:response.data});
  })
  .catch(function (error) {
      //console.log("DATA ERROR");
      //console.dir(error);
      dispatch({type:SEND_SALES_FAILED, payload:error.data});
  });
}


export const collectHardwareSales = () => async dispatch => {
  var token = sessionStorage.getItem('jwtToken');
  //console.log("COLLECT ALL USERS");
  axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
  axios.defaults.headers.post['Accept'] = 'application/json';
  //console.log("/api/users?skip=" + skip + "&limit=" + limit);
  axios.get("/api/hardwareSales")
  .then(function (response) {
      //console.log("DATA RESPONSE");
      //console.dir(response);
      dispatch({type:COLLECTED_HARDWARE_SALES, payload:response.data});
  })
  .catch(function (error) {
      //console.log("DATA ERROR");
      //console.dir(error);
      dispatch({type:FAILED_COLLECTED_HARDWARE_SALES, payload:error.data});
  });
}

export const collectSoftwareSales = () => async dispatch => {
  var token = sessionStorage.getItem('jwtToken');
  //console.log("COLLECT ALL USERS");
  axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
  axios.defaults.headers.post['Accept'] = 'application/json';
  //console.log("/api/users?skip=" + skip + "&limit=" + limit);
  axios.get("/api/softwareSales")
  .then(function (response) {
      //console.log("DATA RESPONSE");
      //console.dir(response);
      dispatch({type:COLLECTED_SOFTWARE_SALES, payload:response.data});
  })
  .catch(function (error) {
      //console.log("DATA ERROR");
      //console.dir(error);
      dispatch({type:FAILED_COLLECTED_SOFTWARE_SALES, payload:error.data});
  });
}


export const deleteDiscount = ({discount}) => async dispatch => {
  var token = sessionStorage.getItem('jwtToken');
  //console.log("DELETE DISCOUNT");
  //console.log(discount);
  axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
  axios.defaults.headers.post['Accept'] = 'application/json';
  //console.log("/api/discount/" + discount + "/delete");
  axios.get("/api/discount/" + discount + "/delete")
  .then(function (response) {
      //console.log("DATA RESPONSE");
      //console.dir(response);
      dispatch({type:COLLECT_DISCOUNTS, payload:response.data});
  })
  .catch(function (error) {
      //console.log("DATA ERROR");
      //console.dir(error);
      dispatch({type:DELETE_DISCOUNT_FAILED, payload:null});
  });
}

export const deleteAffiliate = ({affiliate}) => async dispatch => {
  var token = sessionStorage.getItem('jwtToken');
  //console.log("DELETE DISCOUNT");
  //console.log(affiliate);
  axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
  axios.defaults.headers.post['Accept'] = 'application/json';
  //console.log("/api/discount/" + discount + "/delete");
  axios.get("/api/affiliate/" + affiliate + "/delete")
  .then(function (response) {
      //console.log("DATA RESPONSE");
      //console.dir(response);
      dispatch({type:COLLECT_AFFILIATES, payload:response.data});
  })
  .catch(function (error) {
      console.log("DATA ERROR");
      console.dir(error);
      dispatch({type:DELETE_AFFILIATE_FAILED, payload:error.message});
  });
}

export const collectDiscounts = () => async dispatch => {
  var token = sessionStorage.getItem('jwtToken');
  //console.log("COLLECT ALL USERS SEARCH with " + token);
  axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
  axios.defaults.headers.post['Accept'] = 'application/json';
  //console.log("/api/admin/discounts");
  axios.get("/api/admin/discounts")
  .then(function (response) {
      //console.log("DATA RESPONSE");
      //console.dir(response);
      dispatch({type:COLLECT_DISCOUNTS, payload:response.data});
  })
  .catch(function (error) {
      //console.log("DATA ERROR");
      //console.dir(error);
      dispatch({type:COLLECT_DISCOUNTS, payload:null});
  });
}

export const collectAffiliates = () => async dispatch => {
  var token = sessionStorage.getItem('jwtToken');
  //onsole.log("COLLECT ALL USERS SEARCH");
  axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
  axios.defaults.headers.post['Accept'] = 'application/json';
  //console.log("/api/admin/discounts");
  axios.get("/api/admin/affiliates")
  .then(function (response) {
      //console.log("DATA RESPONSE");
      //console.dir(response);
      dispatch({type:COLLECT_AFFILIATES, payload:response.data});
  })
  .catch(function (error) {
      //console.log("DATA ERROR");
      //console.dir(error);
      dispatch({type:COLLECT_AFFILIATES, payload:null});
  });
}

export const collectAllUsersSearch = ({skip,limit,searchText}) => async dispatch => {
  var token = sessionStorage.getItem('jwtToken');
  //console.log("COLLECT ALL USERS SEARCH");
  axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
  axios.defaults.headers.post['Accept'] = 'application/json';
  //console.log("/api/users?skip=" + skip + "&limit=" + limit + "&search=" + searchText);
  axios.get("/api/users?skip=" + skip + "&limit=" + limit + "&search=" + searchText)
  .then(function (response) {
      //console.log("DATA RESPONSE");
      //console.dir(response);
      dispatch({type:COLLECTED_USERS, payload:response.data});
  })
  .catch(function (error) {
      //console.log("DATA ERROR");
      //console.dir(error);
      dispatch({type:FAILED_COLLECTED_USERS, payload:error.data});
  });
}

export const collectAllMessages = ({skip,limit}) => async dispatch => {
  var token = sessionStorage.getItem('jwtToken');
  //console.log("COLLECT ALL MESSAGES");
  axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
  axios.defaults.headers.post['Accept'] = 'application/json';
  //console.log("/api/messages?skip=" + skip + "&limit=" + limit);
  axios.get("/api/messages?skip=" + skip + "&limit=" + limit)
  .then(function (response) {
      //console.log("DATA RESPONSE");
      //console.dir(response);
      dispatch({type:COLLECTED_MESSAGES, payload:response.data});
  })
  .catch(function (error) {
      //console.log("DATA ERROR");
      //console.dir(error);
      dispatch({type:FAILED_COLLECTED_MESSAGES, payload:error.data});
  });
}

export const collectAllMessagesSearch = ({skip,limit,searchText}) => async dispatch => {
  var token = sessionStorage.getItem('jwtToken');
  //console.log("COLLECT ALL MESSAGES SEARCH");
  axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
  axios.defaults.headers.post['Accept'] = 'application/json';
  //console.log("/api/messages?skip=" + skip + "&limit=" + limit + "&search=" + searchText);
  axios.get("/api/messages?skip=" + skip + "&limit=" + limit + "&search=" + searchText)
  .then(function (response) {
      //console.log("DATA RESPONSE");
      //console.dir(response);
      dispatch({type:COLLECTED_MESSAGES, payload:response.data});
  })
  .catch(function (error) {
      //console.log("DATA ERROR");
      //console.dir(error);
      dispatch({type:FAILED_COLLECTED_MESSAGES, payload:error.data});
  });
}

export const resetRouters = () => async dispatch => {
  var token = sessionStorage.getItem('jwtToken');
  //console.log("RESET ALL ROUTERS");
  axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
  axios.defaults.headers.post['Accept'] = 'application/json';
  axios.get("/api/resetRouters")
  .then(function (response) {
      //console.log("DATA RESPONSE");
      //console.dir(response);
      dispatch({type:RESET_ROUTERS, payload:response.data});
  })
  .catch(function (error) {
      //console.log("DATA ERROR");
      //console.dir(error);
      dispatch({type:RESET_ROUTERS, payload:error.data});
  });
}

export const resetUserError = () => async dispatch => {
  dispatch({type:RESET_USER_ERROR, payload:null});
}

export const updateAppDefaults = ({defaults}) => async dispatch => {
  //console.log("UPDATING DEFAULTS");
  console.dir(defaults);
  var token = sessionStorage.getItem('jwtToken');
  axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
  axios.defaults.headers.post['Accept'] = 'application/json';
  axios.post('/api/defaults/update', { defaults })
  .then(function (response) {
    if (response.data.error !== undefined) {
      //console.log("Error " + response.data.error);
      dispatch({type:UPDATE_DEFAULTS_FAILED, payload:response.data});
    } else {
      //console.dir(response);
      if (response.data) {
        dispatch({type:GET_DEFAULTS, payload:response.data});
        dispatch({type:UPDATE_DEFAULTS, payload:response.data});
      } else {
        dispatch({type:UPDATE_DEFAULTS_FAILED, payload:"Failed to update defaults"});
      }
    }
  })
  .catch(function (error) {
      if (error) {
        dispatch({type:UPDATE_DEFAULTS_FAILED, payload:error});
      }
  });
}

export const getDefaults = () => async dispatch => {
  axios.get('/api/defaults', { })
  .then(function (response) {
      dispatch({type:GET_DEFAULTS, payload:response.data});
  })
  .catch(function (error) {
      dispatch({type:GET_DEFAULTS_FAILED, payload:error.data});
  });
}

export const createNewDiscount = ({discount}) => async dispatch => {
  //console.log("CREATING DISCOUNT");
  console.dir(discount);
  var token = sessionStorage.getItem('jwtToken');
  axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
  axios.defaults.headers.post['Accept'] = 'application/json';
  axios.post('/api/discount/create', { discount })
  .then(function (response) {
    if (response.data.error !== undefined) {
      //console.log("Error " + response.data.error);
      dispatch({type:CREATE_DISCOUNT_FAILED, payload:response.data.error});
    } else {
      //console.dir(response);
      if (response.data) {
        dispatch({type:CREATE_DISCOUNT, payload:response.data});
      } else {
        dispatch({type:CREATE_DISCOUNT_FAILED, payload:"Failed to create discount"});
      }
    }
  })
  .catch(function (error) {
      if (error) {
        dispatch({type:CREATE_DISCOUNT_FAILED, payload:error});
      }
  });
}

export const createNewAffiliate = ({affiliate}) => async dispatch => {
  //console.log("CREATING DISCOUNT");
  console.dir(affiliate);
  var token = sessionStorage.getItem('jwtToken');
  axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
  axios.defaults.headers.post['Accept'] = 'application/json';
  axios.post('/api/affiliate/create', { affiliate })
  .then(function (response) {
    if (response.data.error !== undefined) {
      //console.log("Error " + response.data.error);
      dispatch({type:CREATE_AFFILIATE_FAILED, payload:response.data.error});
    } else {
      //console.dir(response);
      if (response.data) {
        dispatch({type:CREATE_AFFILIATE, payload:response.data});
      } else {
        dispatch({type:CREATE_AFFILIATE_FAILED, payload:"Failed to create affiliate"});
      }
    }
  })
  .catch(function (error) {
      if (error) {
        dispatch({type:CREATE_AFFILIATE_FAILED, payload:error});
      }
  });
}

export const sendEmail = ({content}) => async dispatch => {
  //console.log("SENDING EMAIL");
  //console.dir(content);
  var token = sessionStorage.getItem('jwtToken');
  axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
  axios.defaults.headers.post['Accept'] = 'application/json';
  axios.post('/api/email/send', { content })
  .then(function (response) {
    if (response.data.error !== undefined) {
      //console.log("Error " + response.data.error);
      dispatch({type:SEND_EMAIL_FAILED, payload:response.data});
    } else {
      //console.dir(response);
      if (response.data) {
        dispatch({type:SEND_EMAIL, payload:{data:response.data}});
      } else {
        dispatch({type:SEND_EMAIL_FAILED, payload:"Failed to send email"});
      }
    }
  })
  .catch(function (error) {
      if (error) {
        dispatch({type:FAILED_CREATE_USER, payload:error});
      }
  });
}

export const createUser = ({user}) => async dispatch => {
  //console.log("CREATING USER");
  //console.dir(user);
  var token = sessionStorage.getItem('jwtToken');
  axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
  axios.defaults.headers.post['Accept'] = 'application/json';
  axios.post('/api/user/create', { user })
  .then(function (response) {
    if (response.data.error !== undefined) {
      //console.log("Error " + response.data.error);
      dispatch({type:FAILED_CREATE_USER, payload:response.data.error});
    } else {
      //console.dir(response);
      if (response.data) {
        dispatch({type:CREATE_USER, payload:{data:response.data}});
      } else {
        dispatch({type:FAILED_CREATE_USER, payload:"Failed to create user"});
      }
    }
  })
  .catch(function (error) {
      if (error) {
        dispatch({type:FAILED_CREATE_USER, payload:error});
      }
  });
}

export const saveUser = ({user}) => async dispatch => {
  //console.log("SAVING USER");
  //console.dir(user);
  var token = sessionStorage.getItem('jwtToken');
  axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
  axios.defaults.headers.post['Accept'] = 'application/json';
  axios.post('/api/user/save', { user })
  .then(function (response) {
    if (response.data.error !== undefined) {
      //console.log("Error " + response.data.error);
      dispatch({type:FAILED_SAVED_USER, payload:response.data.error});
    } else {
      //console.dir(response);
      if (response.data) {
        dispatch({type:SAVED_USER, payload:{user:response.data.user}});
      } else {
        dispatch({type:FAILED_SAVED_USER, payload:"Failed to save user"});
      }
    }
  })
  .catch(function (error) {
      if (error) {
        dispatch({type:FAILED_SAVED_USER, payload:"Failed to save user"});
      }
  });
}
