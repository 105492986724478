import axios from 'axios';
import {
  FETCH_USER,
  AUTH_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  PURCHASE_ITEM,
  GET_DEFAULTS,
  GET_DEFAULTS_FAILED,
  ASK_QUESTIONS,
  ASK_QUESTIONS_FAILED,
  RESET_QUESTION,
  AUTHENTICATED,
  UNAUTHENTICATED,
  SIGNED_UP,
  SIGNED_UP_FAILED,
  CONTACT_US,
  CONTACT_US_FAILED,
  RESET_CONTACT_US,
  CHECK_PROMO,
  CHECK_PROMO_FAILED,
  PAYMENT_INTENT,
  PAYMENT_INTENT_FAILED,
  SIGNED_UP_URL
} from './types';

export const processingPurchase = ({ company, address1, address2, city, region, country, postcode, shippingCompany,shippingAddress1, shippingAddress2, shippingCity, shippingRegion, shippingCountry, shippingPostcode, shippingFirstName, shippingLastName, shippingPhone, email, password, firstName, lastName, phone, newsletter, token, productCost, nowTVCost, serviceCost, shippingCost, productName, affiliateID, paymentIntentId },history)  => async dispatch => {
  axios.post('/auth/checkPurchase', { company, address1, address2, city, region, country, postcode, shippingCompany,shippingAddress1, shippingAddress2, shippingCity, shippingRegion, shippingCountry, shippingPostcode, shippingFirstName, shippingLastName, shippingPhone, email, password, firstName, lastName, phone, newsletter, token, productCost, nowTVCost, serviceCost, shippingCost, productName, affiliateID, paymentIntentId })
  .then(function (response) {
    dispatch({type:SIGNED_UP, payload:response.data});
  })
  .catch(function (error) {
      console.log("PAYMENT FAILED");
      console.dir("Error Data: " + error.response);
      dispatch({type:SIGNED_UP_FAILED, payload:error});
  });
}

export const getPaymentIntent = (nowTVCost, productCost, serviceCost, shippingCost, productName) => async dispatch => {
  console.log("Getting Payment Intent");
  axios.post('/auth/paymentIntent', { nowTVCost, productCost, serviceCost, shippingCost, productName })
  .then(function (response) {
    //console.log("Collected Payment Intent");
    //console.dir(response);
    dispatch({type:PAYMENT_INTENT, payload:response.data});
  })
  .catch(function (error) {
      //console.log("PAYMENT INTENT FAILED");
      //console.dir("Error Data: " + error.response);
      dispatch({type:PAYMENT_INTENT_FAILED, payload:error});
  });
}

export const updatePaymentIntent = (paymentIntentId, nowTVCost, productCost, serviceCost, shippingCost) => async dispatch => {
  axios.post('/auth/paymentIntentUpdate', { paymentIntentId, nowTVCost, productCost, serviceCost, shippingCost})
  .then(function (response) {
    //console.log("Collected Payment Intent");
    //console.dir(response);
    dispatch({type:PAYMENT_INTENT, payload:response.data});
  })
  .catch(function (error) {
      //console.log("PAYMENT INTENT FAILED");
      //console.dir("Error Data: " + error.response);
      dispatch({type:PAYMENT_INTENT_FAILED, payload:error});
  });
}


export const fetchUser = () => async dispatch => {
  var payload = {
    admin: sessionStorage.getItem('isAdmin'),
    authenticated: sessionStorage.getItem('jwtToken') ? true : false
  }
  dispatch({type:FETCH_USER,payload:payload});
}

export const handleToken = (token) => async dispatch => {
  const res = await axios.post('api/stripe', token);

  dispatch({type:FETCH_USER, payload:res.data});
}

export const purchaseItem = (item) => async dispatch => {
  dispatch({type:PURCHASE_ITEM, payload:item})
}


export const getDefaults = () => async dispatch => {
  axios.get('/api/defaults', { })
  .then(function (response) {
      dispatch({type:GET_DEFAULTS, payload:response.data});
  })
  .catch(function (error) {
      dispatch({type:GET_DEFAULTS_FAILED, payload:error.data});
  });
}

export const checkPromo = ({ promoCode, name }, history) => async dispatch => {
  //console.log(promoCode);
  axios.post('/api/promo/check', { promoCode, name })
  .then(function (response) {
    if (response.data.error) {
      dispatch({type:CHECK_PROMO_FAILED, payload:response.data.error});
    } else {
      dispatch({type:CHECK_PROMO, payload:response.data});
    }
  })
  .catch(function (error) {
    //console.log(error);
    dispatch({type:CHECK_PROMO_FAILED, payload:error.data});
  });
}

export const resetPassword = ({ email }, history) => async dispatch => {
  //console.log(email);
  axios.post('/auth/reset', { email })
  .then(function (response) {
      dispatch({type:RESET_PASSWORD_SUCCESS, payload:response.data});
  })
  .catch(function (error) {
      dispatch({type:RESET_PASSWORD_FAIL, payload:error.data});
  });
}

export const signupUser = ({ company, address1, address2, city, region, country, postcode, shippingCompany,shippingAddress1, shippingAddress2, shippingCity, shippingRegion, shippingCountry, shippingPostcode, shippingFirstName, shippingLastName, shippingPhone, email, password, firstName, lastName, phone, newsletter, token, productCost, nowTVCost, serviceCost, shippingCost, productName, affiliateID, paymentIntentId },history) => async dispatch => {
  console.log("Sign up the User");
  axios.post('/auth/signup', { company, address1, address2, city, region, country, postcode, shippingCompany,shippingAddress1, shippingAddress2, shippingCity, shippingRegion, shippingCountry, shippingPostcode, shippingFirstName, shippingLastName, shippingPhone, email, password, firstName, lastName, phone, newsletter, token, productCost, nowTVCost, serviceCost, shippingCost, productName, affiliateID, paymentIntentId })
  .then(function (response) {
    console.log("SIGNED UP");
    console.dir(response);
    dispatch({type:SIGNED_UP, payload:response.data});
  })
  .catch(function (error) {
      console.log("SIGNED UP FAILED");
      //console.dir(error);
      if(error.response && error.response.status && error.response.status === 402) {
        console.log("Have 3D Secure URL");
        //console.log(error.response.data.url);
        dispatch({type:SIGNED_UP_URL, payload:error.response.data.url});
      } else{
        dispatch({type:SIGNED_UP_FAILED, payload:error});
      }
  });
}

export const signinUser = ({ username, password }, history) => async dispatch => {
  axios.post('/auth/email', { username, password })
  .then(function (response) {
    if (response.data.error !== undefined) {
      dispatch({type:UNAUTHENTICATED, payload:null});
      //console.log("Error " + response.data.error);
      dispatch({type:AUTH_ERROR, payload:response.data.error});
    } else {
      //console.dir(response);
      if (response.data.token) {
        sessionStorage.setItem('jwtToken', response.data.token);
        sessionStorage.setItem('userId', response.data.userId);
        sessionStorage.setItem('userType', response.data.userType);
        sessionStorage.setItem('isAdmin', response.data.isAdmin);
        dispatch({type:AUTHENTICATED, payload:{admin:response.data.isAdmin}});
        if (response.data.isAdmin === true) {
          history.push('/admin');
        } else {
          history.push('/account');
        }
      } else {
        dispatch({type:UNAUTHENTICATED, payload:null});
        dispatch({type:AUTH_ERROR, payload:"Incorrect Username or Password"});
      }
    }
  })
  .catch(function (error) {
      if (error) {
        dispatch({type:UNAUTHENTICATED, payload:null});
        dispatch({type:AUTH_ERROR, payload:"Incorrect Username or Password"});
      }
  });
}

export const logout = (history) => async dispatch => {
    sessionStorage.removeItem("jwtToken");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("userType");
    sessionStorage.removeItem("isAdmin");
    history.push('/');
    return dispatch({type:FETCH_USER, payload:{
      admin:false,
      authenticated:false,
      userId:sessionStorage.getItem("userId"),
      userType:sessionStorage.getItem("userType"),
      completedSetup:sessionStorage.getItem("isAdmin")
    }});
}

export const savePassword = ({ hash, password }, history) => async dispatch => {
  axios.post('/auth/savepassword', { hash, password })
  .then(function (response) {
      if (response.data.error !== undefined) {
        //console.log(response.data.error);
        axios.get('/api/logoutUser');
        dispatch({type:AUTH_ERROR, payload:response.data.error});
      } else {
        history.push('/');
        dispatch({type:FETCH_USER, payload:response.data});
      }
  })
  .catch(function (error) {
      dispatch({type:AUTH_ERROR, payload:error.data});
  });
}


export const contactUs = ({email,name,question},history) => async dispatch => {
  axios.post('/api/askAQuestion', { email,name,question})
  .then(function (response) {
      if (response.data.error !== undefined) {
        //console.log(response.data.error);
        dispatch({type:CONTACT_US_FAILED, payload:response.data.error});
      } else {
        dispatch({type:CONTACT_US, payload:response.data});
      }
  })
  .catch(function (error) {
      dispatch({type:CONTACT_US_FAILED, payload:error.data});
  });
}

export const askQuestion = ({email,name,question},history) => async dispatch => {
  axios.post('/api/askAQuestion', { email,name,question})
  .then(function (response) {
      if (response.data.error !== undefined) {
        //console.log(response.data.error);
        dispatch({type:ASK_QUESTIONS_FAILED, payload:response.data.error});
      } else {
        dispatch({type:ASK_QUESTIONS, payload:response.data});
      }
  })
  .catch(function (error) {
      dispatch({type:ASK_QUESTIONS_FAILED, payload:error.data});
  });
}

export const resetQuestions = () => async dispatch => {
  dispatch({type:RESET_QUESTION, payload:null});
}

export const resetContactUs = () => async dispatch => {
  dispatch({type:RESET_CONTACT_US, payload:null});
}

export function authError(error) {
  return {
    type: AUTH_ERROR,
    payload: error
  };
}
