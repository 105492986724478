import React, { Component } from "react";
import { connect } from "react-redux";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import * as actions from "../actions";
import { withRouter } from "react-router-dom";
import { Elements, CardElement, injectStripe } from "react-stripe-elements";
import { Loader } from "react-overlay-loader";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-modal";
import Header from "./Header";
import Footer from "./Footer";
import SecureIframe from "./SecureIframe";
import { Button, ButtonGroup } from "react-bootstrap";
import ReactGA from "react-ga4";

const customStyles = {
  content: {
    top: "0%",
    left: "0%",
    right: "0%",
    bottom: "0%",
    width: "100%",
    height: "100%",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "white",
  },
};

Modal.setAppElement("#root");

class _CardForm extends React.Component<InjectedProps & { fontSize: string }> {
  componentDidMount() {
    this.props.onRef(this);
  }
  componentWillUnmount() {
    this.props.onRef(undefined);
  }
  clickButton() {
    this.refs.paymentButton.click();
  }
  handleSubmit = (ev) => {
    ev.preventDefault();
    if (this.props.mainForm === true) {
      if (this.props.emailForm === true) {
        if (this.props.stripe) {
          if (this.props.startSubmission) {
            this.props.startSubmission();
          }
          this.props.stripe.createToken().then((payload) => {
            console.log("[token]", payload);
            this.props.handleToken(payload);
          });
        } else {
          //console.log("Stripe.js hasn't loaded yet.");
        }
      } else {
        this.props.submitEmail();
      }
    } else {
      this.props.submitMain();
    }
  };
  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <CardElement
          style={{ base: { fontSize: "16px", color: "black" } }}
          ref="cardElement"
        />
        <br />
        <button
          ref="paymentButton"
          className="btn btn-primary btn-lg btn-block fs-15"
        >
          <i className="fa fa-mail-forward"></i> Place Order Now
        </button>
      </form>
    );
  }
}
const CardForm = injectStripe(_CardForm);

class Purchase extends Component {
  toastId = 1234;
  toastIdSuccess = 3456;

  notify = (success, value) => {
    if (success) {
      if (!toast.isActive(this.toastIdSuccess)) {
        this.toastId = toast.success(value, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 6000,
        });
      }
    } else {
      if (!toast.isActive(this.toastId)) {
        this.toastId = toast.error(value, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 6000,
        });
      }
    }
  };

  eventTrack(category, action, label, value) {
    console.log("GA event:", category, ":", action, ":", label);
    ReactGA.event({
      category: category,
      action: action,
      label: label,
      value: 99,
    });
  }

  constructor(props) {
    super(props);
    this.childRef = React.createRef();
    //console.dir(props);
    if (!props.purchasedItem) {
      props.history.push("/");
      return;
    }
    //console.log("Propps " + props.purchasedItem.name);
    this.state = {
      secureModalOpen: false,
      mainForm: false,
      emailForm: false,
      firstName: "",
      lastName: "",
      username: "",
      company: "",
      address1: "",
      address2: "",
      city: "",
      postcode: "",
      phone: "",
      shippingFirstName: "",
      shippingLastName: "",
      shippingEmail: "",
      shippingCompany: "",
      shippingAddress1: "",
      shippingAddress2: "",
      shippingCity: "",
      shippingPostcode: "",
      shippingPhone: "",
      newAccount: true,
      shippingSame: true,
      shippingCountry: "",
      shippingRegion: "",
      country: "",
      region: "",
      stripe: null,
      shippingPrice: 0.0,
      product: props.purchasedItem,
      terms: false,
      isSubmitting: false,
      promoCode: "",
      promo: null,
      discount: null,
      active: 0,
      nowTVCost: 0.0,
    };
    this.eventTrack = this.eventTrack.bind(this);
    this.renderNewAccount = this.renderNewAccount.bind(this);
    this.selectShippingCountry = this.selectShippingCountry.bind(this);
    this.selectShippingRegion = this.selectShippingRegion.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    //this.submitForm = this.submitForm.bind(this);
    this.dependantPlan = this.dependantPlan.bind(this);
    this.handleToken = this.handleToken.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAccountSubmit = this.handleAccountSubmit.bind(this);
    this.submitMain = this.submitMain.bind(this);
    this.submitEmail = this.submitEmail.bind(this);
    this.signTheUserUp = this.signTheUserUp.bind(this);
    this.handleAgree = this.handleAgree.bind(this);
    this.showSpinner = this.showSpinner.bind(this);
    this.startSubmission = this.startSubmission.bind(this);
    this.handlePromoCode = this.handlePromoCode.bind(this);
    this.checkPromo = this.checkPromo.bind(this);
    this.updatePaymentIntent = this.updatePaymentIntent.bind(this);
    this.showFooter = this.showFooter.bind(this);
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.completed3DS = this.completed3DS.bind(this);
    this.createInitialOrNewPaymentIntent = this.createInitialOrNewPaymentIntent.bind(
      this
    );
  }

  componentWillMount() {
    if (this.state === null) {
      this.props.history.push("/");
      return;
    }
  }

  componentDidMount() {
    //window.scrollTo(0, 0)
    if (this.props && this.props.defaults && this.props.defaults.affiliateID) {
      this.setState({
        affiliateID: this.props.defaults.affiliateID,
      });
    }

    if (window.Stripe) {
      this.setState({
        stripe: window.Stripe(process.env.REACT_APP_STRIPE_KEY),
      });
    } else {
      document.querySelector("#stripe-js").addEventListener("load", () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({
          stripe: window.Stripe(process.env.REACT_APP_STRIPE_KEY),
        });
      });
    }
    if (this.props && this.props.defaults) {
      var prodCost = 0;
      var service = 0;
      var nowTVCost = 0;
      if (
        this.props.purchasedItem.price ===
        this.props.defaults.monthlyPlanRouterCost +
          this.props.defaults.monthlyPlanServiceCost
      ) {
        prodCost = this.props.defaults.monthlyPlanRouterCost;
        service = this.props.defaults.monthlyPlanServiceCost;
      } else if (
        this.props.purchasedItem.price ===
        this.props.defaults.midTermPlanRouterCost +
          this.props.defaults.midTermPlanServiceCost
      ) {
        prodCost = this.props.defaults.midTermPlanRouterCost;
        service = this.props.defaults.midTermPlanServiceCost;
      } else if (
        this.props.purchasedItem.price ===
        this.props.defaults.shortTermPlanRouterCost +
          this.props.defaults.shortTermPlanServiceCost
      ) {
        prodCost = this.props.defaults.shortTermPlanRouterCost;
        service = this.props.defaults.shortTermPlanServiceCost;
      } else if (
        this.props.purchasedItem.price ===
        this.props.defaults.annualPlanRouterCost +
          this.props.defaults.annualPlanServiceCost
      ) {
        prodCost = this.props.defaults.annualPlanRouterCost;
        service = this.props.defaults.annualPlanServiceCost;
      }
      this.setState({
        clientSecret: "",
        shippingPrices: {
          portugal: this.props.defaults.portugal,
          germany: this.props.defaults.germany,
          austria: this.props.defaults.austria,
          belgium: this.props.defaults.belgium,
          bosnia: this.props.defaults.bosnia,
          bulgaria: this.props.defaults.bulgaria,
          croatia: this.props.defaults.croatia,
          denmark: this.props.defaults.denmark,
          slovakia: this.props.defaults.slovakia,
          slovenia: this.props.defaults.slovenia,
          estonia: this.props.defaults.estonia,
          finland: this.props.defaults.finland,
          france: this.props.defaults.france,
          greece: this.props.defaults.greece,
          netherlands: this.props.defaults.netherlands,
          hungary: this.props.defaults.hungary,
          ireland: this.props.defaults.ireland,
          italy: this.props.defaults.italy,
          lithuania: this.props.defaults.lithuania,
          latvia: this.props.defaults.latvia,
          luxembourg: this.props.defaults.luxembourg,
          norway: this.props.defaults.norway,
          poland: this.props.defaults.poland,
          "czech republic": this.props.defaults.czechRepublic,
          romania: this.props.defaults.romania,
          serbia: this.props.defaults.serbia,
          sweden: this.props.defaults.sweden,
          switzerland: this.props.defaults.switzerland,
          spain: this.props.defaults.spain,
          "united kingdom": this.props.defaults.unitedKingdom,
          granCanaria: this.props.defaults.granCanaria,
        },
        nowtv1: this.props.defaults.nowtv1,
        nowtv2: this.props.defaults.nowtv2,
        nowtvStock: this.props.defaults.nowtvStock,
        defaultShippingPrice: this.props.defaults.defaultShipping,
        productCost: prodCost,
        serviceCost: service,
        nowTVCost: nowTVCost,
        totalCost: prodCost + service + nowTVCost,
      });
    }
  }

  setActive(index) {
    var nowTv1 = this.state.nowtv1;
    var nowTv2 = this.state.nowtv2;
    console.log(nowTv1);
    console.log(nowTv2);
    this.setState({ active: index });
    switch (index) {
      case 0:
        var totalCost =
          this.state.productCost +
          this.state.shippingPrice +
          this.state.serviceCost;
        console.log(totalCost);
        this.setState({ nowTVCost: 0.0, totalCost: totalCost }, () => {
          this.updatePaymentIntent();
        });
        break;
      case 1:
        var totalCost1 =
          this.state.productCost +
          this.state.shippingPrice +
          this.state.serviceCost +
          nowTv1;
        console.log(totalCost1);
        this.setState({ nowTVCost: nowTv1, totalCost: totalCost1 }, () => {
          this.updatePaymentIntent();
        });
        break;
      case 2:
        var totalCost2 =
          this.state.productCost +
          this.state.shippingPrice +
          this.state.serviceCost +
          nowTv2;
        console.log(totalCost2);
        this.setState({ nowTVCost: nowTv2, totalCost: totalCost2 }, () => {
          this.updatePaymentIntent();
        });
        break;
      default:
    }
  }

  completed3DS() {
    this.setState(
      {
        isSubmitting: true,
        secureModalOpen: false,
        redirectURL: null,
      },
      () => {
        console.log("Go Check the State of the Payment");
        if (this.state.discount) {
          var product = this.state.productCost;
          product = product - this.state.discount;
          this.props.processingPurchase(
            {
              paymentIntentId: this.state.paymentIntentId,
              company: this.state.company,
              address1: this.state.address1,
              address2: this.state.address2,
              city: this.state.city,
              region: this.state.region,
              country: this.state.country,
              postcode: this.state.postcode,
              shippingCompany: this.state.shippingCompany,
              shippingAddress1: this.state.shippingAddress1,
              shippingAddress2: this.state.shippingAddress2,
              shippingCity: this.state.shippingCity,
              shippingRegion: this.state.shippingRegion,
              shippingCountry: this.state.shippingCountry,
              shippingPostcode: this.state.shippingPostcode,
              shippingFirstName: this.state.shippingFirstName,
              shippingLastName: this.state.shippingLastName,
              shippingPhone: this.state.shippingPhone,
              email: this.state.username,
              password: this.state.password,
              firstName: this.state.firstName,
              lastName: this.state.lastName,
              phone: this.state.phone,
              newsletter: false,
              token: this.state.token,
              productCost: product,
              nowTVCost: this.state.nowTVCost,
              serviceCost: this.state.serviceCost,
              shippingCost: this.state.shippingPrice,
              affiliateID: this.state.affiliateID,
              productName: this.props.purchasedItem.name,
            },
            this.props.history
          );
        } else {
          this.props.processingPurchase(
            {
              paymentIntentId: this.state.paymentIntentId,
              company: this.state.company,
              address1: this.state.address1,
              address2: this.state.address2,
              city: this.state.city,
              region: this.state.region,
              country: this.state.country,
              postcode: this.state.postcode,
              shippingCompany: this.state.shippingCompany,
              shippingAddress1: this.state.shippingAddress1,
              shippingAddress2: this.state.shippingAddress2,
              shippingCity: this.state.shippingCity,
              shippingRegion: this.state.shippingRegion,
              shippingCountry: this.state.shippingCountry,
              shippingPostcode: this.state.shippingPostcode,
              shippingFirstName: this.state.shippingFirstName,
              shippingLastName: this.state.shippingLastName,
              shippingPhone: this.state.shippingPhone,
              email: this.state.username,
              password: this.state.password,
              firstName: this.state.firstName,
              lastName: this.state.lastName,
              phone: this.state.phone,
              newsletter: false,
              token: this.state.token,
              productCost: this.state.productCost,
              nowTVCost: this.state.nowTVCost,
              serviceCost: this.state.serviceCost,
              shippingCost: this.state.shippingPrice,
              affiliateID: this.state.affiliateID,
              productName: this.props.purchasedItem.name,
            },
            this.props.history
          );
        }
      }
    );
  }

  startSubmission() {
    this.setState({
      isSubmitting: true,
    });
  }

  handleToken(token) {
    console.log(token);
    console.log(token.error);
    if (token.token) {
      if (this.state.shippingSame === true) {
        this.setState(
          {
            token: token,
            shippingCompany: this.state.company,
            shippingAddress1: this.state.address1,
            shippingAddress2: this.state.address2,
            shippingCity: this.state.city,
            shippingRegion: this.state.region,
            shippingCountry: this.state.country,
            shippingPostcode: this.state.postcode,
            shippingFirstName: this.state.firstName,
            shippingLastName: this.state.lastName,
            shippingPhone: this.state.phone,
          },
          () => {
            this.signTheUserUp(token);
          }
        );
      } else {
        this.signTheUserUp(token);
      }
    } else {
      this.setState({
        isSubmitting: false,
      });
      if (token.error && token.error.message) {
        this.notify(false, token.error.message);
        this.createInitialOrNewPaymentIntent();
      }
    }
  }

  signTheUserUp(token) {
    if (this.state.terms === true) {
      this.setState(
        {
          isSubmitting: true,
        },
        () => {
          if (this.state.paymentIntentId) {
            if (this.state.discount) {
              var product = this.state.productCost;
              product = product - this.state.discount;
              this.props.signupUser(
                {
                  paymentIntentId: this.state.paymentIntentId,
                  company: this.state.company,
                  address1: this.state.address1,
                  address2: this.state.address2,
                  city: this.state.city,
                  region: this.state.region,
                  country: this.state.country,
                  postcode: this.state.postcode,
                  shippingCompany: this.state.shippingCompany,
                  shippingAddress1: this.state.shippingAddress1,
                  shippingAddress2: this.state.shippingAddress2,
                  shippingCity: this.state.shippingCity,
                  shippingRegion: this.state.shippingRegion,
                  shippingCountry: this.state.shippingCountry,
                  shippingPostcode: this.state.shippingPostcode,
                  shippingFirstName: this.state.shippingFirstName,
                  shippingLastName: this.state.shippingLastName,
                  shippingPhone: this.state.shippingPhone,
                  email: this.state.username,
                  password: this.state.password,
                  firstName: this.state.firstName,
                  lastName: this.state.lastName,
                  phone: this.state.phone,
                  newsletter: false,
                  token: token,
                  productCost: product,
                  nowTVCost: this.state.nowTVCost,
                  serviceCost: this.state.serviceCost,
                  shippingCost: this.state.shippingPrice,
                  affiliateID: this.state.affiliateID,
                  productName: this.props.purchasedItem.name,
                },
                this.props.history
              );
            } else {
              this.props.signupUser(
                {
                  paymentIntentId: this.state.paymentIntentId,
                  company: this.state.company,
                  address1: this.state.address1,
                  address2: this.state.address2,
                  city: this.state.city,
                  region: this.state.region,
                  country: this.state.country,
                  postcode: this.state.postcode,
                  shippingCompany: this.state.shippingCompany,
                  shippingAddress1: this.state.shippingAddress1,
                  shippingAddress2: this.state.shippingAddress2,
                  shippingCity: this.state.shippingCity,
                  shippingRegion: this.state.shippingRegion,
                  shippingCountry: this.state.shippingCountry,
                  shippingPostcode: this.state.shippingPostcode,
                  shippingFirstName: this.state.shippingFirstName,
                  shippingLastName: this.state.shippingLastName,
                  shippingPhone: this.state.shippingPhone,
                  email: this.state.username,
                  password: this.state.password,
                  firstName: this.state.firstName,
                  lastName: this.state.lastName,
                  phone: this.state.phone,
                  newsletter: false,
                  token: token,
                  productCost: this.state.productCost,
                  nowTVCost: this.state.nowTVCost,
                  serviceCost: this.state.serviceCost,
                  shippingCost: this.state.shippingPrice,
                  affiliateID: this.state.affiliateID,
                  productName: this.props.purchasedItem.name,
                },
                this.props.history
              );
            }
          } else {
            console.log("No Payment Intent Id");
          }
        }
      );
    } else {
      this.notify(false, "You must agree to our terms");
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //console.log("Here are the props");
    //console.log(nextProps);

    if (
      nextProps &&
      nextProps.state &&
      nextProps.state.auth &&
      nextProps.state.auth.redirectURL
    ) {
      console.log("Found URL");
      this.setState(
        {
          redirectURL: nextProps.state.auth.redirectURL,
        },
        () => {
          this.setState({ secureModalOpen: true, isSubmitting: false });
        }
      );
      return;
    }

    if (
      nextProps &&
      nextProps.state &&
      nextProps.state.auth &&
      nextProps.state.auth.promo
    ) {
      var service = this.state.serviceCost;
      var product = this.state.productCost;
      var nowTvCost = parseFloat(
        Math.round(this.state.nowTVCost * 100) / 100
      ).toFixed(2);
      var total = service + product + nowTvCost;
      var discount = 0;
      if (nextProps.state.auth.promo.moneyOff) {
        discount = Math.round(nextProps.state.auth.promo.moneyOff * 100) / 100;
      } else {
        discount =
          Math.round(
            ((total * nextProps.state.auth.promo.percentageOff) / 100) * 100
          ) / 100;
      }
      this.setState(
        {
          isSubmitting: false,
          promo: nextProps.state.auth.promo,
          discount: discount,
        },
        () => {
          this.notify(true, "Your discount has been applied");
          this.updatePaymentIntent();
        }
      );

      return;
    }

    if (
      nextProps &&
      nextProps.state &&
      nextProps.state.auth &&
      nextProps.state.auth.paymentIntent
    ) {
      if (nextProps.state.auth.paymentIntent.client_secret) {
        this.setState({
          clientSecret: nextProps.state.auth.paymentIntent.client_secret,
          paymentIntentId: nextProps.state.auth.paymentIntent.id,
        });
      }
      return;
    }

    if (
      nextProps &&
      nextProps.state &&
      nextProps.state.auth &&
      nextProps.state.auth.promoError
    ) {
      //console.log(nextProps.state.promoError);
      this.setState(
        {
          isSubmitting: false,
          promo: null,
          discount: null,
        },
        () => {
          this.notify(false, nextProps.state.auth.promoError);
        }
      );
      this.refs.promoCode.value = "";
      //this.props.resetQuestions()
      return;
    }

    if (
      nextProps &&
      nextProps.state &&
      nextProps.state.auth &&
      nextProps.state.auth.successful === true
    ) {
      var services = this.state.serviceCost;
      var products = this.state.productCost;
      var nowTvCosts = parseFloat(
        Math.round(this.state.nowTVCost * 100) / 100
      ).toFixed(2);
      var totals = services + products + nowTvCosts;
      this.setState(
        {
          isSubmitting: false,
        },
        () => {
          this.notify(
            true,
            "Congratulations, please check your email for details of your purchase, you may now login"
          );
          setTimeout(() => {
            this.eventTrack(
              this.state.productCost,
              "purchase",
              "Purchase Product",
              totals
            );
            this.props.history.push("/login");
          }, 6000);
        }
      );
      return;
    } else {
      this.setState(
        {
          isSubmitting: false,
        },
        () => {
          if (
            nextProps.state.auth.errorMessage &&
            nextProps.state.auth.errorMessage.response &&
            nextProps.state.auth.errorMessage.response.data &&
            nextProps.state.auth.errorMessage.response.data.error &&
            nextProps.state.auth.errorMessage.response.data.error
          ) {
            if (
              nextProps.state.auth.errorMessage.response.data.error.includes(
                "zip"
              ) ||
              nextProps.state.auth.errorMessage.response.data.error.includes(
                "code"
              )
            ) {
              this.notify(
                false,
                nextProps.state.auth.errorMessage.response.data.error +
                  " Please ensure you enter your Card's Registered Postal Code into the payment method field, not the delivery Postal Code"
              );
            } else {
              this.notify(
                false,
                nextProps.state.auth.errorMessage.response.data.error
              );
            }
          } else {
            this.notify(
              false,
              "There was an error purchasing the product, please try again"
            );
          }
          console.log("IM HERE");
          this.createInitialOrNewPaymentIntent();
        }
      );
    }
  }

  submitMain() {
    if (this.state.terms === true) {
      this.refs.mainFormButton.click();
    } else {
      this.notify(false, "You must agree to our terms");
    }
  }
  submitEmail() {
    if (this.state.terms === true) {
      this.refs.emailFormButton.click();
    } else {
      this.notify(false, "You must agree to our terms");
    }
  }

  handleSubmit(values) {
    values.preventDefault();
    //console.log("Called");
    //console.dir(values);
    if (this.state.terms === true) {
      this.setState({ mainForm: true });
      this.refs.emailFormButton.click();
    } else {
      this.notify(false, "You must agree to our terms");
    }
  }

  handleAccountSubmit(values) {
    values.preventDefault();
    //console.log("Also Called");
    //console.dir(values);
    this.setState(
      {
        emailForm: true,
      },
      () => {
        if (this.state.emailForm === true && this.state.mainForm === true) {
          setTimeout(() => {
            if (this.state.terms === true) {
              this.child.clickButton();
            } else {
              this.notify(false, "You must agree to our terms");
            }
          }, 500);
        }
      }
    );
  }

  // submitForm(e) {
  //   e.preventDefault();
  //   //console.log(this.state);
  //   if (this.props.stripe) {
  //     this.props.stripe
  //       .createToken()
  //       .then(payload => console.log('[token]', payload));
  //   } else {
  //     //console.log("Stripe.js hasn't loaded yet.");
  //   }
  //   return false;
  // }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  selectCountry(val) {
    this.setState({ country: val });
    if (this.state.shippingCountry.length === 0) {
      var found = false;
      for (var key in this.state.shippingPrices) {
        var value = this.state.shippingPrices[key];
        if (val.toLowerCase() === key) {
          found = true;
          this.setState({
            shippingPrice: value,
            totalCost:
              value +
              this.state.productCost +
              this.state.serviceCost +
              this.state.nowTVCost,
          });
          break;
        }
      }
      if (!found) {
        this.setState({
          shippingPrice: this.state.defaultShippingPrice,
          totalCost:
            this.state.defaultShippingPrice +
            this.state.productCost +
            this.state.serviceCost +
            this.state.nowTVCost,
        });
      }
    }
  }

  selectRegion(val) {
    this.setState({ region: val });
    if (this.state.shippingRegion.length === 0) {
      if (this.state.country === "Spain") {
        if (val === "Gran Canaria") {
          this.setState(
            {
              shippingPrice: this.state.shippingPrices.granCanaria,
              totalCost:
                this.state.shippingPrices.granCanaria +
                this.state.productCost +
                this.state.serviceCost +
                this.state.nowTVCost,
            },
            () => {
              this.updatePaymentIntent();
              return;
            }
          );
        } else {
          this.setState(
            {
              shippingPrice: this.state.shippingPrices.spain,
              totalCost:
                this.state.shippingPrices.spain +
                this.state.productCost +
                this.state.serviceCost +
                this.state.nowTVCost,
            },
            () => {
              this.updatePaymentIntent();
              return;
            }
          );
        }
      } else {
        this.updatePaymentIntent();
        return;
      }
    }
    this.updatePaymentIntent();
  }

  selectShippingCountry(val) {
    this.setState({ shippingCountry: val });
    var found = false;
    for (var key in this.state.shippingPrices) {
      //console.log("The key is " + key);
      var value = this.state.shippingPrices[key];
      //console.log("The value is " + value);
      if (val.toLowerCase() === key) {
        found = true;
        this.setState({
          shippingPrice: value,
          totalCost:
            value +
            this.state.productCost +
            this.state.serviceCost +
            this.state.nowTVCost,
        });
        break;
      }
    }
    if (!found) {
      this.setState({
        shippingPrice: this.state.defaultShippingPrice,
        totalCost:
          this.state.defaultShippingPrice +
          this.state.productCost +
          this.state.serviceCost +
          this.state.nowTVCost,
      });
    }
  }

  selectShippingRegion(val) {
    this.setState({ shippingRegion: val });
    if (this.state.shippingCountry === "Spain") {
      if (val === "Gran Canaria") {
        this.setState(
          {
            shippingPrice: this.state.shippingPrices.granCanaria,
            totalCost:
              this.state.shippingPrices.granCanaria +
              this.state.productCost +
              this.state.serviceCost +
              this.state.nowTVCost,
          },
          () => {
            this.updatePaymentIntent();
          }
        );
      } else {
        this.setState(
          {
            shippingPrice: this.state.shippingPrices.spain,
            totalCost:
              this.state.shippingPrices.spain +
              this.state.productCost +
              this.state.serviceCost +
              this.state.nowTVCost,
          },
          () => {
            this.updatePaymentIntent();
          }
        );
      }
    } else {
      this.updatePaymentIntent();
    }
  }

  updatePaymentIntent() {
    console.log("Called Change Payment Intent");
    var product = this.state.productCost;
    if (
      this.state.nowTVCost &&
      this.state.shippingPrice &&
      this.state.productCost &&
      this.state.serviceCost &&
      this.state.product &&
      this.state.product.name &&
      this.props &&
      this.props.paymentIntent &&
      this.props.paymentIntent.id
    ) {
      console.log("Updating Old Payment Intent");
      if (this.state.discount) {
        product = product - this.state.discount;
        this.props.updatePaymentIntent(
          this.props.paymentIntent.id,
          this.state.nowTVCost,
          product,
          this.state.serviceCost,
          this.state.shippingPrice
        );
      } else {
        this.props.updatePaymentIntent(
          this.props.paymentIntent.id,
          this.state.nowTVCost,
          this.state.productCost,
          this.state.serviceCost,
          this.state.shippingPrice
        );
      }
    } else if (
      this.state.shippingPrice &&
      this.state.productCost &&
      this.state.serviceCost &&
      this.state.product &&
      this.state.product.name
    ) {
      this.createInitialOrNewPaymentIntent();
    } else {
      console.log("Not Called");
    }
  }

  createInitialOrNewPaymentIntent() {
    console.log("Creating New Payment Intent");
    var product = this.state.productCost;
    if (
      this.state.shippingPrice &&
      this.state.productCost &&
      this.state.serviceCost &&
      this.state.product &&
      this.state.product.name
    ) {
      if (this.state.discount) {
        product = product - this.state.discount;
        this.props.getPaymentIntent(
          this.state.nowTVCost,
          product,
          this.state.serviceCost,
          this.state.shippingPrice,
          this.state.product.name
        );
      } else {
        this.props.getPaymentIntent(
          this.state.nowTVCost,
          this.state.productCost,
          this.state.serviceCost,
          this.state.shippingPrice,
          this.state.product.name
        );
      }
    }
  }

  checkIfLoggedIn() {
    switch (this.props.auth) {
      case false:
        //console.log("Not Logged In");
        break;
      case null:
        //console.log("Not Logged In");
        break;
      default:
        //console.log("Logged In");
        break;
    }
  }

  handlePromoCode(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  checkPromo() {
    //console.log(this.state.promoCode);
    if (this.state.promoCode.length > 0 && this.state.product.name) {
      this.setState({
        isSubmitting: true,
      });
      this.props.checkPromo({
        promoCode: this.state.promoCode,
        name: this.state.product.name,
      });
    } else {
      this.setState(
        {
          discount: null,
          promo: null,
        },
        () => {
          this.updatePaymentIntent();
        }
      );
    }
  }

  renderNewAccount() {
    if (this.state.newAccount === true) {
      return (
        <div id="newaccount" className="mt-10 mb-30">
          <div className="row mb-0">
            <div className="col-md-12 col-sm-12">
              <label htmlFor="billing_email">Email *</label>
              <input
                id="username"
                onChange={this.handleInputChange}
                name="username"
                required={true}
                type="text"
                className="form-control required"
              />
            </div>
          </div>
          <div className="row mb-0">
            <div className="col-md-6 col-sm-6">
              <label htmlFor="account:password">Password *</label>
              <input
                type="text"
                id="browser-friendly-login-field-location"
                value="{this.state.username}"
                onChange={this.handleInputChange}
                style={{ display: "none" }}
              />
              <input
                id="account:password"
                onChange={this.handleInputChange}
                name="password"
                type="password"
                className="form-control"
                required={true}
                autoComplete="false"
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <label htmlFor="account:password2">Confirm Password *</label>
              <input
                id="account:password2"
                onChange={this.handleInputChange}
                name="password2"
                type="password"
                className="form-control"
                required={true}
                autoComplete="false"
              />
            </div>
          </div>
          <small className="text-warning">
            NOTE: Email address will be used to login
          </small>
          <div className="row">
            <div className="col-lg-12 m-0 clearfix">
              <label className="checkbox float-left">
                <input
                  id="termsbox"
                  name="termsbox"
                  type="checkbox"
                  onChange={() => this.setState({ terms: !this.state.terms })}
                  checked={this.state.terms}
                />
                <i></i>{" "}
                <span className="fw-300">
                  Agree to our terms and conditions
                </span>{" "}
                <a
                  href="#terms"
                  style={{ color: "#000000" }}
                  data-toggle="modal"
                  data-target="#termsModal"
                >
                  Click Here To View
                </a>
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 m-0 clearfix">
              <label htmlFor="billing_email">ADD PROMO CODE</label>
              <input
                id="promoCode"
                ref="promoCode"
                name="promoCode"
                onChange={this.handlePromoCode}
                required={false}
                type="text"
                className="form-control"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 m-0 clearfix">
              <button
                type="button"
                className="btn btn-secondary"
                name="createDiscount"
                id="createDiscount"
                onClick={this.checkPromo}
              >
                Apply Code
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  renderShippingAddress() {
    const { shippingSame, shippingCountry, shippingRegion } = this.state;
    if (this.state.shippingSame === false) {
      return (
        <fieldset id="shipping" className="mt-80">
          <h4>Shipping Address</h4>
          <hr />
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <label htmlFor="shippingFirstName">First Name *</label>
              <input
                id="shippingFirstName"
                onChange={this.handleInputChange}
                required={!shippingSame}
                name="shippingFirstName"
                type="text"
                className="form-control required"
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <label htmlFor="shippingLastName">Last Name *</label>
              <input
                id="shippingLastName"
                name="shippingLastName"
                required={!shippingSame}
                onChange={this.handleInputChange}
                type="text"
                className="form-control required"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-sm-6">
              <label htmlFor="shippingCompany">Company</label>
              <input
                id="shippingCompany"
                name="shippingCompany"
                onChange={this.handleInputChange}
                type="text"
                className="form-control"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <label htmlFor="shippingAddress1">Address *</label>
              <input
                id="shippingAddress1"
                name="shippingAddress1"
                required={!shippingSame}
                onChange={this.handleInputChange}
                type="text"
                className="form-control required"
                placeholder="Address 1"
              />

              <input
                id="shippingAddress2"
                name="shippingAddress2"
                required={!shippingSame}
                onChange={this.handleInputChange}
                type="text"
                className="form-control mt-10"
                placeholder="Address 2"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-sm-6">
              <label htmlFor="shippingCity">City *</label>
              <input
                id="shippingCity"
                name="shippingCity"
                required={!shippingSame}
                onChange={this.handleInputChange}
                type="text"
                className="form-control required"
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <label htmlFor="shipping:zipcode">Postal Code *</label>
              <input
                id="shippingPostcode"
                name="shippingPostcode"
                required={!shippingSame}
                onChange={this.handleInputChange}
                type="text"
                className="form-control required"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-sm-6">
              <label htmlFor="shippingCountry">Country *</label>
              <label className="input mb-10">
                <CountryDropdown
                  value={shippingCountry}
                  onChange={(val) => this.selectShippingCountry(val)}
                  style={{ width: "100px" }}
                  required={!shippingSame}
                />
                <b className="tooltip tooltip-bottom-right">Your Country</b>
              </label>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-sm-6">
              <label htmlFor="shippingRegion">Region *</label>
              <label className="input mb-10">
                <RegionDropdown
                  country={shippingCountry}
                  value={shippingRegion}
                  onChange={(val) => this.selectShippingRegion(val)}
                  disableWhenEmpty={true}
                  blankOptionLabel="Select Country First"
                  required={!shippingSame}
                />
                <b className="tooltip tooltip-bottom-right">Your Region</b>
              </label>
            </div>
            <div className="col-md-6 col-sm-6">
              <label htmlFor="shippingPhone">Phone *</label>
              <input
                id="shippingPhone"
                name="shippingPhone"
                onChange={this.handleInputChange}
                required={!shippingSame}
                type="text"
                className="form-control required"
              />
            </div>
          </div>
        </fieldset>
      );
    }
  }

  handleAgree() {
    var agreed = !this.state.terms;
    this.setState({
      terms: agreed,
    });
  }

  dependantPlan() {
    var current = this.state.active;
    var getClass = function(name, index) {
      if (index === current) return name + " active";
      return name;
    };
    var total = 0;
    if (this.state.discount) {
      total = parseFloat(
        parseFloat(
          Math.round(this.state.totalCost * 100).toFixed(2) / 100
        ).toFixed(2) -
          parseFloat(
            Math.round(this.state.discount * 100).toFixed(2) / 100
          ).toFixed(2)
      ).toFixed(2);
    } else {
      total = parseFloat(
        parseFloat(
          Math.round(this.state.totalCost * 100).toFixed(2) / 100
        ).toFixed(2)
      ).toFixed(2);
    }
    if (
      this.props.purchasedItem &&
      this.props.purchasedItem.name === "Monthly Plan"
    ) {
      return (
        <div className="card-block">
          <div className="content">
            <span className="clearfix">
              <span className="float-right">€{this.state.productCost}</span>
              <strong className="float-left">Preconfigured Router:</strong>
            </span>
            <span className="clearfix">
              <span className="float-right">€{this.state.serviceCost}</span>
              <strong className="float-left">Monthly Subscription:</strong>
            </span>
            {/* {this.props.defaults && this.props.defaults.nowtvStock ? (
              <span className="clearfix">
                <span className="float-right">
                  €
                  {parseFloat(
                    Math.round(this.state.nowTVCost * 100) / 100
                  ).toFixed(2)}
                </span>
                <strong className="float-left">Roku Premiere Devices:</strong>
              </span>
            ) : (
              <span></span>
            )} */}
            <span className="clearfix">
              <span className="float-right">
                €{this.state.shippingPrice.toFixed(2)}
              </span>
              <span className="float-left">Shipping:</span>
            </span>
            {this.state.discount ? (
              <span className="clearfix">
                <span className="float-right">
                  - €{this.state.discount.toFixed(2)}
                </span>
                <span className="float-left">
                  Discount ({this.state.promo.code}):
                </span>
              </span>
            ) : (
              ""
            )}
            <hr />
            {this.props.defaults && this.props.defaults.deliveryTime ? (
              <span className="clearfix">
                <span className="float-right fs-20">
                  {this.props.defaults.deliveryTime}
                </span>
                <strong className="float-left">Expected Dispatch:</strong>
              </span>
            ) : (
              <span></span>
            )}
            <hr />
            {this.state.discount ? (
              <span className="clearfix">
                <span className="float-right fs-20">€{total}</span>
                <strong className="float-left">TOTAL:</strong>
              </span>
            ) : (
              <span className="clearfix">
                <span className="float-right fs-20">€{total}</span>
                <strong className="float-left">TOTAL:</strong>
              </span>
            )}
            <hr />
            <br />
            {/* {this.props.defaults && this.props.defaults.nowtvStock ? (
              <div>
                <strong className="float-left">Add a Roku Premiere?</strong>
                <span className="float-right">
                  <ButtonGroup aria-label="Basic example">
                    <Button
                      variant="secondary"
                      onClick={this.setActive.bind(this, 0)}
                      className={getClass("No", 0)}
                    >
                      No
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={this.setActive.bind(this, 1)}
                      className={getClass("add1", 1)}
                    >
                      Add 1
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={this.setActive.bind(this, 2)}
                      className={getClass("add2", 2)}
                    >
                      Add 2
                    </Button>
                  </ButtonGroup>
                </span>{" "}
                <br /> <br /> <hr />{" "}
              </div>
            ) : (
              <span></span>
            )} */}
            <div className="clearfix">
              <span className="fw-300">
                Please create an account for later use
              </span>
            </div>
            {this.renderNewAccount()}
          </div>
        </div>
      );
    } else {
      return (
        <div className="card-block">
          <div className="content">
            <span className="clearfix">
              <span className="float-right">€{this.state.productCost}</span>
              <strong className="float-left">Preconfigured Router:</strong>
            </span>
            <span className="clearfix">
              <span className="float-right">€{this.state.serviceCost}</span>
              <strong className="float-left">
                Service cost ({this.props.purchasedItem.name}):
              </strong>
            </span>
            {/* {this.props.defaults && this.props.defaults.nowtvStock ? (
              <span className="clearfix">
                <span className="float-right">
                  €
                  {parseFloat(
                    Math.round(this.state.nowTVCost * 100) / 100
                  ).toFixed(2)}
                </span>
                <strong className="float-left">Roku Premiere Devices:</strong>
              </span>
            ) : (
              <span></span>
            )} */}
            <span className="clearfix">
              <span className="float-right">€{this.state.shippingPrice}</span>
              <span className="float-left">Shipping:</span>
            </span>
            {this.state.discount ? (
              <span className="clearfix">
                <span className="float-right">
                  - €{this.state.discount.toFixed(2)}
                </span>
                <span className="float-left">
                  Discount ({this.state.promo.code}):
                </span>
              </span>
            ) : (
              ""
            )}
            <hr />
            {this.props.defaults && this.props.defaults.deliveryTime ? (
              <span className="clearfix">
                <span className="float-right fs-20">
                  {this.props.defaults.deliveryTime}
                </span>
                <strong className="float-left">Expected Dispatch:</strong>
              </span>
            ) : (
              <span></span>
            )}
            <hr />
            {this.state.discount ? (
              <span className="clearfix">
                <span className="float-right fs-20">€{total}</span>
                <strong className="float-left">TOTAL:</strong>
              </span>
            ) : (
              <span className="clearfix">
                <span className="float-right fs-20">€{total}</span>
                <strong className="float-left">TOTAL:</strong>
              </span>
            )}
            <hr />
            <br />
            {this.props.defaults && this.props.defaults.nowtvStock ? (
              <div>
                <strong className="float-left">Add a Roku Premiere?:</strong>
                <span className="float-right">
                  <ButtonGroup aria-label="Basic example">
                    <Button
                      variant="secondary"
                      onClick={this.setActive.bind(this, 0)}
                      className={getClass("No", 0)}
                    >
                      No
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={this.setActive.bind(this, 1)}
                      className={getClass("add1", 1)}
                    >
                      Add 1
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={this.setActive.bind(this, 2)}
                      className={getClass("add2", 2)}
                    >
                      Add 2
                    </Button>
                  </ButtonGroup>
                </span>
                <br />
                <br />
                <hr />
              </div>
            ) : (
              <span></span>
            )}
            <div className="clearfix">
              <span className="fw-300">
                Please create an account for later use
              </span>
            </div>
            {this.renderNewAccount()}
          </div>
        </div>
      );
    }
  }

  showFooter() {
    if (!this.state.secureModalOpen) {
      return <Footer />;
    }
  }

  showSpinner() {
    if (this.state.isSubmitting) {
      return (
        <Loader
          fullPage
          loading
          text="Please wait..."
          textStyle={{ color: "black" }}
        />
      );
    }
  }

  openModal() {
    this.setState({ secureModalOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  closeModal() {
    this.setState({ secureModalOpen: false });
  }

  render() {
    if (!this.state) {
      return null;
    }
    const { country, region } = this.state;
    return (
      <div id="wrapper">
        <Header />

        <Modal
          isOpen={this.state.secureModalOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          shouldCloseOnOverlayClick={false}
          overlayClassName="Overlay"
        >
          <SecureIframe
            completed3DS={this.completed3DS}
            sourceURL={this.state.redirectURL}
          />
        </Modal>

        <ToastContainer />
        {this.showSpinner()}
        <section className="page-header page-header-xs">
          <div className="container">
            <h1>CHECKOUT</h1>
            {/* <h4 className="heading-title" style={{ color: "red" }}>
              Due to illness we can't ship anymore new routers until 08/01/24
              sorry for any inconvenience. It could change, if so we will update
              here.
            </h4> */}
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-sm-7">
                <div className="heading-title">
                  <h4>Billing &amp; Shipping</h4>
                </div>
                <form onSubmit={this.handleSubmit}>
                  <button
                    type="submit"
                    id="hiddenFormButton"
                    ref="mainFormButton"
                    style={{ display: "none" }}
                  ></button>
                  <fieldset className="mt-60">
                    <div className="row">
                      <div className="col-md-6 col-sm-6">
                        <label htmlFor="firstName">First Name *</label>
                        <input
                          id="firstName"
                          onChange={this.handleInputChange}
                          name="firstName"
                          type="text"
                          required={true}
                          className="form-control required"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <label htmlFor="billing_lastname">Last Name *</label>
                        <input
                          id="lastName"
                          onChange={this.handleInputChange}
                          name="lastName"
                          required={true}
                          type="text"
                          className="form-control required"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 col-sm-6">
                        <label htmlFor="company">Company</label>
                        <input
                          id="company"
                          onChange={this.handleInputChange}
                          name="company"
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <label htmlFor="billing_address1">Address *</label>
                        <input
                          id="address1"
                          onChange={this.handleInputChange}
                          required={true}
                          name="address1"
                          type="text"
                          className="form-control required"
                          placeholder="Address 1"
                        />
                        <input
                          id="address2"
                          onChange={this.handleInputChange}
                          name="address2"
                          type="text"
                          className="form-control mt-10"
                          placeholder="Address 2"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 col-sm-6">
                        <label htmlFor="city">City *</label>
                        <input
                          id="city"
                          onChange={this.handleInputChange}
                          required={true}
                          name="city"
                          type="text"
                          className="form-control required"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <label htmlFor="postcode">Zip/Postal Code *</label>
                        <input
                          id="postcode"
                          onChange={this.handleInputChange}
                          required={true}
                          name="postcode"
                          type="text"
                          className="form-control required"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 col-sm-6">
                        <label htmlFor="country">Country *</label>
                        <label className="input mb-10">
                          <CountryDropdown
                            id="country"
                            value={country}
                            onChange={(val) => this.selectCountry(val)}
                            style={{ width: "100px" }}
                            required={true}
                          />
                          <b className="tooltip tooltip-bottom-right">
                            Your Country
                          </b>
                        </label>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 col-sm-6">
                        <label htmlFor="region">Region *</label>
                        <label className="input mb-10">
                          <RegionDropdown
                            id="region"
                            country={country}
                            value={region}
                            onChange={(val) => this.selectRegion(val)}
                            disableWhenEmpty={true}
                            required={true}
                            blankOptionLabel="Select Country First"
                          />
                          <b className="tooltip tooltip-bottom-right">
                            Your Region
                          </b>
                        </label>
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <label htmlFor="phone">Phone *</label>
                        <input
                          id="phone"
                          onChange={this.handleInputChange}
                          name="phone"
                          type="text"
                          className="form-control required"
                          required={true}
                        />
                      </div>
                    </div>

                    <hr />

                    <div className="row">
                      <div className="col-lg-12 m-0 clearfix">
                        <label className="checkbox float-left">
                          <input
                            id="shipswitch"
                            name="shippingSame"
                            type="checkbox"
                            onChange={() =>
                              this.setState({
                                shippingSame: !this.state.shippingSame,
                              })
                            }
                            checked={this.state.shippingSame}
                          />
                          <i></i>{" "}
                          <span className="fw-300">
                            Ship to the same address
                          </span>
                        </label>
                      </div>
                    </div>
                  </fieldset>
                  {this.renderShippingAddress()}
                </form>
              </div>

              <div className="col-lg-5 col-sm-5">
                <div
                  className="card card-default"
                  style={{ backgroundColor: "#EEEEEE" }}
                >
                  <form onSubmit={this.handleAccountSubmit}>
                    <button
                      type="submit"
                      id="emailFormButton"
                      ref="emailFormButton"
                      style={{ display: "none" }}
                    ></button>
                    {this.dependantPlan()}
                  </form>
                </div>
                <fieldset
                  id="ccPayment"
                  className="mt-30 card card-default"
                  style={{ backgroundColor: "#EEEEEE" }}
                >
                  <div className="card-block">
                    <h4>Payment Method</h4>
                    <div className="row">
                      <div className="col-lg-12">
                        <label htmlFor="cc_number">Credit Card Number *</label>
                        <label className="text-warning" htmlFor="cc_number">
                          Please use Card Billing Postcode if requested.
                        </label>
                        <Elements>
                          <CardForm
                            onRef={(ref) => (this.child = ref)}
                            startSubmission={this.startSubmission}
                            handleToken={this.handleToken}
                            mainForm={this.state.mainForm}
                            emailForm={this.state.emailForm}
                            submitMain={this.submitMain}
                            submitEmail={this.submitEmail}
                          />
                        </Elements>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </section>
        <div
          className="modal fade"
          id="termsModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  &times;
                </button>
                <h4 className="modal-title" id="myModal">
                  Terms &amp; Conditions
                </h4>
              </div>

              <div className="modal-body modal-short">
                <h2>
                  <b>Terms and conditions of use</b>
                </h2>
                <div>
                  <p>
                    By making use of the UK TV Router provision of service you
                    agree to our Terms and Conditions of Use, Privacy and
                    Refund.
                  </p>

                  <h3>Article 1 General</h3>
                  <p>
                    1.1 These terms and conditions apply to the agreement
                    between UK TV ROUTER and the user related to the delivery of
                    the VPN service by UK TV ROUTER. These terms and conditions
                    form an integral part of the agreement.
                  </p>
                  <p>
                    1.2 The user of UK TV ROUTER’s VPN service agrees without
                    any proviso to all Terms and Conditions and the Privacy
                    Statement of UK TV ROUTER.
                  </p>
                  <p>
                    1.3 General terms and conditions or stipulations on the part
                    of the user are expressly rejected by UK TV ROUTER.
                  </p>
                  <p>
                    1.4 Derogations from these provisions will only be valid if
                    these have been expressly agreed and recorded in writing
                    between UK TV ROUTER and the user.
                  </p>
                  <p></p>
                  <h3>Article 2 Coming into effect of the agreement</h3>
                  <p>
                    2.1 An offer and/or quotation from UK TV ROUTER will be an
                    invitation to the potential user to choose one of the
                    subscriptions. The agreement between UK TV ROUTER and the
                    user will come into effect when UK TV ROUTER accepts the
                    choice of the user electronically. UK TV ROUTER will not be
                    obliged to acceptance thereof.
                  </p>
                  <p>
                    2.2 The user must generate an account to be able to make use
                    of the VPN service, which account will provide UK TV ROUTER
                    with the necessary data for the provision of the service,
                    the payment and the statutory obligations UK TV ROUTER must
                    comply with.
                  </p>
                  <p>
                    2.3 The user must pay an amount as set out on the website
                    for the verification of the data in the account.
                  </p>
                  <p>
                    2.4 Following the verification of the account and the
                    acceptance by UK TV ROUTER the VPN service will immediately
                    be made available for the user within the preconditions of
                    the chosen subscription.
                  </p>
                  <p>
                    2.5 The user guarantees the provision of correct personal
                    data to UK TV ROUTER, including the address, place of
                    residence, telephone and account numbers. Changes of
                    personal data must be passed electronically to UK TV ROUTER
                    as soon as possible.
                  </p>
                  <p></p>
                  <h3>
                    Article 3 Duration, amendment and termination of the
                    agreement
                  </h3>
                  <p>
                    3.1 The agreement is entered into for a fixed or indefinite
                    period.
                  </p>
                  <p>
                    3.2 Following the end of the first agreed period, the
                    agreement for a fixed period will be converted into an
                    agreement for an indefinite period. Unless UK TV ROUTER or
                    the user state electronically or in writing prior to the
                    expiry of the agreed period that they do not wish the
                    agreement to continue.
                  </p>
                  <p>
                    3.3 The user can choose another subscription at any desired
                    time. It is possible to extend subscription.
                  </p>
                  <p>
                    3.4 The user can cancel the subscription without statement
                    of reason by means of email, within the first 30 days. This
                    arrangement applies only to new users. Commercial users as
                    well as consumers can make use of this arrangement.
                  </p>
                  <p>
                    3.5 In all other cases cancellation can take place at any
                    desired time within the duration of the chosen subscription.
                    In that case the user will not have the right to refund of
                    the subscription costs paid in advance.
                  </p>
                  <p></p>
                  <h3>Article 4 Amendments</h3>
                  <p>
                    4.1 UK TV ROUTER has the right at any time to adjust these
                    terms and conditions, the privacy statement, and the
                    technical characteristics of the service and rates.
                  </p>
                  <p>
                    4.2 Amendments will be electronically notified to the user.
                  </p>
                  <p>
                    4.3 Amendments will come into effect 1 month after
                    notification.
                  </p>
                  <p>
                    4.4 If the amendment(s) objectively disadvantage the user,
                    the user will have the right to cancel the service within
                    the month referred to.
                  </p>
                  <p>
                    4.5 If a judicial decision, or a statutory provision, and/or
                    measures ensuing therefrom for UK TV ROUTER, compel UK TV
                    ROUTER to adjust these terms and conditions, privacy
                    statement, service and/or rates, the user will not have the
                    right to interim termination of the service.
                  </p>
                  <p>
                    4.6 If one of these provisions under these terms and
                    conditions is wholly or in part in conflict with a statutory
                    provision, or is null and void, the remaining terms and
                    conditions will remain in full force. A new provision will
                    replace the old earlier provision, whereby the original
                    intention will be approached as closely as possible.
                  </p>
                  <p></p>
                  <h3>Article 5 Obligations on the part of the user</h3>
                  <p>5.1 The user will not breach any legal rules.</p>
                  <p>
                    5.2 The user will refrain from conduct whereby, or as a
                    result of which, other internet users will (can) experience
                    hindrance, or whereby, or as a result of which, third party
                    rights are infringed.
                  </p>
                  <p>
                    5.3 The user will not undertake any activities as a result
                    of which damage can be caused to UK TV ROUTER’s system.
                  </p>
                  <p>
                    5.4 PEER TO PEER (P2P) The use of P2P applications (eg
                    BitTorrent) is strictly prohibited. P2P applications are
                    commonly used to download copyrighted material.
                  </p>
                  <p>Copyright law</p>
                  <p>
                    It is an offence under UK law to make copies of
                    commercially-produced music recordings, videos, images and
                    computer software. You are not permitted to make copies for
                    personal use. You must have permission from the rights
                    holder to copy any such material.
                  </p>
                  <p>
                    Many P2P applications automatically make downloaded files
                    available to other P2P users. This is dealing in digital
                    contraband, resulting in increased seriousness of the
                    copyright breach committed.
                  </p>
                  <p>Security risks</p>
                  <p>
                    P2P applications can lead to abuse either by those supplying
                    the software or by third parties. Applications can include
                    license agreements whereby the licensee allows the licensor
                    the right to use all available CPU and network resources on
                    the installed machine for any reason they see fit. We cannot
                    accept such a condition.
                  </p>
                  <p>
                    Many P2P downloads contain malware (viruses, worms,
                    trojans). It is often impossible to know whether a file
                    being downloaded via P2P is really what it claims.
                  </p>
                  <p>
                    Users of P2P can also share more than they intended to with
                    other users. Such as sharing your documents folder by
                    accident and putting your data at risk.
                  </p>
                  <p>Legitimate use</p>
                  <p>
                    If you have a legitimate use for P2P applications, please
                    use your ISP (internet service provider) network
                  </p>
                  <p>Sanction</p>
                  <p>Sanctions for inappropriate use may include:</p>
                  <p>
                    Immediate cancelation of account with no rights to a refund.
                  </p>
                  <p>
                    5.5 The user will be obliged, as an internet user, to behave
                    responsibly and carefully.
                  </p>
                  <p>
                    5.6 The user will not make his/her account available to
                    third parties. The user will be responsible for all
                    activities, which take place via or with the aid of the
                    user’s account, whether or not with the user’s permission.
                  </p>
                  <p>
                    5.7 If it has been noticed or is suspected that the user
                    does not, or does not sufficiently, comply with the
                    obligations set out, UK TV ROUTER will have the right to
                    close down the service, whether or not entirely, for this
                    user. In that event UK TV ROUTER will not be liable for
                    potential damage on the part of the user or a third party
                    resulting from the closing down.
                  </p>
                  <p>
                    5.8 If the user does not act in accordance with the
                    provisions above, UK TV ROUTER will be entitled to impose
                    instructions on the user. The user will be obliged to follow
                    these. Any financial consequences will be at the user’s
                    expense. If this situation occurs the user cannot make claim
                    to any refund.
                  </p>
                  <p>
                    5.9 UK TV ROUTER has the right to take all measures that are
                    necessary to protect its network, its users and third
                    parties, against impermissible conduct – in whatsoever form
                    – on the part of specific users.
                  </p>
                  <p></p>
                  <h3>Article 6 Availability and fair use policy</h3>
                  <p>
                    6.1 UK TV ROUTER arranges the service as it deems most
                    suitable. UK TV ROUTER will be entitled to change procedures
                    and the technical aspects of the service.
                  </p>
                  <p>
                    6.2 UK TV ROUTER makes endeavours to be of service to its
                    users in the best possible manner in the field of speed,
                    availability, security and usability. However, UK TV ROUTER
                    cannot guarantee this due to inter alia breakdowns,
                    necessary maintenance and adjustments of the system for
                    improvement.
                  </p>
                  <p>
                    6.3 UK TV ROUTER does not apply a strict bandwidth
                    limitation in its subscription. However, a fair use policy
                    does apply. Users, who use more than 0.5% of the entire
                    bandwidth of the UK TV ROUTER network, will be approached by
                    UK TV ROUTER to reduce the use, or to make a higher payment.
                  </p>
                  <p></p>
                  <h3>Article 7 Rates and payment</h3>
                  <p>
                    7.1 UK TV Router charges a payment for the use of the
                    service in accordance with the rates published in UK TV
                    ROUTER’s website.
                  </p>
                  <p>
                    7.2 All rates referred to on the website are including IVA
                    and any other duties or taxes imposed by authorities.
                  </p>
                  <p>
                    7.3 If the agreement is converted into an agreement for an
                    indefinite period the collection of the payment will take
                    place by means of credit/debit card monthly automatic
                    payment.
                  </p>
                  <p>
                    7.4 The collection will take place prior to the provision of
                    the service.
                  </p>
                  <p>
                    7.5 It is assumed for the recording of the amount of the
                    collection that the user continues with his/her choice of
                    package, unless the user has notified otherwise.
                  </p>
                  <p>
                    7.6 UK TV ROUTER will notify you each time you have made a
                    subscription payment. If you have a recurring subscription
                    and your card changes you must login to your account and
                    change your payment card prior to the next payment date or
                    the service will cease when payment fails. You can however
                    login at uktvrouter.com and re-subscribe and the service
                    will resume.
                  </p>
                  <p>
                    7.7 The user will be in default without further notice of
                    default if the payment collection cannot take place. The
                    service will terminate until the user renews their
                    subscription.
                  </p>
                  <p>
                    7.8 The user must make objections against the amount of the
                    collection apparent within 30 days. The user will be deemed
                    to agree to the amount of the collection after this period.
                  </p>
                  <p></p>
                  <h3>Article 8 Force Majeure</h3>
                  <p>
                    8.1 If UK TV ROUTER, due to a non-attributable failure
                    (force majeure) cannot fulfil its obligations, the
                    fulfilment of these obligations will be suspended for the
                    duration of the force majeure.
                  </p>
                  <p>
                    8.2 A failure in the provision of service cannot be
                    attributed to UK TV ROUTER if this cannot be blamed on fault
                    on its part, or a cause for which UK TV ROUTER is not
                    accountable by law, by legal act or according to generally
                    accepted standards. These circumstances also include, but
                    are not limited to: power failures, war, environmental
                    disasters, breakdowns of the internet connection, breakdowns
                    in the telecommunication infrastructure, breakdowns of
                    networks, failures on the part of engaged third parties, or
                    suppliers, strike actions and business closures.
                  </p>
                  <p>
                    8.3 In the event of force majeure the obligation on the part
                    of UK TV ROUTER of fulfilment of the agreement will lapse.
                    In that event UK TV ROUTER will not owe any compensation. UK
                    TV ROUTER will be exclusively obliged for a consumer-user to
                    provide refund of the subscription fee pro rata the duration
                    of the breakdown, unless this cannot be required of it in
                    all reasonableness, with due regard to the duration and the
                    circumstances.
                  </p>
                  <p>
                    8.4 If the force majeure lasts for more than four weeks,
                    both parties will have the right to terminate the agreement
                    wholly or in part, insofar as this is justified by the force
                    majeure. The user will not have the right to any
                    compensation in the event of force majeure on the part of UK
                    TV ROUTER.
                  </p>
                  <p></p>
                  <h3>Article 9 Default and termination</h3>
                  <p>
                    9.1 In the event of default on the part of the user, or in
                    one of the cases below under article 9.4 all UK TV ROUTER’s
                    claims against the user, on whatsoever basis, will be
                    immediately due and payable in full, and UK TV ROUTER will
                    be entitled to suspend the provision of the service. The
                    above will not affect any of UK TV ROUTER’s other rights on
                    the basis of the law or the agreement.
                  </p>
                  <p>
                    9.2 UK TV ROUTER as well as the user can terminate the
                    agreement if the other party does not properly fulfil one or
                    more of the other party’s obligations on the basis of the
                    agreement, insofar as this failure justifies the
                    termination. If fulfilment is still possible the agreement
                    can only be terminated after the other party has been given
                    demand in writing and the failure is not eliminated within
                    the period stated thereby.
                  </p>
                  <p>
                    9.4 In the event of (provisional) moratorium, bankruptcy,
                    cessation, or liquidation of (the business of) the user, or
                    if the user is or has become legally incompetent, is placed
                    under a regime of administration, or in any other manner
                    whatsoever loses the free disposition over his/her assets,
                    the agreement will be terminated by operation of law.
                  </p>
                  <p></p>
                  <h3>Article 10 Complaints</h3>
                  <p>
                    10.1 Complaints regarding defects/breakdowns in the service
                    must be reported to UK TV ROUTER electronically as soon as
                    possible after the discovery thereof. The user will be
                    obliged, after noticing any breakdown, to do, and to refrain
                    from, anything that is reasonably possible for the
                    prevention of damage. The user will provide all cooperation
                    necessary for the inspection and the solution of the
                    compliant, inter alia by providing UK TV ROUTER with the
                    opportunity to conduct an inspection of the circumstances of
                    the use of the service and the factors related thereto.
                  </p>
                  <p>
                    10.2 If the user makes a complaint regarding defects in the
                    service correctly, justifiably and in a timely manner, UK TV
                    ROUTER will make endeavours to resolve the defect as soon as
                    possible. UK TV ROUTER will state, if possible, when the
                    defect has presumably been solved. If the user does not make
                    a compliant in a timely manner the user will loss his/her
                    rights.
                  </p>
                  <p>
                    10.3 The costs of the breakdown inspection and the costs for
                    eliminating the breakdown will be at UK TV ROUTER’s expense.
                    If the breakdown is the result of an attributable failure on
                    the part of the user, UK TV ROUTER can charge on these costs
                    to the user.
                  </p>
                  <p>
                    10.4 UK TV ROUTER will not be obliged to any payment (of
                    compensation) if UK TV ROUTER has fulfilled the obligation
                    included in this article.
                  </p>
                  <p></p>
                  <h3>Article 11 Liability</h3>
                  <p>
                    11.1 UK TV ROUTER will not be liable for any consequential
                    loss, which the user or a third party might suffer due to
                    the use of the service. This also includes, but is not
                    limited to: trading loss, lost profits, loss or theft of
                    data, intangible loss, unauthorised access to confidential
                    information. Consequential loss is also loss or damage
                    related to, or resulting from, interruptions or blocking of
                    the access to UK TV ROUTER’s network, the internet of UK TV
                    ROUTER or third parties, a defect in the security of the
                    information stored by the user, acts on the part of other
                    users, or other internet users, and lastly, changes in login
                    procedures and account data.
                  </p>
                  <p>
                    11.2 In any event the contractual and legal liability on the
                    part of UK TV ROUTER towards the user, non-consumer, will be
                    at all times limited to the payments excluding VAT actually
                    made by the user to UK TV ROUTER during the ongoing contract
                    year, which will be with a maximum of EURO 100 per incident,
                    whereby a related series of incidents will apply as one
                    single incident.
                  </p>
                  <p>
                    11.3 UK TV ROUTER will only be liable towards the user per
                    incident to a maximum of the (annual) subscription owed by
                    the user to UK TV ROUTER. UK TV ROUTER will only be liable
                    towards one and the same user per year to a maximum of twice
                    the (annual) subscription owed by the user to UK TV ROUTER.
                  </p>
                  <p>
                    11.4 If several users are affected at the same time by
                    damage caused by UK TV ROUTER, UK TV ROUTER will be liable
                    in that case towards all users jointly up to a maximum
                    amount of EURO 500.
                  </p>
                  <p>
                    11.5 Damage must be reported as soon as possible to UK TV
                    ROUTER in writing or electronically, but no later than
                    within four weeks after the arising thereof. Damage that has
                    not been brought to the attention of UK TV ROUTER within
                    this period will not be eligible for compensation.
                  </p>
                  <p></p>
                  <h3>Article 12 Indemnification by the user</h3>
                  <p>
                    The user indemnifies UK TV ROUTER against claims by third
                    parties for compensation of damage, which these third
                    parties could (attempt to) recover from UK TV ROUTER in any
                    manner whatsoever, due to the fact that use has been made of
                    UK TV ROUTER’s service, or as the case may be due to the
                    user not having fulfilled the user’s obligations towards UK
                    TV ROUTER.
                  </p>
                  <p></p>
                  <h3>Article 13 Intellectual property</h3>
                  <p>
                    13.1 The user exclusively acquires a non-exclusive and
                    non-transferable right to use UK TV ROUTER’s service
                    together with the documentation and software forming part
                    thereof. All intellectual property rights thereto are vested
                    in UK TV ROUTER and/or its suppliers.
                  </p>
                  <p>
                    13.2 Copying the documentation for normal personal use and
                    backup purposes is permitted. When making copies all
                    references determining the ownership and origin must remain
                    unaltered.
                  </p>
                  <p></p>
                  <h3>Article 14 Contract takeover</h3>
                  <p>
                    14.1 The user will not be entitled to transfer rights and
                    obligations under the agreement to a third party without
                    permission in writing from UK TV ROUTER. Conditions can be
                    attached to this permission.
                  </p>
                  <p>
                    14.2 UK TV ROUTER will be entitled to transfer its rights
                    and obligations under the agreement to a third party, if
                    this takes place in the context of the transfer of (a part
                    of) the enterprise run by UK TV ROUTER.
                  </p>
                  <p></p>
                  <h3>Article 15 Time limit</h3>
                  <p>
                    All causes of action ensuing for the user, who is not a
                    consumer, on the basis of this agreement will lapse by means
                    of the expiry of two years, to be calculated from the day
                    following the day on which the claim has become due and
                    payable.
                  </p>
                  <p></p>
                  <h3>Article 16 Applicable law and disputes</h3>
                  <p>
                    16.1 The law of the Spain applies to these Terms and
                    Conditions as well as to the agreement.
                  </p>
                  <p>
                    16.2 The applicability of the Vienna Sales Convention 1980
                    is expressly excluded.
                  </p>
                  <p>
                    16.3 If the user and UK TV ROUTER are in a dispute in the
                    course of which it is not feasible to reach a mutually
                    satisfactory solution the dispute will be made pending
                    before the court with competent jurisdiction in Alicante.
                  </p>
                  <p></p>
                  <h3>Contact</h3>
                  <p>Business name:</p>
                  <p>UK TV ROUTER</p>
                  <p>Part of Base Satellite</p>
                  <p>Business address :</p>
                  <p>Miraflor 22A 03700 Denia Alicante Spain</p>
                  <p>
                    Phone: +34962800807 Note: We don’t offer support by
                    telephone. If you need support, please contact our support
                    team via support@uktvrouter.com
                  </p>
                  <p>
                    Email address:{" "}
                    <a href="emailto:support@uktvrouter.com">
                      support@uktvrouter.com
                    </a>
                  </p>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  name="termsAgree"
                  id="termsAgree"
                  onClick={this.handleAgree}
                >
                  <i
                    className="fa fa-check"
                    checked={this.state.termsAgree}
                  ></i>{" "}
                  I Agree
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.showFooter()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  console.log("ME TOO");
  console.dir(state);
  return {
    state: state,
    purchasedItem: state.auth.purchaseItem,
    paymentIntent: state.auth.paymentIntent,
    paymentIntentProcess: state.auth.paymentIntentProcess,
    redirectURL: state.auth.redirectURL,
    defaults: state.defaults[0],
  };
}

export default connect(mapStateToProps, actions)(withRouter(Purchase));
