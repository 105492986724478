import React, {Component} from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../actions';
import {isMobile} from 'react-device-detect';

class Header extends Component  {

  constructor(props) {
    super(props);
    this.logoutUser = this.logoutUser.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  logoutUser() {
    this.props.logout(this.props.history);
  }

  renderContent() {
    if (sessionStorage.getItem('userId')) {
      return <a href="#logout" onClick={this.logoutUser}>
        LOGOUT
      </a>;
    } else {
      return <a href={() => false} onClick={() => this.handleClick('login')}>
        LOGIN
      </a>;
    }
  }

  handleClick(value) {
    //console.log(value);
    if (value === '#about') {
      if (isMobile) {
        //console.log("We are navigating");
        this.props.history.push('/#about');
        setTimeout(function() {
          window.location.reload();
          this.props.history.push('/#about');
        }, 100);
      } else {
        this.props.history.push('/#about');
        window.location.reload();
      }
    } else if (value === '#pricing') {
      if (isMobile) {
        //console.log("We are navigating");
        this.props.history.push('/#pricing');
        setTimeout(function() {
          window.location.reload();
          this.props.history.push('/#pricing');
        }, 100);
      } else {
        this.props.history.push('/#pricing');
        window.location.reload();
      }
    } else if (value === '#testimonials') {
      if (isMobile) {
        //console.log("We are navigating");
        this.props.history.push('/#testimonials');
        setTimeout(function() {
          window.location.reload();
          this.props.history.push('/#testimonials');
        }, 100);
      } else {
        this.props.history.push('/#testimonials');
        window.location.reload();
      }
    } else if (value === 'support') {
      if (isMobile) {
        this.props.history.push('/support#top');
        window.location.reload();
      } else {
        this.props.history.push('/support#top');
      }
    } else if (value === 'faq') {
      if (isMobile) {
        this.props.history.push('/faq#top');
        window.location.reload();
      } else {
        this.props.history.push('/faq#top');
      }
    } else if (value === 'contact') {
      if (isMobile) {
        this.props.history.push('/contact');
        window.location.reload();
      } else {
        this.props.history.push('/contact');
      }

    } else if (value === '#home') {
      if (isMobile) {
        //console.log("We are navigating");
        this.props.history.push('/#home');
        setTimeout(function() {
          window.location.reload();
          this.props.history.push('/#home');
        }, 100);
      } else {
        this.props.history.push('/#home');
        window.location.reload();
      }
    } else if (value === 'admin') {
      if (isMobile) {
        this.props.history.push('/admin');
        window.location.reload();
      } else {
        this.props.history.push('/admin');
      }

    } else if (value === 'account') {
      if (isMobile) {
        this.props.history.push('/account');
        window.location.reload();
      } else {
        this.props.history.push('/account');
      }

    } else if (value === 'login') {
      if (isMobile) {
        this.props.history.push('/login');
        window.location.reload();
      } else {
        this.props.history.push('/login');
      }

    }
  }

  renderRegisterContent() {
    if (sessionStorage.getItem('userId')) {
      if (sessionStorage.getItem('isAdmin') && (sessionStorage.getItem('isAdmin') === "true")) {
          return <li>
            <a href={() => false} onClick={() => this.handleClick('admin')}>
                ADMIN
            </a>
          </li>
      } else {
          return <li>
            <a href={() => false} onClick={() => this.handleClick('account')}>
                MY ACCOUNT
            </a>
          </li>
      }
    }
  }


  render() {
    return (
      <div id="header" className="navbar-toggleable-md sticky clearfix">
				<header id="topNav">
					<div className="container">
						<button className="btn btn-mobile" data-toggle="collapse" data-target=".nav-main-collapse">
							<i className="fa fa-bars"></i>
						</button>
            <a href={() => false} className="logo float-left scrollTo" onClick={() => this.handleClick('#home')}>
              <img src="assets/images/uktvrouter/logo-small.png" alt="" />
						</a>
            {/* <Link
              to={this.props.auth ? '/surveys' : '/'}
              className="logo float-left scrollTo">
                <img src="assets/images/uktvrouter/logo-small.png" alt="UK TV Router Logo" />
            </Link> */}
						<div className="navbar-collapse collapse float-right nav-main-collapse submenu-dark">
							<nav className="nav-main">
								<ul id="topMain" className="nav nav-pills nav-main">
									<li>
                    <a href={() => false} onClick={() => this.handleClick('#home')}>
											HOME
										</a>
									</li>
									<li>
										<a href={() => false} onClick={() => this.handleClick('#about')}>
											ABOUT
										</a>
									</li>
									<li>
										<a href={() => false} onClick={() => this.handleClick('#pricing')}>
											PLANS &amp; PRICING
										</a>
									</li>
									<li>
										<a href={() => false} onClick={() => this.handleClick('#testimonials')}>
											TESTIMONIALS
										</a>
									</li>
                  <li>
                    <a href={() => false} onClick={() => this.handleClick('faq')}>
                      FAQ's
                    </a>
									</li>
									<li>
                    <a href={() => false} onClick={() => this.handleClick('support')}>
                      SUPPORT
                    </a>
									</li>
									<li>
                    <a href={() => false} onClick={() => this.handleClick('contact')}>
                      CONTACT
                    </a>
									</li>
                  {this.renderRegisterContent()}
                  <li>
                    {this.renderContent()}
									</li>
								</ul>
							</nav>
						</div>
					</div>
				</header>
        {/* <nav>
          <div className="row">
            <div className="col-sm-12 nav-wrapper">
              <Link
              to={this.props.auth ? '/surveys' : '/'}
              className="left brand-logo"
              >
              Emaily</Link>
              <ul id="nav-mobile" className="right">
                {this.renderContent()}
              </ul>
            </div>
          </div>
        </nav> */}
			</div>
    );
  }
}

function mapStateToProps({auth}) {
  return { auth };
}
export default connect(mapStateToProps,actions)(withRouter(Header));
