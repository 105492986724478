import React, { Component } from "react";
import { isMobile } from "react-device-detect";
// import {Link} from 'react-router-dom';
import ReactWhatsapp from "react-whatsapp";

class Revolution extends Component {
  componentDidMount() {
    //console.log("Called Here Too ******");
    const { hash } = window.location;
    if (hash !== "") {
      setTimeout(
        () => {
          const id = hash.replace("#", "");
          //console.log("Revolution ID is " + id );
          if (id === "home") {
            if (this.refs.home) {
              this.refs.home.scrollIntoView({ behavior: "smooth" });
            }
          }
        },
        isMobile ? 1000 : 1000
      );
    }
  }

  render() {
    return (
      <section
        id="home"
        ref="home"
        className="fullheight"
        style={{
          backgroundImage: "url('/assets/images/uktvrouter/topBack.jpg')",
          height: "700px",
        }}
      >
        <div className="overlay dark-4"></div>
        <div className="display-table">
          <div className="display-table-cell vertical-align-middle">
            <div className="container">
              <div className="slider-featured-text text-center">
                <h1 className="text-white wow fadeInUp" data-wow-delay="0.4s">
                  Welcome to UK TV Router
                </h1>
                <h2
                  className="fw-300 text-white wow fadeInUp"
                  data-wow-delay="0.8s"
                >
                  Access live and catch up UK TV services from anywhere
                </h2>
                <a
                  className="btn btn-primary btn-lg wow fadeInUp lightbox"
                  data-wow-delay="1s"
                  href="https://eu2.contabostorage.com/0719335084e9416c88f52dc33b7e69a4:images/UKTVRouterExplainerVideoHowItWorks.mp4"
                  data-plugin-options='{"type":"iframe"}'
                >
                  WATCH THE EXPLAINER VIDEO
                </a>
                <br />
                <br />
                <br />
                <ReactWhatsapp
                  className="btn btn-primary btn-lg wow fadeInUp"
                  data-wow-delay="1.5s"
                  number="+34667427271"
                  message="Hi, I have a question about UKTVRouter.."
                >
                  CONTACT US ON WHATSAPP!
                </ReactWhatsapp>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Revolution;
