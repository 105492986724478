import {
  COLLECT_ME,
  FAILED_COLLECT_ME,
  UPDATE_USER,
  UPDATE_USER_FAILED,
  COLLECT_CARDS,
  FAILED_COLLECT_CARDS,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_FAILED,
  DELETE_CARD,
  PURCHASE_ADDITIONAL_ITEM,
  PURCHASE_ADDITIONAL_ITEM_FAILED,
  PURCHASE_ADDITIONAL_ITEM_URL,
  FAILED_ADD_CARD,
  PAYMENT_URL,
  CHECK_SUBSCRIPTION,
  CHECK_SESSION,
} from "../actions/types";

export default function(
  state = {
    user: null,
    product: null,
    customer: null,
    cancelSubscription: false,
    deletedCard: false,
    purchasedItem: false,
    purchaseError: null,
    redirectURL: null,
    paymentIntentId: null,
    failedCardAddError: null,
    payment_url: null,
    subscription: false,
  },
  action
) {
  switch (action.type) {
    case CHECK_SESSION:
      return {
        ...state,
        user: action.payload.user,
        product: action.payload.prod,
        cancelSubscription: false,
        deletedCard: false,
        purchasedItem: false,
        purchaseError: null,
        redirectURL: null,
        paymentIntentId: null,
        failedCardAddError: null,
        payment_url: null,
        subscription: false,
        session: action.payload.completedSetup,
      };
    case CHECK_SUBSCRIPTION:
      return {
        ...state,
        user: action.payload.user,
        product: action.payload.prod,
        cancelSubscription: false,
        deletedCard: false,
        purchasedItem: false,
        purchaseError: null,
        redirectURL: null,
        paymentIntentId: null,
        failedCardAddError: null,
        payment_url: null,
        subscription: action.payload.completedSetup,
      };
    case PAYMENT_URL:
      return {
        ...state,
        user: action.payload.user,
        product: action.payload.prod,
        cancelSubscription: false,
        deletedCard: false,
        purchasedItem: false,
        purchaseError: null,
        redirectURL: null,
        paymentIntentId: null,
        failedCardAddError: null,
        payment_url: action.payload.url,
        subscription: false,
      };
    case COLLECT_ME:
      return {
        ...state,
        user: action.payload.user,
        product: action.payload.prod,
        cancelSubscription: false,
        deletedCard: false,
        purchasedItem: false,
        purchaseError: null,
        redirectURL: null,
        paymentIntentId: null,
        failedCardAddError: null,
        payment_url: null,
        subscription: false,
      };
    case FAILED_COLLECT_ME:
      return {
        ...state,
        cancelSubscription: false,
        user: null,
        deletedCard: false,
        purchasedItem: false,
        purchaseError: null,
        redirectURL: null,
        paymentIntentId: null,
        failedCardAddError: null,
        payment_url: null,
        subscription: false,
      };
    case COLLECT_CARDS:
      return {
        ...state,
        cancelSubscription: false,
        customer: action.payload.customer,
        deletedCard: false,
        purchasedItem: false,
        purchaseError: null,
        redirectURL: null,
        paymentIntentId: null,
        failedCardAddError: null,
        payment_url: null,
        subscription: false,
      };
    case FAILED_COLLECT_CARDS:
      return {
        ...state,
        cancelSubscription: false,
        deletedCard: false,
        purchasedItem: false,
        purchaseError: null,
        redirectURL: null,
        paymentIntentId: null,
        failedCardAddError: null,
        payment_url: null,
        subscription: false,
      };
    case FAILED_ADD_CARD:
      return {
        ...state,
        cancelSubscription: false,
        deletedCard: false,
        purchasedItem: false,
        purchaseError: null,
        redirectURL: null,
        paymentIntentId: null,
        failedCardAddError: action.payload.message,
        payment_url: null,
        subscription: false,
      };
    case UPDATE_USER:
      return {
        ...state,
        cancelSubscription: false,
        user: action.payload.user,
        product: action.payload.prod,
        deletedCard: false,
        purchasedItem: false,
        purchaseError: null,
        redirectURL: null,
        paymentIntentId: null,
        failedCardAddError: null,
        payment_url: null,
        subscription: false,
      };
    case UPDATE_USER_FAILED:
      return {
        ...state,
        cancelSubscription: false,
        deletedCard: false,
        purchasedItem: false,
        purchaseError: null,
        redirectURL: null,
        paymentIntentId: null,
        failedCardAddError: null,
        payment_url: null,
        subscription: false,
      };
    case CANCEL_SUBSCRIPTION:
      return {
        ...state,
        cancelSubscription: true,
        deletedCard: false,
        purchasedItem: false,
        purchaseError: null,
        redirectURL: null,
        paymentIntentId: null,
        failedCardAddError: null,
        payment_url: null,
        subscription: false,
      };
    case CANCEL_SUBSCRIPTION_FAILED:
      return {
        ...state,
        cancelSubscription: false,
        deletedCard: false,
        purchasedItem: false,
        purchaseError: null,
        redirectURL: null,
        paymentIntentId: null,
        failedCardAddError: null,
        payment_url: null,
        subscription: false,
      };
    case DELETE_CARD:
      return {
        ...state,
        cancelSubscription: false,
        deletedCard: action.payload.result,
        purchasedItem: false,
        purchaseError: null,
        redirectURL: null,
        paymentIntentId: null,
        failedCardAddError: null,
        payment_url: null,
        subscription: false,
      };
    case PURCHASE_ADDITIONAL_ITEM:
      return {
        ...state,
        cancelSubscription: false,
        deletedCard: false,
        purchasedItem: true,
        purchaseError: null,
        redirectURL: null,
        paymentIntentId: null,
        failedCardAddError: null,
        payment_url: null,
        subscription: false,
      };
    case PURCHASE_ADDITIONAL_ITEM_FAILED:
      console.log("Whats the ERRRROR");
      console.log(action.payload);
      return {
        ...state,
        cancelSubscription: false,
        deletedCard: false,
        purchasedItem: false,
        purchaseError: action.payload.error,
        redirectURL: null,
        paymentIntentId: null,
        failedCardAddError: null,
        payment_url: null,
        subscription: false,
      };
    case PURCHASE_ADDITIONAL_ITEM_URL:
      return {
        ...state,
        cancelSubscription: false,
        deletedCard: false,
        purchasedItem: false,
        purchaseError: action.payload.error,
        paymentIntentError: null,
        redirectURL: action.payload.url,
        paymentIntentId: action.payload.paymentIntentId,
        failedCardAddError: null,
        payment_url: null,
        subscription: false,
      };
    default:
      return state;
  }
}
