import React, {Component} from 'react';
import {reduxForm, Field} from 'redux-form';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { Link, withRouter } from 'react-router-dom';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { ToastContainer, toast } from 'react-toastify';

class Register extends Component  {

  notify = () => {
    toast.error("Oops! Please select a country and region and try again", {
      position: toast.POSITION.TOP_CENTER
    });
  }

  constructor (props) {
    super(props);
    this.state = { country: '', region: '' };
    this.selectRegion = this.selectRegion.bind(this);
    this.selectCountry = this.selectCountry.bind(this);
  }

  selectCountry (val) {
    this.setState({ country: val });
  }

  selectRegion (val) {
    this.setState({ region: val });
  }

  handleFormSubmit(formProps) {
    //console.log(formProps);
    if (this.state.country === '' || this.state.region === '') {
      this.notify();
      return;
    }
    formProps.country = this.state.country;
    formProps.region = this.state.region;
    this.props.signupUser(formProps, this.props.history);
    //console.log(this.state.form.registrationForm.values);
    //this.boundActionCreators.signupUser(formProps);
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops!</strong> {this.props.errorMessage}
        </div>
      );
    }
  }

  render() {


    const { handleSubmit, submitting, fields: { postcode, street, city, country, region, email, password, passwordMatch, phone, firstName, lastName, agreed, newsletter }} = this.props
    return (
      <section>
      <div className="container">
                <label className="fs-20"><i className="glyphicon glyphicon-user"></i> Create a new account <Link to='/login' className="external"><span className="fs-10">(already registered? Sign in here)</span></Link></label>
                <div className="toggle-content">
                  <form className="m-0 sky-form" onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                    <fieldset>

                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label>First Name *</label>
                          <label className="input mb-10">
                            <i className="ico-append fa fa-user"></i>
                            <Field {...firstName} name="firstName" required="" type="text" component={renderField}/>
                            <b className="tooltip tooltip-bottom-right">Your First Name</b>
                          </label>
                        </div>

                        <div className="col-md-6 col-sm-6">
                          <label htmlFor="register:last_name">Last Name *</label>
                          <label className="input mb-10">
                            <i className="ico-append fa fa-user"></i>
                            <Field {...lastName} name="lastName" required="" type="text" component={renderField}/>
                            <b className="tooltip tooltip-bottom-right">Your Last Name</b>
                          </label>
                        </div>

                      </div>

                      <div className="row">

                        <div className="col-md-6 col-sm-6">
                          <label htmlFor="register:email">Email *</label>
                          <label className="input mb-10">
                            <i className="ico-append fa fa-envelope"></i>
                            <Field {...email} name="email" required="" type="text" component={renderField}/>
                            <b className="tooltip tooltip-bottom-right">Your Email</b>
                          </label>
                        </div>

                        <div className="col-md-6 col-sm-6">
                          <label htmlFor="register:phone">Phone * (Format: xx-xxx-xxxxxxx e.g. 44-781-2345678)</label>
                          <label className="input mb-10">
                            <i className="ico-append fa fa-phone"></i>
                            <Field {...phone} name="phone" type="text" required="" component={renderField} pattern="^\d{2}-\d{3}-\d{7}$"/>
                            <b className="tooltip tooltip-bottom-right">Your Phone (optional)</b>
                          </label>
                        </div>

                      </div>

                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label>Street Name *</label>
                          <label className="input mb-10">
                            <i className="ico-append fa fa-map-signs"></i>
                          <Field {...street} name="street" required="" type="text" component={renderField}/>
                        <b className="tooltip tooltip-bottom-right">Street Name</b>
                          </label>
                        </div>

                        <div className="col-md-6 col-sm-6">
                          <label htmlFor="register:city">City *</label>
                          <label className="input mb-10">
                            <i className="ico-append fa fa-map-pin"></i>
                          <Field {...city} name="city" required="" type="text" component={renderField}/>
                        <b className="tooltip tooltip-bottom-right">City</b>
                          </label>
                        </div>

                      </div>

                      <div className="row">

                        <div className="col-md-6 col-sm-6">
                          <label htmlFor="register:country">Country *</label>
                          <label className="input mb-10">
                            <Field {...country} name="country" onChangeAction={(val) => this.selectCountry(val)} valueField={this.state.country} country={this.state.country} required="" component={renderCountry}/>
                            <b className="tooltip tooltip-bottom-right">Your Country</b>
                          </label>
                        </div>

                        <div className="col-md-3 col-sm-3">
                          <label htmlFor="register:region">Region *</label>
                          <label className="input mb-10">
                            <Field {...region} name="region" onChangeAction={(val) => this.selectRegion(val)} valueField={this.state.region} country={this.state.country} region={this.state.region} required="" component={renderRegion}/>
                            <b className="tooltip tooltip-bottom-right">Your Region</b>
                          </label>
                        </div>

                        <div className="col-md-3 col-sm-3">
                          <label htmlFor="register:postcode">Postal Code *</label>
                          <label className="input mb-10">
                            <i className="ico-append fa fa-location-arrow"></i>
                          <Field {...postcode} name="postcode" type="text" required="" component={renderField}/>
                        <b className="tooltip tooltip-bottom-right">Your Postal Code</b>
                          </label>
                        </div>
                        <ToastContainer autoClose={4000}/>
                      </div>

                      <div className="row">

                        <div className="col-md-6 col-sm-6">
                          <label htmlFor="register:pass1">Password *</label>
                          <label className="input mb-10">
                            <i className="ico-append fa fa-lock"></i>
                            <Field {...password} name="password" required="" type="password" component={renderPasswordField} className="err"/>
                            <b className="tooltip tooltip-bottom-right">Min. 6 characters</b>
                          </label>
                        </div>

                        <div className="col-md-6 col-sm-6">
                          <label htmlFor="register:pass2">Password Again *</label>
                          <label className="input mb-10">
                            <i className="ico-append fa fa-lock"></i>
                            <Field {...passwordMatch} name="passwordMatch" required="" type="password" component={renderPasswordField} className="err"/>
                            <b className="tooltip tooltip-bottom-right">Type the password again</b>
                          </label>
                        </div>

                      </div>
                      <hr />

                      <Field {...agreed} name="agreed" type="checkbox" component={renderAgree}/>
                      <Field {...newsletter} name="newsletter" type="checkbox" value="false" component={renderTerms}/>

                    </fieldset>
                    {this.renderAlert()}
                    <div className="row">
                      <div className="col-md-12">
                        <button type="submit" className="btn btn-primary" disabled={submitting}><i className="fa fa-check"></i> REGISTER</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
  			</section>
    );
  }
};

const renderAgree = ({
  input,
  label,
  type,
  className,
  pattern,
  meta: { touched, error, warning }
}) => (
  <div>
    <label className="checkbox m-0">
      <input {...input} placeholder={label} type={type} className={className} pattern={pattern}/>
      <i></i>I agree to the <a href="#terms" data-toggle="modal" data-target="#termsModal">Terms of Service</a>
      {touched &&
        ((error && <span style={{color:'red'}}><br/>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </label>
  </div>
)

const renderTerms = ({
  input,
  label,
  type,
  className,
  pattern,
  meta: { touched, error, warning }
}) => (
  <div>
    <label className="checkbox m-0">
      <input {...input} placeholder={label} type={type} className={className} pattern={pattern}/>
      <i></i>I want to receive news and  special offers
      {touched &&
        ((error && <span style={{color:'red'}}>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </label>
  </div>
)

const renderField = ({
  input,
  label,
  type,
  className,
  pattern,
  meta: { touched, error, warning }
}) => (
  <div>
      <input {...input} placeholder={label} type={type} className={className} pattern={pattern}/>
      {touched &&
        ((error && <span style={{color:'red'}}>{error}</span>) ||
          (warning && <span>{warning}</span>))}
  </div>
)

const renderPasswordField = ({
  input,
  label,
  type,
  className,
  pattern,
  meta: { touched, error, warning }
}) => (
  <div>
      <input {...input} placeholder={label} type={type} className={className} pattern={pattern} autoComplete="off"/>
      {touched &&
        ((error && <span style={{color:'red'}}>{error}</span>) ||
          (warning && <span>{warning}</span>))}
  </div>
)

const renderCountry = ({
  input,
  label,
  type,
  className,
  pattern,
  valueField,
  onChangeAction,
  meta: { touched, error, warning }
}) => (
  <div>
      <CountryDropdown className={className} value={valueField} onChange={onChangeAction}/>
      {touched &&
        ((error && <span style={{color:'red'}}>{error}</span>) ||
          (warning && <span>{warning}</span>))}
  </div>
)

const renderRegion = ({
  input,
  label,
  type,
  className,
  pattern,
  country,
  valueField,
  onChangeAction,
  meta: { touched, error, warning }
}) => (
  <div>
      <RegionDropdown className={className} country={country} value={valueField} onChange={onChangeAction} disableWhenEmpty={true} blankOptionLabel="Select Country First"/>
      {touched &&
        ((error && <span style={{color:'red'}}>{error}</span>) ||
          (warning && <span>{warning}</span>))}
  </div>
)

function validate(values) {
  const errors = {};

  //console.log(values);

  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }

  if (!values.password) {
    errors.password = 'Password is required'
  } else if (values.password.length < 6) {
    errors.password = 'Must be 6 characters or more'
  } else if (!values.passwordMatch) {
    errors.passwordMatch = 'Confirm password is required'
  } else if (values.password !== values.passwordMatch) {
    errors.passwordMatch = 'Passwords do not match'
  }

  if (!values.firstName) {
    errors.firstName = 'First name is required'
  } else if (values.firstName.length > 15) {
    errors.firstName = 'Must be 15 characters or less'
  }

  if (!values.lastName) {
    errors.lastName = 'Last name is required'
  } else if (values.lastName.length > 15) {
    errors.lastName = 'Must be 15 characters or less'
  }

  if (!values.street) {
    errors.street = 'Street name is required'
  } else if (values.street.length < 5) {
    errors.street = 'Must be 5 characters or more'
  }

  if (!values.city) {
    errors.city = 'City name is required'
  } else if (values.city.length < 3) {
    errors.city = 'Must be 3 characters or more'
  }

  if (!values.postcode) {
    errors.postcode = 'Postal Code is required'
  } else if (values.postcode.length < 3) {
    errors.postcode = 'Must be 3 characters or more'
  }

  // if (!values.region) {
  //   errors.region = 'Region required'
  // }
  //
  // if (!values.country) {
  //   errors.country = 'Your Country is required'
  // }

  if (!values.phone) {
    errors.phone = 'A phone number is required'
  }

  if (values.agreed !== true) {
    errors.agreed = 'You must agree to our terms and conditions'
  }

  return errors;
}


const mapStateToProps = (state) => {
  //console.log(state);
  return {
    formValues : state.form.registerForm.values,
    errorMessage : state.auth.errorMessage
  };
};


Register = connect(
    mapStateToProps,
    actions
)(withRouter(Register));

export default reduxForm({
  validate,
  form: 'registerForm',
  fields: ['street', 'city', 'region', 'country', 'postcode', 'email', 'password', 'passwordMatch', 'phone', 'firstName', 'lastName', 'agreed', 'newsletter']
})(Register);
