import {
  COLLECTED_USERS,
  FAILED_COLLECTED_USERS,
  COLLECTED_MESSAGES,
  FAILED_COLLECTED_MESSAGES,
  SAVED_USER,
  FAILED_SAVED_USER,
  RESET_ROUTERS,
  CREATE_USER,
  FAILED_CREATE_USER,
  RESET_USER_ERROR,
  COLLECT_DISCOUNTS,
  CREATE_DISCOUNT_FAILED,
  DELETE_DISCOUNT_FAILED,
  CREATE_DISCOUNT,
  SEND_EMAIL,
  SEND_EMAIL_FAILED,
  UPDATE_DEFAULTS,
  UPDATE_DEFAULTS_FAILED,
  COLLECTED_HARDWARE_SALES,
  FAILED_COLLECTED_HARDWARE_SALES,
  COLLECTED_SOFTWARE_SALES,
  FAILED_COLLECTED_SOFTWARE_SALES,
  SEND_SALES,
  SEND_SALES_FAILED,
  CREATE_AFFILIATE,
  DELETE_AFFILIATE_FAILED,
  CREATE_AFFILIATE_FAILED,
  COLLECT_AFFILIATES,
} from '../actions/types';

export default function(state = {users:[], messages:[], createdUser:null, createdUserError:null, totalCount:0, deleteAffiliateError:null, affiliates:null, createAffilliateError:null, createdAffiliate:false, deleteDiscountError:null, createDiscountError:null, createdDiscount:false, sendEmailError:null, sendEmailSuccess:false, defaultsUpdated:false,defaultsUpdatedError:null, hardwareSales:null, softwareSales:null, sentSale:null}, action) {
  switch (action.type) {
    case COLLECTED_USERS:
      return { ...state, createdUser:null, createdUserError:null, users: action.payload.users, totalCount:action.payload.totalCount, affiliates:null, savedUser:null, deleteAffiliateError:null, createAffilliateError:null, createdAffiliate:false ,resetRouters:false, deleteDiscountError:null, createDiscountError:null, createdDiscount:false, defaultsUpdated:false,defaultsUpdatedError:null, hardwareSales:null, softwareSales:null, sentSale:null};
    case FAILED_COLLECTED_USERS:
      return { ...state, createdUser:null, createdUserError:null, users: [], savedUser:null ,resetRouters:false, deleteDiscountError:null, affiliates:null, deleteAffiliateError:null, createAffilliateError:null, createdAffiliate:false, createDiscountError:null, createdDiscount:false, defaultsUpdated:false,defaultsUpdatedError:null, hardwareSales:null, softwareSales:null, sentSale:null};
    case COLLECTED_MESSAGES:
      return { ...state, createdUser:null, createdUserError:null, messages: action.payload.messages, totalCount:action.payload.totalCount, affiliates:null, deleteAffiliateError:null, createAffilliateError:null, createdAffiliate:false, savedUser:null ,resetRouters:false, deleteDiscountError:null, createDiscountError:null, createdDiscount:false, defaultsUpdated:false,defaultsUpdatedError:null, hardwareSales:null, softwareSales:null, sentSale:null};
    case FAILED_COLLECTED_MESSAGES:
      return { ...state, createdUser:null, createdUserError:null, messages: [], savedUser:null , resetRouters:false, deleteDiscountError:null, affiliates:null, deleteAffiliateError:null, createAffilliateError:null, createdAffiliate:false, createDiscountError:null, createdDiscount:false, defaultsUpdated:false,defaultsUpdatedError:null, hardwareSales:null, softwareSales:null, sentSale:null};
    case SAVED_USER:
      return { ...state, createdUser:null, createdUserError:null, savedUser:action.payload, resetRouters:false, deleteDiscountError:null, affiliates:null, deleteAffiliateError:null, createAffilliateError:null, createdAffiliate:false, createDiscountError:null, createdDiscount:false, defaultsUpdated:false,defaultsUpdatedError:null, hardwareSales:null, softwareSales:null, sentSale:null};
    case FAILED_SAVED_USER:
      return { ...state, createdUser:null, createdUserError:null, savedUser:null, resetRouters:false, deleteDiscountError:null, affiliates:null, deleteAffiliateError:null, createAffilliateError:null, createdAffiliate:false, createDiscountError:null, createdDiscount:false, defaultsUpdated:false,defaultsUpdatedError:null, hardwareSales:null, softwareSales:null, sentSale:null};
    case CREATE_USER:
      return { ...state, createdUser:action.payload, createdUserError:null, resetRouters:false, deleteDiscountError:null, affiliates:null, deleteAffiliateError:null, createAffilliateError:null, createdAffiliate:false, createDiscountError:null, createdDiscount:false, defaultsUpdated:false,defaultsUpdatedError:null, hardwareSales:null, softwareSales:null, sentSale:null};
    case FAILED_CREATE_USER:
      return { ...state, createdUser:null, createdUserError:action.payload, resetRouters:false, deleteDiscountError:null, affiliates:null, deleteAffiliateError:null, createAffilliateError:null, createdAffiliate:false, createDiscountError:null, createdDiscount:false, defaultsUpdated:false,defaultsUpdatedError:null, hardwareSales:null, softwareSales:null, sentSale:null};
    case CREATE_DISCOUNT_FAILED:
      return { ...state, createdUser:null, createdUserError:null, resetRouters:false, deleteDiscountError:null, affiliates:null, deleteAffiliateError:null, createAffilliateError:null, createdAffiliate:false, createDiscountError:action.payload.error, createdDiscount:false, defaultsUpdated:false,defaultsUpdatedError:null, hardwareSales:null, softwareSales:null, sentSale:null};
    case DELETE_DISCOUNT_FAILED:
      return { ...state, createdUser:null, createdUserError:null, resetRouters:false, createDiscountError:null, affiliates:null, deleteAffiliateError:null, createAffilliateError:null, createdAffiliate:false, deleteDiscountError:action.payload.error, createdDiscount:false, defaultsUpdated:false,defaultsUpdatedError:null, hardwareSales:null, softwareSales:null, sentSale:null};
    case CREATE_AFFILIATE_FAILED:
      return { ...state, createdUser:null, createdUserError:null, resetRouters:false, deleteDiscountError:null, affiliates:null, deleteAffiliateError:null, createAffilliateError:action.payload.error, createdAffiliate:false, createDiscountError:null, createdDiscount:false, defaultsUpdated:false,defaultsUpdatedError:null, hardwareSales:null, softwareSales:null, sentSale:null};
    case DELETE_AFFILIATE_FAILED:
      return { ...state, createdUser:null, createdUserError:null, resetRouters:false, createDiscountError:null, affiliates:null, deleteAffiliateError:action.payload.error, createAffilliateError:null, createdAffiliate:false, deleteDiscountError:null, createdDiscount:false, defaultsUpdated:false,defaultsUpdatedError:null, hardwareSales:null, softwareSales:null, sentSale:null};
    case COLLECT_AFFILIATES:
      return { ...state, createdUser:null, createdUserError:null, resetRouters:false, discounts:null, deleteAffiliateError:null, affiliates:action.payload.affiliates, createAffilliateError:null, createdAffiliate:false, deleteDiscountError:null, createDiscountError:null, createdDiscount:false, defaultsUpdated:false,defaultsUpdatedError:null, hardwareSales:null, softwareSales:null, sentSale:null};
    case CREATE_AFFILIATE:
        return { ...state, createdUser:null, createdUserError:null, resetRouters:false, discounts:null, affiliates:action.payload.affiliates, deleteAffiliateError:null, createAffilliateError:null, createdAffiliate:true, deleteDiscountError:null, createDiscountError:null, createdDiscount:false, defaultsUpdated:false,defaultsUpdatedError:null, hardwareSales:null, softwareSales:null, sentSale:null};
    case RESET_ROUTERS:
      return { ...state, createdUser:null, createdUserError:null, resetRouters:true, deleteDiscountError:null, affiliates:null, deleteAffiliateError:null, createAffilliateError:null, createdAffiliate:false, createDiscountError:null, createdDiscount:false, defaultsUpdated:false,defaultsUpdatedError:null, hardwareSales:null, softwareSales:null, sentSale:null};
    case RESET_USER_ERROR:
      return { ...state, createdUser:null, createdUserError:null, resetRouters:false, deleteDiscountError:null, affiliates:null, deleteAffiliateError:null, createAffilliateError:null, createdAffiliate:false, createDiscountError:null, createdDiscount:false, sendEmailError:null, sendEmailSuccess:false, defaultsUpdated:false,defaultsUpdatedError:null, hardwareSales:null, softwareSales:null, sentSale:null};
    case COLLECT_DISCOUNTS:
      return { ...state, createdUser:null, createdUserError:null, resetRouters:false, discounts:action.payload.discounts, affiliates:null, deleteAffiliateError:null, createAffilliateError:null, createdAffiliate:false, deleteDiscountError:null, createDiscountError:null, createdDiscount:false, defaultsUpdated:false,defaultsUpdatedError:null, hardwareSales:null, softwareSales:null, sentSale:null};
    case CREATE_DISCOUNT:
      return { ...state, createdUser:null, createdUserError:null, resetRouters:false, discounts:action.payload.discounts, affiliates:null, deleteAffiliateError:null, createAffilliateError:null, createdAffiliate:false, deleteDiscountError:null, createDiscountError:null, createdDiscount:true, defaultsUpdated:false,defaultsUpdatedError:null, hardwareSales:null, softwareSales:null, sentSale:null};
    case SEND_EMAIL:
      return { ...state, createdUser:null, createdUserError:null, resetRouters:false, deleteDiscountError:null, affiliates:null, deleteAffiliateError:null, createAffilliateError:null, createdAffiliate:false, createDiscountError:null, createdDiscount:false, sendEmailError:null, sendEmailSuccess:true, defaultsUpdated:false,defaultsUpdatedError:null, hardwareSales:null, softwareSales:null, sentSale:null};
    case SEND_EMAIL_FAILED:
      return { ...state, createdUser:null, createdUserError:null, resetRouters:false, deleteDiscountError:null, affiliates:null, deleteAffiliateError:null, createAffilliateError:null, createdAffiliate:false, createDiscountError:null, createdDiscount:false, sendEmailError:action.payload.error, sendEmailSuccess:false, defaultsUpdated:false,defaultsUpdatedError:null, hardwareSales:null, softwareSales:null, sentSale:null};
    case UPDATE_DEFAULTS:
      return { ...state, createdUser:null, createdUserError:null, resetRouters:false, deleteDiscountError:null, affiliates:null, deleteAffiliateError:null, createAffilliateError:null, createdAffiliate:false, createDiscountError:null, createdDiscount:false, sendEmailError:null, sendEmailSuccess:false, defaultsUpdated:true, defaultsUpdatedError:null, hardwareSales:null, softwareSales:null, sentSale:null};
    case UPDATE_DEFAULTS_FAILED:
      return { ...state, createdUser:null, createdUserError:null, resetRouters:false, deleteDiscountError:null, affiliates:null, deleteAffiliateError:null, createAffilliateError:null, createdAffiliate:false, createDiscountError:null, createdDiscount:false, sendEmailError:null, sendEmailSuccess:false, defaultsUpdated:false,defaultsUpdatedError:action.payload.error, hardwareSales:null, softwareSales:null, sentSale:null};
    case COLLECTED_HARDWARE_SALES:
      return { ...state, createdUser:null, createdUserError:null, resetRouters:false, deleteDiscountError:null, affiliates:null, deleteAffiliateError:null, createAffilliateError:null, createdAffiliate:false, createDiscountError:null, createdDiscount:false, sendEmailError:null, sendEmailSuccess:false, defaultsUpdated:false,defaultsUpdatedError:null, hardwareSales:action.payload.sales, softwareSales:null, sentSale:null};
    case FAILED_COLLECTED_HARDWARE_SALES:
      return { ...state, createdUser:null, createdUserError:null, resetRouters:false, deleteDiscountError:null, affiliates:null, deleteAffiliateError:null, createAffilliateError:null, createdAffiliate:false, createDiscountError:null, createdDiscount:false, sendEmailError:null, sendEmailSuccess:false, defaultsUpdated:false,defaultsUpdatedError:null, hardwareSales:null, softwareSales:null, sentSale:null};
    case COLLECTED_SOFTWARE_SALES:
      return { ...state, createdUser:null, createdUserError:null, resetRouters:false, deleteDiscountError:null, affiliates:null, deleteAffiliateError:null, createAffilliateError:null, createdAffiliate:false, createDiscountError:null, createdDiscount:false, sendEmailError:null, sendEmailSuccess:false, defaultsUpdated:false,defaultsUpdatedError:null, hardwareSales:null, softwareSales:action.payload.sales, sentSale:null};
    case FAILED_COLLECTED_SOFTWARE_SALES:
      return { ...state, createdUser:null, createdUserError:null, resetRouters:false, deleteDiscountError:null, affiliates:null, deleteAffiliateError:null, createAffilliateError:null, createdAffiliate:false, createDiscountError:null, createdDiscount:false, sendEmailError:null, sendEmailSuccess:false, defaultsUpdated:false,defaultsUpdatedError:null, hardwareSales:null, softwareSales:null, sentSale:null};
    case SEND_SALES:
      return { ...state, createdUser:null, createdUserError:null, resetRouters:false, deleteDiscountError:null, affiliates:null, deleteAffiliateError:null, createAffilliateError:null, createdAffiliate:false, createDiscountError:null, createdDiscount:false, sendEmailError:null, sendEmailSuccess:false, defaultsUpdated:false,defaultsUpdatedError:null, hardwareSales:null, softwareSales:null, sentSale:true};
    case SEND_SALES_FAILED:
      return { ...state, createdUser:null, createdUserError:null, resetRouters:false, deleteDiscountError:null, affiliates:null, deleteAffiliateError:null, createAffilliateError:null, createdAffiliate:false, createDiscountError:null, createdDiscount:false, sendEmailError:null, sendEmailSuccess:false, defaultsUpdated:false,defaultsUpdatedError:null, hardwareSales:null, softwareSales:null, sentSale:false};
    default:
      return state;
  }
}
