import React, { Component } from "react";
import * as actions from "../actions";
import { BrowserRouter, Route } from "react-router-dom";
import { connect } from "react-redux";
import { StripeProvider } from "react-stripe-elements";
import Landing from "./Landing";
import Login from "./Login";
import Register from "./Register";
import Support from "./Support";
import Faq from "./Faq";
import ResetPassword from "./ResetPassword";
import Purchase from "./Purchase";
import Admin from "./Admin";
import noAuthentication from "./NoAuthentication";
//import authentication from './IsAuthenticated'
import isAdmin from "./IsAdmin";
import Account from "./Account";
import Contact from "./Contact";
import Processing from "./Processing";
import Success from "./Success";
import SuccessP from "./SuccessP";
import ReactGA from "react-ga4";
ReactGA.initialize("G-Z8F7593HH0");

class App extends Component {
  constructor(props) {
    super(props);
    this.getInitialState = this.getInitialState.bind(this);
    this.setSelectedOption = this.setSelectedOption.bind(this);
  }

  componentDidMount() {
    localStorage.clear();
    this.props.fetchUser();
    this.props.getDefaults();
    let url = window.location.href;
    var pieces = url.split("?aid=");
    console.log(pieces);
    if (pieces.length > 1) {
      this.setState({
        affiliateID: pieces[1],
      });
      this.setSelectedOption(pieces[1]);
    }
  }

  getInitialState() {
    var selectedOption = localStorage.getItem("affiliateID") || null;
    return {
      affiliateID: selectedOption,
    };
  }

  setSelectedOption(option) {
    localStorage.setItem("affiliateID", option);
    this.setState({ affiliateID: option });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.getInitialState() && nextProps && nextProps.defaults) {
      nextProps.defaults.affiliateID = this.getInitialState().affiliateID;
    } else if (
      nextProps &&
      nextProps.defaults &&
      this.state &&
      this.state.affiliateID
    ) {
      nextProps.defaults.affiliateID = this.state.affiliateID;
    }
  }

  render() {
    return (
      <div>
        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
          <BrowserRouter>
            <div id="wrapper">
              <Route exact path="/" component={Landing} />
              <Route exact path="/account" component={Account} />
              <Route exact path="/admin" component={isAdmin(Admin)} />
              <Route exact path="/support" component={Support} />
              <Route exact path="/faq" component={Faq} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/login" component={noAuthentication(Login)} />
              <Route
                exact
                path="/register"
                component={noAuthentication(Register)}
              />
              <Route exact path="/purchase" component={Purchase} />
              <Route exact path="/success" component={Success} />
              <Route exact path="/successP" component={SuccessP} />
              <Route path="/resetPassword/:hash" component={ResetPassword} />
              <Route path="/processing/:pi" component={Processing} />
            </div>
          </BrowserRouter>
        </StripeProvider>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.dir(`My State ${state.defaults[0]}`);
  return {
    defaults: state.defaults[0],
  };
};

App = connect(mapStateToProps, actions)(App);

export default App;
