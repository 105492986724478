import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export default function (ComposedComponent) {
  class AuthenticatedAdmin extends Component {
    componentWillMount() {
      if (!this.props.authenticated || !this.props.admin) {
        this.props.history.push('/signin');
      }
    }

    componentWillUpdate(nextProps) {
      if (!nextProps.authenticated ||  !nextProps.admin) {
        this.props.history.push('/signin');
      }
    }

    PropTypes = {
      router: PropTypes.object,
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    //console.log("HEEEEEEEEERE");
    //console.dir(state);
    return { authenticated: state.auth.authenticated, admin:state.auth.admin };
  }

  return connect(mapStateToProps)(AuthenticatedAdmin);
}
