import React, { Component } from "react";
import Revolution from "./Revolution";
import Callout from "./Callout";
import About from "./About";
import Pricing from "./Pricing";
import Parallax from "./Parallax";
import NowTv from "./NowTv";
import Testimonials from "./Testimonials";
import * as actions from "../actions";
import { connect } from "react-redux";
import Header from "./Header";
import Footer from "./Footer";
import Features from "./NewFeatures";

class Landing extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.state) {
      console.log(`State: ${nextProps.state}`);
    } else {
      //const { defaults } = nextProps.defaults;
      console.log(`This is the state: ${nextProps}`);
    }
  }

  render() {
    console.log("CALLED");
    if (this.props && this.props.defaults) {
      const { defaults } = this.props;
      //console.log("Called");
      return (
        <div className="container-fluid" style={{ padding: "0 0 0 0" }}>
          <Header />
          <Revolution />
          <Features />
          <Callout />
          <About />
          <Parallax />
          <Pricing defaults={defaults} />
          <Testimonials />
          <Footer />
        </div>
      );
    } else {
      return (
        <div className="container-fluid" style={{ padding: "0 0 0 0" }}>
          <Header />
          <Revolution />
          <Features />
          <Callout />
          <About />
          <Parallax />
          <Pricing />
          <Testimonials />
          <Footer />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  //console.log("here");
  console.log(`THE STATE ${state.defaults}`);
  return {
    defaults: state.defaults[0],
  };
};

Landing = connect(mapStateToProps, actions)(Landing);

export default Landing;
