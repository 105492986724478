import {
  FETCH_USER,
  AUTH_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  PURCHASE_ITEM,
  ASK_QUESTIONS,
  ASK_QUESTIONS_FAILED,
  RESET_QUESTION,
  AUTHENTICATED,
  UNAUTHENTICATED,
  SIGNED_UP,
  SIGNED_UP_FAILED,
  SIGNED_UP_URL,
  RESET_CONTACT_US,
  CONTACT_US,
  CONTACT_US_FAILED,
  CHECK_PROMO,
  CHECK_PROMO_FAILED,
  PAYMENT_INTENT,
  PAYMENT_INTENT_FAILED,
  PAYMENT_INTENT_PROCESS
} from '../actions/types';

export default function(state = {askedQuestion:false,askedAnswer:null,authenticated:false,admin:false,promo:null,promoError:null,affiliateID:null}, action) {
  switch (action.type) {
    case AUTHENTICATED:
      return { ...state, authenticated: true, admin:action.payload.admin ,promo:null,promoError:null};
    case UNAUTHENTICATED:
      return { ...state, authenticated: false ,promo:null,promoError:null};
    case FETCH_USER:
    if (action.payload) {
      return { ...state, authenticated: action.payload.authenticated ? action.payload.authenticated : false, admin: action.payload.admin ? action.payload.admin : false,promo:null,promoError:null};
    } else {
      return { ...state, authenticated: false, admin: false,promo:null,promoError:null};
    }
    case SIGNED_UP_URL:
      return { ...state, authenticated: false , promo:null, promoError:null, errorMessage:null, paymentIntentError:null, redirectURL:action.payload};
    case SIGNED_UP:
      if (action.payload) {
        return { ...state, successful: action.payload.successful ? action.payload.successful : false, admin: action.payload.admin ? action.payload.admin : false,promo:null,promoError:null,paymentIntent:null, paymentIntentError:null, redirectURL:null};
      } else {
        return { ...state, successful: false, admin: false,promo:null,promoError:null,paymentIntent:null, paymentIntentError:null, redirectURL:null};
      }
    case SIGNED_UP_FAILED:
    //console.log(action.payload.Error);
      return { ...state, authenticated: false ,promo:null,promoError:null,errorMessage:action.payload ? action.payload : null,paymentIntent:null, paymentIntentError:null, redirectURL:null};
    case AUTH_ERROR:
      if (action.payload !== null) {
        return { ...state, errorMessage: action.payload ,promo:null,promoError:null,paymentIntent:null, paymentIntentError:null};
      } else {
        return { ...state, errorMessage: "An Error has occurred" ,promo:null,promoError:null,paymentIntent:null, paymentIntentError:null};
      }
    case RESET_PASSWORD_SUCCESS:
      return { ...state, resetMessage: "A reset email has been sent" ,promo:null,promoError:null};
    case RESET_PASSWORD_FAIL:
      return { ...state, resetMessage: "A reset error has occurred" ,promo:null,promoError:null};
    case PURCHASE_ITEM:
      //console.log(action.payload);
      return { ...state, purchaseItem:action.payload,promo:null,promoError:null};
    case ASK_QUESTIONS:
      //console.log(action.payload);
      return { ...state, askedQuestion:true, askedAnswer:"Your question has been sent",promo:null,promoError:null};
    case ASK_QUESTIONS_FAILED:
      //console.log(action.payload);
      return { ...state, askedQuestion:false, askedAnswer:"There was an error sending your question, please try again",promo:null,promoError:null};
    case CONTACT_US:
      //console.log(action.payload);
      return { ...state, contactedUs:true, contactedUsAnswer:"Your question has been sent",promo:null,promoError:null};
    case CONTACT_US_FAILED:
      //console.log(action.payload);
      return { ...state, contactedUs:false, contactedUsAnswer:"There was an error sending your question, please try again",promo:null,promoError:null};
    case RESET_QUESTION:
      //console.log(action.payload);
      return { ...state, askedQuestion:false, askedAnswer:null,promo:null,promoError:null};
    case RESET_CONTACT_US:
      //console.log(action.payload);
      return { ...state, contactedUs:false, contactedUsAnswer:null,promo:null,promoError:null};
    case CHECK_PROMO:
      //console.log(action.payload);
      return { ...state, contactedUs:false, contactedUsAnswer:null,promo:action.payload.discount,promoError:null,paymentIntent:null, paymentIntentError:null};
    case CHECK_PROMO_FAILED:
      //console.log(action.payload);
      return { ...state, contactedUs:false, contactedUsAnswer:null, promo:null, promoError:action.payload,paymentIntent:null, paymentIntentError:null};
    case PAYMENT_INTENT:
      //console.log(action.payload);
      return { ...state, contactedUs:false, contactedUsAnswer:null,paymentIntent:action.payload.paymentIntent,paymentIntentError:null,paymentIntentProcess:null,promoError:null,promo:null};
    case PAYMENT_INTENT_PROCESS:
      //console.log(action.payload);
      return { ...state, contactedUs:false, contactedUsAnswer:null,paymentIntent:null,paymentIntentError:null,paymentIntentProcess:action.payload,promoError:null,promo:null};
    case PAYMENT_INTENT_FAILED:
      //console.log(action.payload);
      return { ...state, contactedUs:false, contactedUsAnswer:null, paymentIntent:null, paymentIntentError:action.payload,paymentIntentProcess:null,promoError:null,promo:null};
    default:
      return state;
  }
}
