import React, {Component} from 'react';
import { withRouter } from 'react-router-dom'
import {isMobile} from 'react-device-detect';

class Testimonials extends Component {


  componentDidMount() {
    //console.log("Called Here ******");
    const { hash } = window.location;
    if (hash !== '') {
      setTimeout(() => {
        const id = hash.replace('#', '');
        //console.log("Testimonials ID is " + id );
        if (id === "testimonials") {
			if (this.refs.testimonials) {
          		this.refs.testimonials.scrollIntoView({ behavior: "smooth" });
			}
        }
      }, isMobile ? 1000 : 1000);
    }
  }

  render() {
    return (
  			<section id="testimonials" ref="testimonials" className="alternate">
  				<div className="container">

  					<header className="text-center mb-60">
  						<h2>Testimonials</h2>
  						<p className="lead font-lato">Here is what some of our customers are saying</p>
  						<hr />
  					</header>

  					<div className="row mb-60">
  						<div className="col-md-4">
  							<div className="testimonial testimonial-bordered p-15">
  								<figure className="float-left">
  									<img className="rounded" src="demo_files/images/people/300x300/2-min.jpg" alt="" />
  								</figure>
  								<div className="testimonial-content">
  									<p>I am totally technologically illiterate.  I have found my UK TV router supplied by Paul excellent and very good value.  I would like to Thank Paul for his help and patience when needed.</p>
  									<cite>
                    Samantha
  										<span>Spain.</span>
  									</cite>
  								</div>
  							</div>
  						</div>
  						<div className="col-md-4">
  							<div className="testimonial testimonial-bordered p-15">
  								<figure className="float-left">
  									<img className="rounded" src="assets/images/uktvrouter/person1.jpg" alt="" />
  								</figure>
  								<div className="testimonial-content">
  									<p>UK TV used for 3 years. Devices: Sky + HD Box sky pink card and iPad with Sky go. Great system and totally reliable. Now have all the TV we need!</p>
                    <br/>
  									<cite>
  										John
  										<span>Spain Costa del Sol.</span>
  									</cite>

  								</div>
  							</div>
  						</div>
  						<div className="col-md-4">
  							<div className="testimonial testimonial-bordered p-15">
  								<figure className="float-left">
  									<img className="rounded" src="assets/images/uktvrouter/person4.png" alt="" />
  								</figure>
  								<div className="testimonial-content">
  									<p>I think your products and service are excellent and all queries are always dealt with a very informative, pleasant and customer-oriented attitude.</p>
                    <br/>
  									<cite>
  										Pedro
  										<span>Spain.</span>
  									</cite>
  								</div>
  							</div>
  						</div>
  					</div>

  					<ul className="row clearfix testimonial-dotted list-unstyled">
  						<li className="col-md-4">
  							<div className="testimonial">
  								<figure className="float-left">
  									<img className="rounded" src="assets/images/uktvrouter/person5.png" alt="" />
  								</figure>
  								<div className="testimonial-content">
  									<p>I am making use of the Sky+ HD Box from the Netherlands. I also use the router to watch Sky TV via my laptop via SkyGo.!</p>
  									<cite>
  										Werner
  										<span>Netherlands.</span>
  									</cite>
  								</div>
  							</div>
  						</li>
  						<li className="col-md-4">
  							<div className="testimonial">
  								<figure className="float-left">
  									<img className="rounded" src="assets/images/uktvrouter/person2.jpg" alt="" />
  								</figure>
  								<div className="testimonial-content">
  									<p>UKTV Router provides a stable, no fuss UK proxy service. Up time close to 100% over the last 2 years.!</p>
                    <br/>
  									<cite>
  										Alan
  										<span>Switzerland.</span>
  									</cite>
  								</div>
  							</div>
  						</li>
  						<li className="col-md-4">
  							<div className="testimonial">
  								<figure className="float-left">
  									<img className="rounded" src="demo_files/images/people/300x300/c-min.jpg" alt="" />
  								</figure>
  								<div className="testimonial-content">
  									<p>Great Service, efficient, technically proficient, good product range and very helpful after-sales support!</p>
                    <br/>
  									<cite>
  										Ciara
  										<span>Portugal.</span>
  									</cite>
  								</div>
  							</div>
  						</li>
  						<li className="col-md-4">
  							<div className="testimonial">
  								<figure className="float-left">
  									<img className="rounded" src="assets/images/uktvrouter/person6.png" alt="" />
  								</figure>
  								<div className="testimonial-content">
  									<p>This particular router solves all my problems with individual vpn´s. Having been without decent ITV and BBC reception for over a year, life is now much easier!</p>
  									<cite>
  										Brian
  										<span>Stockholm.</span>
  									</cite>
  								</div>
  							</div>
  						</li>
  						<li className="col-md-4">
  							<div className="testimonial">
  								<figure className="float-left">
  									<img className="rounded" src="demo_files/images/people/300x300/11-min.jpg" alt="" />
  								</figure>
  								<div className="testimonial-content">
  									<p>All up and running thanks, you’re right it was very simple to set up. Thanks Paul!</p>
                    <br/>
                    <br/>
  									<cite>
  										Debbie
  										<span>Italy.</span>
  									</cite>
  								</div>
  							</div>
  						</li>
  						<li className="col-md-4">
  							<div className="testimonial">
  								<figure className="float-left">
  									<img className="rounded" src="assets/images/uktvrouter/person7.png" alt="" />
  								</figure>
  								<div className="testimonial-content">
  									<p>We have been using the router in Florida over the last 10 weeks with 2 UK ROKUs and the reception and picture has been perfect!</p>
                    <br/>
  									<cite>
  										Joe W. 
  										<span>Florida, U.S.A.</span>
  									</cite>
  								</div>
  							</div>
  						</li>
  					</ul>

  				</div>
  			</section>
    );
  }

};

export default withRouter(Testimonials);
