import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "./accountActions";
import { withRouter } from "react-router-dom";
import Menu, { SubMenu, MenuItem } from "rc-menu";
import animate from "css-animation";
import "rc-menu/assets/index.css";
import Moment from "moment";
import momentLocalizer from "react-widgets-moment";
//import { DateTimePicker } from 'react-widgets'
import "react-widgets/dist/css/react-widgets.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { Elements, CardElement, injectStripe } from "react-stripe-elements";
import { ToastContainer, toast } from "react-toastify";
import { Loader } from "react-overlay-loader";
import Header from "./Header";
import Footer from "./Footer";
import Modal from "react-modal";

Modal.setAppElement("#root");

Moment.locale("en");
momentLocalizer();

class _AddCardForm extends React.Component<
  InjectedProps & { fontSize: string }
> {
  componentDidMount() {
    this.props.onRef(this);
  }
  componentWillUnmount() {
    this.props.onRef(undefined);
  }
  clickButton() {
    this.refs.paymentButton.click();
  }
  handleSubmit = (ev) => {
    ev.preventDefault();
    if (this.props.stripe) {
      this.props.stripe.createToken().then((payload) => {
        //console.log('[token]', payload)
        this.props.handleToken(payload);
        //this._element.clear();
      });
    } else {
      //console.log("Stripe.js hasn't loaded yet.");
    }
  };
  render() {
    return (
      <form id="cardForm" onSubmit={this.handleSubmit}>
        <CardElement
          elementRef={(c) => (this._element = c)}
          style={{ base: { fontSize: "16px", color: "black" } }}
        />
        <br />
        <button
          ref="paymentButton"
          className="btn btn-primary btn-lg btn-block fs-15"
        >
          <i className="fa fa-mail-forward"></i> Add Card
        </button>
      </form>
    );
  }
}
const AddCardForm = injectStripe(_AddCardForm);

const animation = {
  enter(node, done) {
    let height;
    return animate(node, "rc-menu-collapse", {
      start() {
        height = node.offsetHeight;
        node.style.height = 0;
      },
      active() {
        node.style.height = `${height}px`;
      },
      end() {
        node.style.height = "";
        done();
      },
    });
  },

  appear() {
    return this.enter.apply(this, arguments);
  },

  leave(node, done) {
    return animate(node, "rc-menu-collapse", {
      start() {
        node.style.height = `${node.offsetHeight}px`;
      },
      active() {
        node.style.height = 0;
      },
      end() {
        node.style.height = "";
        done();
      },
    });
  },
};

class Account extends Component {
  toastId = 1234;
  toastIdSuccess = 3456;

  notify = (success, value) => {
    if (success) {
      if (!toast.isActive(this.toastIdSuccess)) {
        this.toastId = toast.success(value, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } else {
      if (!toast.isActive(this.toastId)) {
        this.toastId = toast.error(value, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  state = {
    user: null,
    product: null,
    showingAddress: false,
    showingCard: false,
    showingUpgrades: false,
    showingInfo: true,
    stripe: null,
    defaults: null,
    showCardsOverlay: false,
    isSubmitting: true,
    annualSelected: false,
    monthlySelected: false,
    biAnnualSelected: false,
    quarterlySelected: false,
    selectedKey: -1,
    secureModalOpen: false,
  };

  componentWillReceiveProps(nextProps) {
    //console.log("Props");
    //console.dir(nextProps);
    if (nextProps.state && nextProps.state.account.payment_url != null) {
      //console.log("HERE");
      //console.log(`URL: ${nextProps.state.account.payment_url}`);
      window.location.replace(nextProps.state.account.payment_url);
      return;
    }

    if (
      nextProps &&
      nextProps.state &&
      nextProps.state.account &&
      nextProps.state.account.redirectURL &&
      nextProps.state.account.paymentIntentId &&
      nextProps.state.account.payment_url == null
    ) {
      console.log("Found URL");
      this.setState(
        {
          redirectURL: nextProps.state.account.redirectURL,
          paymentIntentId: nextProps.state.account.paymentIntentId,
        },
        () => {
          this.setState({ secureModalOpen: true, isSubmitting: false });
        }
      );
      return;
    }

    if (
      nextProps &&
      nextProps.state &&
      nextProps.state.account &&
      nextProps.state.account.failedCardAddError &&
      nextProps.state.account.payment_url == null
    ) {
      this.setState(
        {
          isSubmitting: false,
        },
        () => {
          this.notify(false, nextProps.state.account.failedCardAddError);
        }
      );
      return;
    }

    if (
      nextProps.state &&
      nextProps.state.account.cancelSubscription === true
    ) {
      //console.log("Called");
      setTimeout(() => {
        this.setState(
          {
            showingAddress: false,
            showingCard: false,
            showingUpgrades: false,
            showingInfo: true,
          },
          () => {
            //console.log("Called Again");
            this.props.getMe();
            //this.props.collectCards();
            this.notify(
              true,
              "Congratulations, We have cancelled your subscription"
            );
          }
        );
      }, 3000);
    } else {
      console.log(nextProps);
      if (
        nextProps.state &&
        nextProps.state.account &&
        nextProps.state.account.user &&
        nextProps.state.account.product &&
        nextProps.state.account.payment_url == null
      ) {
        this.setState({
          user: nextProps.state.account.user,
          product: nextProps.state.account.product,
          isSubmitting: false,
        });
      }
      if (
        nextProps.state &&
        nextProps.state.account &&
        nextProps.state.account.customer &&
        nextProps.state.account.payment_url == null
      ) {
        this.setState({
          customer: nextProps.state.account.customer,
          isSubmitting: false,
        });
      }
      if (
        nextProps.state &&
        nextProps.state.defaults &&
        nextProps.state.defaults.length > 0 &&
        nextProps.state.account.payment_url == null
      ) {
        this.setState({
          defaults: nextProps.state.defaults[0],
        });
      }

      if (
        nextProps.state &&
        nextProps.state.account.deletedCard === true &&
        nextProps.state.account.payment_url == null
      ) {
        //this.props.collectCards();
      }
      if (
        nextProps.state &&
        nextProps.state.account.purchasedItem === true &&
        nextProps.state.account.payment_url == null
      ) {
        this.props.getMe();
        //this.props.collectCards();
        this.setState({
          showingAddress: false,
          showingCard: false,
          showingUpgrades: false,
          showingInfo: true,
          isSubmitting: false,
        });
        this.notify(
          true,
          "Congratulations, We have added the product to your account"
        );
      } else if (
        nextProps.state &&
        nextProps.state.account.purchasedItem === false &&
        nextProps.state.account.purchaseError !== null &&
        nextProps.state.account.payment_url == null
      ) {
        this.props.getMe();
        //this.props.collectCards();
        setTimeout(() => {
          this.setState(
            {
              showingAddress: false,
              showingCard: false,
              showingUpgrades: false,
              showingInfo: true,
              isSubmitting: false,
            },
            () => {
              if (nextProps.state.account.purchaseError) {
                this.notify(false, nextProps.state.account.purchaseError);
              } else {
                this.notify(
                  false,
                  "There was an error purchasing the product, please try again"
                );
              }
            }
          );
        }, 3000);
      }
    }
  }

  componentWillMount() {
    this.props.getMe();
    this.props.collectCards();
  }

  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleToken = this.handleToken.bind(this);
    this.returnCustomerCards = this.returnCustomerCards.bind(this);
    this.handleCancelSubscription = this.handleCancelSubscription.bind(this);
    this.purchaseMonthly = this.purchaseMonthly.bind(this);
    this.purchaseAnnual = this.purchaseAnnual.bind(this);
    this.purchaseQuarterly = this.purchaseQuarterly.bind(this);
    this.purchaseBiAnnually = this.purchaseBiAnnually.bind(this);
    this.modalOverlayForCards = this.modalOverlayForCards.bind(this);
    this.closeCardsOverlay = this.closeCardsOverlay.bind(this);
    this.returnValidCustomerCards = this.returnValidCustomerCards.bind(this);
    this.clickedRow = this.clickedRow.bind(this);
    this.deleteThisCard = this.deleteThisCard.bind(this);
    this.showSpinner = this.showSpinner.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.completed3DS = this.completed3DS.bind(this);
  }

  completed3DS() {
    this.setState(
      {
        isSubmitting: true,
        secureModalOpen: false,
        redirectURL: null,
      },
      () => {
        console.log("Go Check the State of the Payment");
        const {
          annualPlanServiceCost,
          shortTermPlanServiceCost,
          monthlyPlanServiceCost,
          midTermPlanServiceCost,
        } = this.state.defaults;
        //console.log(JSON.stringify(values));
        var details = {};
        var product;
        var service;
        if (this.state.annualSelected) {
          product = "Annual Plan";
          service = annualPlanServiceCost;
        } else if (this.state.biAnnualSelected) {
          product = "6 Month Plan";
          service = midTermPlanServiceCost;
        } else if (this.state.quarterlySelected) {
          product = "3 Month Plan";
          service = shortTermPlanServiceCost;
        } else if (this.state.monthlySelected) {
          product = "Monthly Plan";
          service = monthlyPlanServiceCost;
        } else {
          return;
        }
        details.cardId = this.state.cardId;
        details.productName = product;
        details.serviceCost = service;
        details.paymentIntentId = this.state.paymentIntentId;
        this.setState(
          {
            isSubmitting: true,
          },
          () => {
            //console.log(details);
            this.props.checkPurchase(details);
          }
        );
      }
    );
  }

  openModal() {
    this.setState({ secureModalOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  closeModal() {
    this.setState({ secureModalOpen: false });
  }

  purchaseMonthly() {
    this.setState(
      {
        showCardsOverlay: false,
        annualSelected: false,
        biAnnualSelected: false,
        quarterlySelected: false,
        monthlySelected: true,
      },
      () => {
        this.clickedRow();
      }
    );
  }

  purchaseAnnual() {
    this.setState(
      {
        showCardsOverlay: false,
        annualSelected: true,
        biAnnualSelected: false,
        quarterlySelected: false,
        monthlySelected: false,
      },
      () => {
        this.clickedRow();
      }
    );
  }

  purchaseQuarterly() {
    this.setState(
      {
        showCardsOverlay: false,
        annualSelected: false,
        biAnnualSelected: false,
        quarterlySelected: true,
        monthlySelected: false,
      },
      () => {
        this.clickedRow();
      }
    );
  }

  purchaseBiAnnually() {
    this.setState(
      {
        showCardsOverlay: false,
        annualSelected: false,
        biAnnualSelected: true,
        quarterlySelected: false,
        monthlySelected: false,
      },
      () => {
        this.clickedRow();
      }
    );
  }

  clickedRow() {
    const {
      annualPlanServiceCost,
      shortTermPlanServiceCost,
      monthlyPlanServiceCost,
      midTermPlanServiceCost,
    } = this.state.defaults;
    //console.log(JSON.stringify(values));
    this.setState(
      {
        selectedKey: 0,
      },
      () => {
        var details = {};
        var product;
        var service;
        if (this.state.annualSelected) {
          product = "Annual Plan";
          service = annualPlanServiceCost;
        } else if (this.state.biAnnualSelected) {
          product = "6 Month Plan";
          service = midTermPlanServiceCost;
        } else if (this.state.quarterlySelected) {
          product = "3 Month Plan";
          service = shortTermPlanServiceCost;
        } else if (this.state.monthlySelected) {
          product = "Monthly Plan";
          service = monthlyPlanServiceCost;
        } else {
          return;
        }
        details.productName = product;
        details.serviceCost = service;
        this.setState(
          {
            isSubmitting: false,
          },
          () => {
            //console.log(details);
            this.props.purchaseItem(details);
          }
        );
      }
    );
  }

  returnValidCustomerCards() {
    const rows = [
      <button
        className="btn btn-3d btn-success"
        onClick={(e) => {
          this.clickedRow();
        }}
      >
        Pay Now
      </button>,
    ];
    console.log(rows);
    return rows;
  }

  modalOverlayForCards() {
    if (this.state.showCardsOverlay === true) {
      return (
        <div className="container" ref="cards">
          <br />
          <h4>Please complete your subscription with our payment partner:</h4>
          <h5>
            (If you have an existing service it will be extended from your
            current expiry date)
          </h5>
          <button
            className="btn btn-3d btn-success"
            onClick={(e) => {
              this.clickedRow();
            }}
          >
            Pay Now
          </button>
        </div>
      );
    }
  }

  closeCardsOverlay() {
    this.setState({
      showCardsOverlay: false,
    });
  }

  deleteThisCard(values) {
    //console.log(JSON.stringify(values));
    if (values && values.id) {
      this.setState(
        {
          isSubmitting: true,
        },
        () => {
          this.props.deleteMyCard({ card: values.id });
        }
      );
    }
  }

  returnCustomerCards() {
    const { customer } = this.state;
    if (
      customer &&
      customer.sources &&
      customer.sources.data &&
      customer.sources.data.length > 0
    ) {
      const rows = this.state.customer.sources.data.map((card, i) => (
        <tr key={i}>
          <td>{card.brand}</td>
          <td>**** **** **** {card.last4}</td>
          <td>{new Moment(card.exp_month, "MM").format("MMM")}</td>
          <td>{card.exp_year}</td>
          <td>{card.address_zip}</td>
          <td>
            <button
              className="btn btn-3d btn-danger"
              onClick={(e) => {
                this.deleteThisCard(card);
              }}
            >
              Delete Card
            </button>
          </td>
        </tr>
      ));
      //console.log(rows);
      return rows;
    } else {
      return (
        <tr key={0}>
          <td>No Cards Stored</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      );
    }
  }

  handleSubmit(values) {
    values.preventDefault();
    this.setState(
      {
        isSubmitting: true,
      },
      () => {
        this.props.updateUser({
          user: {
            company: this.state.user.company,
            address1: this.state.user.address1,
            address2: this.state.user.address2,
            city: this.state.user.city,
            region: this.state.user.region,
            country: this.state.user.country,
            postcode: this.state.user.postcode,
            firstName: this.state.user.firstName,
            lastName: this.state.user.lastName,
            phone: this.state.user.phone,
          },
        });
      }
    );
  }

  handleSelect(info) {
    //console.log(info);
    //console.log(`selected ${info.key}`);
    switch (info.key) {
      case "1-0":
        this.setState({
          showingAddress: false,
          showingCard: false,
          showingUpgrades: false,
          showingInfo: true,
        });
        break;
      case "1-1":
        this.setState({
          showingAddress: false,
          showingCard: true,
          showingUpgrades: false,
          showingInfo: false,
        });
        break;
      case "1-2":
        this.setState({
          showingAddress: true,
          showingCard: false,
          showingUpgrades: false,
          showingInfo: false,
        });
        break;
      case "2-1":
        this.setState({
          showingAddress: false,
          showingCard: false,
          showingUpgrades: true,
          showingInfo: false,
        });
        break;
      default:
        break;
    }
  }

  handleToken(token) {
    if (token && token.token && token.token.id) {
      this.setState(
        {
          isSubmitting: true,
        },
        () => {
          //console.log("TOOOOOKEEEEEENNNNN");
          //console.log(token.token.id);
          this.props.addCard({ cardToken: token.token.id });
        }
      );
    } else {
      this.notify(
        false,
        "Please add your card details into the form before you submit them"
      );
    }
  }

  selectCountry(val) {
    var user = this.state.user;
    user.country = val;
    this.setState({ user: user });
  }

  selectRegion(val) {
    var user = this.state.user;
    user.region = val;
    this.setState({ user: user });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    var user = this.state.user;
    user[name] = value;
    this.setState({
      user: user,
    });
  }

  handleCancelSubscription() {
    if (
      this.props.state &&
      this.props.state.account.customer &&
      this.props.state.account.customer.subscriptions &&
      this.props.state.account.customer.subscriptions.data &&
      this.props.state.account.customer.subscriptions.data.length > 0 &&
      this.props.state.account.customer.subscriptions.data[0].id
    ) {
      this.setState(
        {
          isSubmitting: true,
        },
        () => {
          //console.log("I have subscriptions: " + this.state.customer.subscriptions.data[0].id);
          this.props.cancelMySubscription({
            subscriptionId: this.state.customer.subscriptions.data[0].id,
          });
        }
      );
    } else {
      //console.log("No Subscription");
    }
  }

  showSpinner() {
    if (this.state.isSubmitting) {
      return (
        <Loader
          fullPage
          loading
          text="Please wait..."
          textStyle={{ color: "black" }}
        />
      );
    }
  }

  render() {
    const {
      user,
      product,
      showingInfo,
      showingAddress,
      showingCard,
      showingUpgrades,
    } = this.state;
    var commonMenu = (
      <Menu onSelect={this.handleSelect}>
        <SubMenu title={<span>Account</span>} key="1">
          <MenuItem key="1-0">My Account</MenuItem>
          <MenuItem key="1-2">View Address Details</MenuItem>
        </SubMenu>
        <SubMenu title={<span>Subscriptions</span>} key="2">
          <MenuItem key="2-1">Renew / Cancel Subscription</MenuItem>
        </SubMenu>
      </Menu>
    );

    var horizontalMenu = React.cloneElement(commonMenu, {
      mode: "horizontal",
      motion: animation,
    });

    var firstName = product ? product.firstName : "";
    var expiryDate = product
      ? product.expires
        ? new Moment(product.expires)
        : ""
      : "";
    var hasExpired = false;
    var today = new Moment();
    //console.log(today);
    //console.log(new Moment(product ? product.expires ? new Moment(product.expires) : "" : ""));
    if (today.isAfter(new Moment(expiryDate, "LL"))) {
      hasExpired = true;
    }
    expiryDate = product
      ? product.expires
        ? new Moment(product.expires).format("LL")
        : ""
      : "";
    var productName = product
      ? product.productMonths
        ? product.productMonths === 1
          ? product.productMonths + " Month "
          : product.productMonths + " Months "
        : ""
      : "";
    //console.log(user);
    var subscription = product
      ? product.subscribtionCancelled
        ? product.subscribtionCancelled
        : null
      : null;
    var isSubscriber = false;
    var labelText = "Your " + productName + " product expires on " + expiryDate;
    if (
      this.props.state &&
      this.props.state.account &&
      this.props.state.account.customer &&
      this.props.state.account.customer.subscriptions &&
      this.props.state.account.customer.subscriptions.data &&
      this.props.state.account.customer.subscriptions.data.length > 0 &&
      this.state.product &&
      this.state.product.productMonths &&
      this.state.product.productMonths === 1
    ) {
      var subId = this.props.state.account.customer.subscriptions.data[0].id;
      if (subId === subscription) {
        labelText = "Your subscription will expire on " + expiryDate;
        isSubscriber = false;
      } else {
        labelText =
          "You have an ongoing subscription to the UKTV Router Service";
        isSubscriber = true;
      }
    } else if (hasExpired) {
      labelText = "Your " + productName + " product expired on " + expiryDate;
    }
    if (showingInfo) {
      if (isSubscriber) {
        return (
          <div>
            <Header />
            <section id="wrapper" className="container">
              <ToastContainer />
              {this.showSpinner()}
              <div>{horizontalMenu}</div>
              <br />
              <label>Welcome back {firstName}</label>
              <br />
              <label>{labelText}</label>
              <br />
              <br />
              <br />
              <hr />
            </section>
            <Footer />
          </div>
        );
      } else {
        return (
          <div>
            <Header />
            <section id="account">
              <ToastContainer />
              <div className="container">
                <div>{horizontalMenu}</div>
                <br />
                <label>Welcome back {firstName}</label>
                <br />
                <label>{labelText}</label>
              </div>
            </section>
            <Footer />
          </div>
        );
      }
    } else if (showingAddress) {
      return (
        <div>
          <Header />
          <div id="wrapper">
            <section id="account" className="container">
              <ToastContainer />
              {this.showSpinner()}
              <div>{horizontalMenu}</div>

              <form onSubmit={this.handleSubmit}>
                <button
                  type="submit"
                  id="hiddenFormButton"
                  ref="mainFormButton"
                  style={{ display: "none" }}
                ></button>
                <fieldset className="mt-60">
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <label htmlFor="firstName">First Name *</label>
                      <input
                        id="firstName"
                        value={user.firstName}
                        onChange={this.handleInputChange}
                        name="firstName"
                        type="text"
                        required={true}
                        className="form-control required"
                      />
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <label htmlFor="billing_lastname">Last Name *</label>
                      <input
                        id="lastName"
                        value={user.lastName}
                        onChange={this.handleInputChange}
                        name="lastName"
                        required={true}
                        type="text"
                        className="form-control required"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <label htmlFor="company">Company</label>
                      <input
                        id="company"
                        value={user.company}
                        onChange={this.handleInputChange}
                        name="company"
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <label htmlFor="billing_address1">Address *</label>
                      <input
                        id="address1"
                        value={user.address1}
                        onChange={this.handleInputChange}
                        required={true}
                        name="address1"
                        type="text"
                        className="form-control required"
                        placeholder="Address 1"
                      />
                      <input
                        id="address2"
                        value={user.address2}
                        onChange={this.handleInputChange}
                        name="address2"
                        type="text"
                        className="form-control mt-10"
                        placeholder="Address 2"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <label htmlFor="city">City *</label>
                      <input
                        id="city"
                        value={user.city}
                        onChange={this.handleInputChange}
                        required={true}
                        name="city"
                        type="text"
                        className="form-control required"
                      />
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <label htmlFor="postcode">Zip/Postal Code *</label>
                      <input
                        id="postcode"
                        value={user.postcode}
                        onChange={this.handleInputChange}
                        required={true}
                        name="postcode"
                        type="text"
                        className="form-control required"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <label htmlFor="country">Country *</label>
                      <label className="input mb-10">
                        <CountryDropdown
                          id="country"
                          value={user.country}
                          onChange={(val) => this.selectCountry(val)}
                          style={{ width: "100px" }}
                          required={true}
                        />
                        <b className="tooltip tooltip-bottom-right">
                          Your Country
                        </b>
                      </label>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <label htmlFor="region">Region *</label>
                      <label className="input mb-10">
                        <RegionDropdown
                          id="region"
                          country={user.country}
                          value={user.region}
                          onChange={(val) => this.selectRegion(val)}
                          disableWhenEmpty={true}
                          required={true}
                          blankOptionLabel="Select Country First"
                        />
                        <b className="tooltip tooltip-bottom-right">
                          Your Region
                        </b>
                      </label>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <label htmlFor="phone">Phone *</label>
                      <input
                        id="phone"
                        value={user.phone}
                        onChange={this.handleInputChange}
                        name="phone"
                        type="text"
                        className="form-control required"
                        required={true}
                      />
                    </div>
                  </div>

                  <hr />

                  <div className="row">
                    <div className="col-lg-6 m-0 clearfix">
                      <button className="btn btn-primary">
                        Update Details
                      </button>
                    </div>
                  </div>
                </fieldset>
              </form>
            </section>
          </div>
          <Footer />
        </div>
      );
    } else if (showingCard) {
      return (
        <div>
          <Header />
          <section id="account" className="container">
            {this.showSpinner()}
            <ToastContainer />
            <div>{horizontalMenu}</div>
            <br />
            <h4>Your Cards</h4>
            <table className="table table-lg">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Last 4</th>
                  <th>Expiry Month</th>
                  <th>Expiry Year</th>
                  <th>Registered Postcode</th>
                </tr>
              </thead>
              <tbody>{this.returnCustomerCards()}</tbody>
            </table>
            <fieldset
              id="ccPayment"
              className="mt-30 card card-default"
              style={{ backgroundColor: "#EEEEEE" }}
            >
              <div className="card-block">
                <h4>Add Payment Method</h4>
                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="cc_number">Credit Card Number *</label>
                    <Elements>
                      <AddCardForm
                        onRef={(ref) => (this.child = ref)}
                        handleToken={this.handleToken}
                      />
                    </Elements>
                  </div>
                </div>
              </div>
            </fieldset>
          </section>
          <Footer />
        </div>
      );
    } else if (showingUpgrades) {
      if (this.state.defaults) {
        const {
          annualPlanServiceCost,
          shortTermPlanServiceCost,
          monthlyPlanServiceCost,
          midTermPlanServiceCost,
        } = this.state.defaults;
        if (isSubscriber) {
          return (
            <div>
              <Header />
              <section id="wrapper" className="container">
                <ToastContainer />
                {this.showSpinner()}
                <div>{horizontalMenu}</div>
                <div className="container">
                  <header className="text-center mb-10">
                    <h2>Cancel your subscription</h2>
                    <p className="lead font-lato">
                      In order to purchase a new product you must cancel your
                      existing subscription
                    </p>
                    <p className="lead font-lato">
                      Your subscription will run until the end of the month of
                      the current payment, any new product your purchase after
                      cancelling will be added on to this end date.
                    </p>
                    <hr />
                    <button
                      className="btn btn-3d btn-danger"
                      onClick={this.handleCancelSubscription}
                    >
                      Cancel My Subscription
                    </button>
                  </header>
                </div>
              </section>
              <Footer />
            </div>
          );
        } else {
          return (
            <div>
              <Header />
              <section id="wrapper" className="container">
                <ToastContainer />
                {this.showSpinner()}
                <div>{horizontalMenu}</div>
                <div className="container">
                  <header className="text-center mb-10">
                    <h2>Our Pricing</h2>
                    <p className="lead font-lato">
                      Choose the package that suits you best
                    </p>
                    <hr />
                  </header>

                  <div className="row">
                    <div className="col-md-3 col-sm-2">
                      <div
                        className="price-clean"
                        style={{
                          backgroundColor:
                            this.state.annualSelected === true
                              ? "RGBA(233, 250, 155, 0.50)"
                              : "white",
                        }}
                      >
                        <h4>
                          <sup>€</sup>
                          {annualPlanServiceCost}
                          <em>/year</em>
                        </h4>
                        <h5> Annual Plan </h5>
                        <hr />
                        <p>
                          For people looking for the most cost effective price.
                        </p>
                        <hr />
                        <button
                          className="btn btn-3d btn-teal"
                          onClick={this.purchaseAnnual}
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>

                    <div className="col-md-3 col-sm-2">
                      <div
                        className="price-clean price-clean-popular"
                        style={{
                          backgroundColor:
                            this.state.monthlySelected === true
                              ? "RGBA(233, 250, 155, 0.50)"
                              : "white",
                        }}
                      >
                        <div className="ribbon">
                          <div className="ribbon-inner">POPULAR</div>
                        </div>
                        <h4>
                          <sup>€</sup>
                          {monthlyPlanServiceCost}
                          <em>/month</em>
                        </h4>
                        <h5> Monthly Plan </h5>
                        <hr />
                        <p>
                          Our On/Off post Brexit solution - start and stop as
                          needed
                        </p>
                        <hr />
                        <button
                          className="btn btn-3d btn-primary"
                          onClick={this.purchaseMonthly}
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>

                    <div className="col-md-3 col-sm-2">
                      <div
                        className="price-clean"
                        style={{
                          backgroundColor:
                            this.state.biAnnualSelected === true
                              ? "RGBA(233, 250, 155, 0.50)"
                              : "white",
                        }}
                      >
                        <h4>
                          <sup>€</sup>
                          {midTermPlanServiceCost}
                          <em> 6 months</em>
                        </h4>
                        <h5> Mid Term </h5>
                        <hr />
                        <p>For people who need a mid term solution.</p>
                        <hr />
                        <button
                          className="btn btn-3d btn-teal"
                          onClick={this.purchaseBiAnnually}
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>

                    <div className="col-md-3 col-sm-2">
                      <div
                        className="price-clean"
                        style={{
                          backgroundColor:
                            this.state.quarterlySelected === true
                              ? "RGBA(233, 250, 155, 0.50)"
                              : "white",
                        }}
                      >
                        <h4>
                          <sup>€</sup>
                          {shortTermPlanServiceCost}
                          <em> 3 months</em>
                        </h4>
                        <h5> Short Term </h5>
                        <hr />
                        <p>For people who need a short term solution.</p>
                        <hr />
                        <button
                          className="btn btn-3d btn-teal"
                          onClick={this.purchaseQuarterly}
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {this.modalOverlayForCards()}
              </section>
              <Footer />
            </div>
          );
        }
      } else {
        return (
          <div>
            <Header />
            <div id="wrapper" className="container">
              <div>{horizontalMenu}</div>
            </div>
            <Footer />
          </div>
        );
      }
    }
  }
}

function mapStateToProps(state) {
  //console.log("ME TOO");
  //console.dir(state)
  return {
    state: state,
    paymentIntentId: state.account.paymentIntentId,
    paymentIntentProcess: state.account.paymentIntentProcess,
    redirectURL: state.account.redirectURL,
  };
}

export default connect(mapStateToProps, actions)(withRouter(Account));
