import { combineReducers } from 'redux';
import authReducer from './authReducer';
import defaultsReducer from './defaultsReducer';
import adminReducer from './adminReducer';
import accountReducer from './accountReducer';
import { reducer as reduxForm} from 'redux-form';

export default combineReducers({
  account:accountReducer,
  auth: authReducer,
  admin: adminReducer,
  defaults:defaultsReducer,
  form : reduxForm
});
