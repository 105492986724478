import React from 'react';

const Callout  = () => {
  return (
    <section className="callout-dark heading-title heading-arrow-bottom" style={{zIndex:"100"}}>
				<div className="container">
					<div className="text-center">
						<h3 className="fs-30">If only I could access live TV as if I were in the UK</h3>
						<p>Well now you can, with UK TV Router you can access TV as if you were in the UK</p>
					</div>
				</div>
			</section>
  );
};

export default Callout;
