export const FETCH_USER = "fetch_user";
export const AUTH_ERROR = "auth_error";
export const AUTHENTICATED = "authenticated";
export const UNAUTHENTICATED = "unauthenticated";
export const COLLECT_ME = "collect_me";
export const FAILED_COLLECT_ME = "failed_collect_me";
export const COLLECT_CARDS = "collect_cards";
export const FAILED_COLLECT_CARDS = "failed_collect_cards";
export const UPDATE_USER = "update_user";
export const UPDATE_USER_FAILED = "failed_update_user";
export const RESET_PASSWORD_SUCCESS = "reset_password_success";
export const RESET_PASSWORD_FAIL = "reset_password_fail";
export const PURCHASE_ITEM = "purchase_item";
export const GET_DEFAULTS = "get_defaults";
export const GET_DEFAULTS_FAILED = "get_defaults_failed";
export const ASK_QUESTIONS_FAILED = "ask_questions_failed";
export const ASK_QUESTIONS = "ask_questions";
export const CONTACT_US_FAILED = "contact_us_failed";
export const CONTACT_US = "contact_us";
export const RESET_QUESTION = "reset_questions";
export const COLLECTED_USERS = "collected_users";
export const FAILED_COLLECTED_USERS = "failed_collected_users";
export const COLLECTED_MESSAGES = "collected_messages";
export const FAILED_COLLECTED_MESSAGES = "failed_collected_messages";
export const SAVED_USER = "saved_user";
export const FAILED_SAVED_USER = "failed_saved_user";
export const CANCEL_SUBSCRIPTION = "cancel_subscription";
export const CANCEL_SUBSCRIPTION_FAILED = "cancel_subscription_failed";
export const DELETE_CARD = "delete_card";
export const PURCHASE_ADDITIONAL_ITEM = "purchase_additional_item";
export const PURCHASE_ADDITIONAL_ITEM_FAILED =
  "purchase_additional_item_failed";
export const PURCHASE_ADDITIONAL_ITEM_URL = "purchase_additional_item_url";
export const SIGNED_UP = "signed_up";
export const SIGNED_UP_URL = "signed_up_url";
export const SIGNED_UP_FAILED = "signed_up_failed";
export const RESET_CONTACT_US = "reset_contact_us";
export const RESET_ROUTERS = "reset_routers";
export const CREATE_USER = "create_user";
export const FAILED_CREATE_USER = "failed_create_user";
export const RESET_USER_ERROR = "reset_user_error";
export const COLLECT_DISCOUNTS = "collect_discounts";
export const CREATE_DISCOUNT = "create_discounts";
export const CREATE_DISCOUNT_FAILED = "create_discount_failed";
export const DELETE_DISCOUNT_FAILED = "delete_discount_failed";
export const COLLECT_AFFILIATES = "collect_affiliates";
export const CREATE_AFFILIATE = "create_affiliate";
export const CREATE_AFFILIATE_FAILED = "create_affiliate_failed";
export const DELETE_AFFILIATE_FAILED = "delete_affiliate_failed";
export const CHECK_PROMO_FAILED = "check_promo_failed";
export const CHECK_PROMO = "check_promo";
export const SEND_EMAIL_FAILED = "send_email_failed";
export const SEND_EMAIL = "send_email";
export const UPDATE_DEFAULTS_FAILED = "update_defaults_failed";
export const UPDATE_DEFAULTS = "update_defaults";
export const COLLECTED_HARDWARE_SALES = "collect_hardware_sales";
export const FAILED_COLLECTED_HARDWARE_SALES = "failed_collect_hardware_sales";
export const COLLECTED_SOFTWARE_SALES = "collect_software_sales";
export const FAILED_COLLECTED_SOFTWARE_SALES = "failed_collect_software_sales";
export const PAYMENT_INTENT = "payment_intent";
export const PAYMENT_INTENT_FAILED = "payment_intent_failed";
export const PAYMENT_INTENT_PROCESS = "payment_intent_process";
export const FAILED_ADD_CARD = "failed_Add_card";
export const SEND_SALES = "send_sales";
export const SEND_SALES_FAILED = "send_sales_failed";
export const PAYMENT_URL = "payment_url";
export const CHECK_SUBSCRIPTION = "check_subscription";
export const CHECK_SESSION = "check_session";
