import React, {Component} from 'react';


class Parallax extends Component  {

  render() {
    return (
      <section id="home" ref="home" className="parallax parallax-2" style={{backgroundImage: "url('assets/images/uktvrouter/ani_logo3.gif')"}}>
  				<div className="overlay dark-8"></div>

  				<div className="container">
  					<div className="text-center">

  						<h3 className="m-0">What do I get?</h3>
  						<ul className="mt-10 list-unstyled list-inline">
                <li>
                  <span>A new preconfigured, plug and play, hi speed wifi Router</span><br/>
                </li>
                <li>
                  <span>Your own network connection to our own managed UK server network with a fixed UK IP address. </span><br/>
                </li>
                <li>
                  <span>This is a must have for all expats who enjoy being able to watch what they want, where they want and when they want.</span><br/>
                </li>
              </ul>

              <h3 className="m-0">What devices can I use?</h3>
  						<ul className="mt-10 list-unstyled list-inline">
                <li>
                  <span>Sky+ HD (must have satellite signal also),</span><br/>
                </li>
                <li>
                  <span>Freesat with Freetime (must have satellite signal also),</span><br/>
                </li>
                <li>
                  <span>Smart TVs,</span><br/>
                </li>
                <li>
                  <span>Now TV,</span><br/>
                </li>
                <li>
                  <span>Roku TV,</span><br/>
                </li>
                <li>
                  <span>Humax H3,</span><br/>
                </li>
                <li>
                  <span>PlayStation,</span><br/>
                </li>
                <li>
                  <span>Apple TV,</span><br/>
                </li>
                <li>
                  <span>Computers,</span><br/>
                </li>
                <li>
                  <span>Tablets and Smartphones.</span><br/>
                </li>
              </ul>


              <h3 className="m-0">What apps and services can I access?</h3>
  						<ul className="mt-10 list-unstyled list-inline">
                <li>
                  <span>BBC iPlayer – Live and catch-up,</span><br/>
                </li>
                <li>
                  <span>ITV Hub – Live and catch-up,</span><br/>
                </li>
                <li>
                  <span>All 4 – catch-up,</span><br/>
                </li>
                <li>
                  <span>Demand Five – catch-up,</span><br/>
                </li>
                <li>
                  <span>www.tvguide.co.uk (for live UK TV Channels),</span><br/>
                </li>
                <li>
                  <span>SkyGo,</span><br/>
                </li>
                <li>
                  <span>BT Sport,</span><br/>
                </li>
                <li>
                  <span>Netflix GB and Amazon prime video</span><br/>
                </li>
              </ul>

              <h3 className="m-0">What about satellite connected services?</h3>
  						<ul className="mt-10 list-unstyled list-inline">
                <li>
                  <span>Sky+ HD: you can download from catch up, on demand, box sets, Sky store, Sky cinema, box office.</span><br/>
                </li>
                <li>
                  <span>Catch-up works even if you dont have the satellite signal (for that channel) because of where you live, i.e. on BBC, ITV etc., you can still get catch-up via the internet.</span><br/>
                </li>
                <li>
                  <span>If you have Sky in the UK and a Sky box in your second home in France, Spain, Italy etc. you can take the card only and pair it to your other Sky box simply by pushing in the card and pressing select when prompted but only when you are connected to the UK TV Router network.</span><br/>
                </li>
                <li>
                  <span>Freesat Freetime, watch catch up TV from all the main channels, goes back 7 days.</span><br/>
                </li>
              </ul>

              <h3 className="m-0">What else do I get?</h3>
  						<ul className="mt-10 list-unstyled list-inline">
                <li>
                  <span>UK Lottery,</span><br/>
                </li>
                <li>
                  <span>Online shopping and getting things delivered to family and friends in the UK,</span><br/>
                </li>
                <li>
                  <span>This is a must have for all expats who enjoy being able to watch what you want where you want and when you want. Get connected with UK TV Router!</span><br/>
                </li>
              </ul>

  					</div>
            </div>
  			</section>
    );
  }
};

export default Parallax;
