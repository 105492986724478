import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as actions from './adminActions';
import { withRouter } from 'react-router-dom';
import Menu, {SubMenu, MenuItem} from 'rc-menu';
import animate from 'css-animation';
import 'rc-menu/assets/index.css';
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment'
import { DateTimePicker } from 'react-widgets'
import 'react-widgets/dist/css/react-widgets.css'
import { Loader } from 'react-overlay-loader';
import { ToastContainer, toast } from 'react-toastify';
import Header from './Header'
import Footer from './Footer'

Moment.locale('en')
momentLocalizer()

const animation = {
  enter(node, done) {
    let height;
    return animate(node, 'rc-menu-collapse', {
      start() {
        height = node.offsetHeight;
        node.style.height = 0;
      },
      active() {
        node.style.height = `${height}px`;
      },
      end() {
        node.style.height = '';
        done();
      },
    });
  },

  appear() {
    return this.enter.apply(this, arguments);
  },

  leave(node, done) {
    return animate(node, 'rc-menu-collapse', {
      start() {
        node.style.height = `${node.offsetHeight}px`;
      },
      active() {
        node.style.height = 0;
      },
      end() {
        node.style.height = '';
        done();
      },
    });
  },
};

var contains = function(needle) {
    // Per spec, the way to identify NaN is that it is not equal to itself
    // eslint-disable-next-line
    var findNaN = needle !== needle;
    var indexOf;

    if(!findNaN && typeof Array.prototype.indexOf === 'function') {
        indexOf = Array.prototype.indexOf;
    } else {
        indexOf = function(needle) {
            var i = -1, index = -1;

            for(i = 0; i < this.length; i++) {
                var item = this[i];
                // eslint-disable-next-line
                if((findNaN && item !== item) || item === needle) {
                    index = i;
                    break;
                }
            }

            return index;
        };
    }

    return indexOf.call(this, needle) > -1;
};

class Admin extends Component {

  toastId = 1234;
  toastIdSuccess = 3456;

  notify = (success, value) => {
    if (success) {
      if (! toast.isActive(this.toastIdSuccess)) {
              this.toastId = toast.success(value, {
              position: toast.POSITION.TOP_CENTER
          });
      }
    } else {
      if (! toast.isActive(this.toastId)) {
              this.toastId = toast.error(value, {
              position: toast.POSITION.TOP_CENTER
          });
      }
    }
  }


  state = {
    code:"",
    url:"",
    copySuccess: '',
    viewAllUsers:true,
    addUser:false,
    viewServiceSales:false,
    viewHardwareSales:false,
    removeUser:false,
    viewRouter:false,
    viewDiscounts:false,
    viewAffiliates:false,
    addRouter:false,
    removeRouter:false,
    viewMessages:false,
    numRecords:20,
    page:0,
    users:[],
    messages:[],
    hardwareSales:[],
    softwareSales:[],
    searchUsersText:"",
    searchMessagesText:"",
    currentUser:null,
    newUser : {
      firstName:"",
      lastName:"",
      email:"",
      productMonths:12
    },
    isUsersModalOpen: false,
    isSubmitting:false,
    isNewUsersModalOpen:false,
    isDiscountModalOpen:false,
    isAffiliateModalOpen:false,
    affiliates:[],
    discounts:[],
    currentAffiliate:{
      affiliateName:"",
      affiliateIdentifier:"",
      affiliateURL:""
    },
    currentDiscount:{
      code:"",
      startDate:null,
      endDate:null,
      percentageOff:"0",
      moneyOff:"0",
      monthsIncluded:[]
    },
    discountType:false,
    emailForm:false,
    viewDefaults:false
  }

  goNextPage() {
    //console.log("Get Next");
    var nextPage = this.state.page + this.state.numRecords;
    this.setState( {
      page:nextPage
    }, () => {
      this.getData()
    })
  }

  goPreviousPage() {
    //console.log("Get Previous");
    if (this.state.page > 0) {
      var page = this.state.page - this.state.numRecords
      this.setState( {
        page:page
      }, () => {
        setTimeout(() => {
          this.getData()
        },500)
      })
    }
  }

  clickedRow(values) {
    //console.log(JSON.stringify(values));
    this.setState({
      currentUser:values,
      isUsersModalOpen: !this.state.isUsersModalOpen
    })
  }

  returnCurrentUsers() {
    var CurrentDate = new Date();
    var inOneMonth =  new Date();
    inOneMonth = inOneMonth.setDate(inOneMonth.getDate() + 7)
    const rows = this.state.users.map((user, i) =>
      <tr data-toggle="modal" data-target="#termsModal" onClick={(e) => {this.clickedRow(user)}} style={Moment(user.expires) <  CurrentDate ? {backgroundColor:"orange"} : Moment(user.expires) <  inOneMonth ? {backgroundColor:"yellow"} : {backgroundColor:"white"} } key={i}>
        <td >{user.firstName + " " + user.lastName}</td>
        <td >{user.email}</td>
        <td >{user.username}</td>
        <td >{user.password}</td>
        <td >{user.phone}</td>
        <td >{user.productMonths} Months</td>
        <td >{Moment(user.expires).format("DD MMM YYYY")}</td>
      </tr>
    );
    //console.log(rows);
    return rows;
  }

  deleteThisDiscount(values) {
    //console.log(JSON.stringify(values));
    if (values && values._id) {
      this.setState({
        isSubmitting:true
      }, () => {
        this.props.deleteDiscount({discount:values._id})
      })
    }
  }

  deleteThisAffiliate(values) {
    //console.log(JSON.stringify(values));
    if (values && values._id) {
      this.setState({
        isSubmitting:true
      }, () => {
        this.props.deleteAffiliate({affiliate:values._id})
      })
    }
  }

  returnCurrentDiscounts() {
    var CurrentDate = new Date();
    const rows = this.state.discounts.map((discount, i) =>
      <tr data-toggle="modal" data-target="#discountsModal" style={Moment(discount.endDate) <  CurrentDate ? {backgroundColor:"orange"} : {backgroundColor:"white"} } key={i}>
        <td style={{textAlign:"center"}}>{discount.code}</td>
        <td style={{textAlign:"center"}}>{Moment(discount.startDate).format("DD MMM YYYY")}</td>
        <td style={{textAlign:"center"}}>{Moment(discount.endDate).format("DD MMM YYYY")}</td>
        <td style={{textAlign:"center"}}>{discount.monthsIncluded.join(", ")}</td>
        <td style={{textAlign:"center"}}>{discount.percentageOff ?  discount.percentageOff +"%" : ""}</td>
        <td style={{textAlign:"center"}}>{discount.moneyOff ? "€" + discount.moneyOff + " Off" : "" }</td>
        <td style={{textAlign:"center"}}><button className="btn btn-3d btn-danger" onClick={(e) => {this.deleteThisDiscount(discount)}}>
          Delete
        </button></td>
      </tr>
    );
    //console.log(rows);
    return rows;
  }

  returnCurrentAffiliates() {
    const rows = this.state.affiliates.map((affiliate, i) =>
      <tr data-toggle="modal" data-target="#affiliatesModal" key={i}>
        <td style={{textAlign:"center"}}>{affiliate.affiliateName}</td>
        <td style={{textAlign:"center"}}>{affiliate.affiliateIdentifier}</td>
        <td style={{textAlign:"center"}}>{affiliate.affiliateURL}</td>
        <td style={{textAlign:"center"}}><button className="btn btn-3d btn-danger" onClick={(e) => {this.deleteThisAffiliate(affiliate)}}>
          Delete
        </button></td>
      </tr>
    );
    //console.log(rows);
    return rows;
  }

  returnCurrentMessages() {
    const rows = this.state.messages.map((message, i) =>
      <tr key={i}>
        <td >{Moment(message.dateSent).format("DD MMM YYYY HH:mm:ss")}</td>
        <td >{message.type}</td>
        <td >{message.attributes.object}</td>
        <td >{message.user ? message.user.firstName + " " + message.user.lastName : ""}</td>
      </tr>
    );
    //console.log(rows);
    return rows;
  }

  shouldAddNext() {
    if ((this.state.page + this.state.numRecords) < this.state.totalCount) {
      return (
        <li className="next"><a href="#next" onClick={this.goNextPage}>Next &rarr;</a></li>
      )
    }
  }

  shouldAddPrevious() {
    if (this.state.page > 0) {
      return (
        <li className="previous"><a href="#previous" onClick={this.goPreviousPage}>&larr; Previous</a></li>
      )
    }
  }

  searchUsers(values) {
    //console.log(this.state.searchUsersText);
    values.preventDefault()
    this.props.collectAllUsersSearch({skip:this.state.page,limit:this.state.numRecords,searchText:this.state.searchUsersText});
  }

  searchMessages(values) {
    //console.log(this.state.searchMessagesText);
    values.preventDefault()
    this.props.collectAllMessagesSearch({skip:this.state.page,limit:this.state.numRecords,searchText:this.state.searchMessagesText});
  }

  handleMessagesTextChange(e) {
    e.preventDefault()
    //console.log(e);
    this.setState({
      searchMessagesText:e.target.value
    },() => {
      this.props.collectAllMessagesSearch({skip:this.state.page,limit:this.state.numRecords,searchText:this.state.searchMessagesText});
    })
  }

  handleUserTextChange(e) {
    e.preventDefault()
    //console.log(e);
    this.setState({
      searchUsersText:e.target.value
    },() => {
      this.props.collectAllUsersSearch({skip:this.state.page,limit:this.state.numRecords,searchText:this.state.searchUsersText});
    })
  }

  showCorrectTable() {
    if (this.state.viewHardwareSales === true) {
      return (
        <div className="container" style={{zIndex:0}}>
          <h4>Hardware Sales</h4>
          <div className="table-responsive">
            <table className="table table-sm">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Username</th>
                  <th>Password</th>
                  <th>Product</th>
                  <th>Expires</th>
                </tr>
              </thead>
              <tbody>
                {this.returnCurrentUsers()}
              </tbody>
            </table>
            <ul className="pager">
              {this.shouldAddPrevious()}
              {this.shouldAddNext()}
            </ul>
          </div>
        </div>
      );
    } else if (this.state.viewServiceSales === true) {

    } else if (this.state.viewAllUsers === true) {
      return (
        <div className="container" style={{zIndex:0}}>
          <h4>Users</h4>
          <div className="table-responsive">
            <div>
              <form onSubmit={this.searchUsers}>
                <div className="input-group">
                <input className='form-control' type='text' placeholder="Filter by first, last name or username after 'uktv0s'" name="src" value={this.state.searchUsersText} onChange={(e) => {this.handleUserTextChange(e)}}/>
                <span className="input-group-button">
                </span>
                </div>
              </form>
            </div>
            <table className="table table-sm">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Username</th>
                  <th>Password</th>
                  <th>Phone</th>
                  <th>Product</th>
                  <th>Expires</th>
                </tr>
              </thead>
              <tbody>
                {this.returnCurrentUsers()}
              </tbody>
            </table>
            <ul className="pager">
              {this.shouldAddPrevious()}
              {this.shouldAddNext()}
            </ul>
          </div>
        </div>
      );
    } else if (this.state.viewMessages === true) {
      return (
        <div className="container" style={{zIndex:0}}>
          <h4>Messages</h4>
          <div className="table-responsive">
            <div>
              <form>
                <div className="input-group">
                <input className='form-control' type='text' placeholder="Filter by type" name="srcMessages" value={this.state.searchMessagesText} onChange={(e) => {this.handleMessagesTextChange(e)}}/>
                <span className="input-group-button">
                </span>
                </div>
              </form>
            </div>
            <table className="table table-sm">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Type</th>
                  <th>Object</th>
                  <th>User</th>
                </tr>
              </thead>
              <tbody>
                {this.returnCurrentMessages()}
              </tbody>
            </table>
            <ul className="pager">
              {this.shouldAddPrevious()}
              {this.shouldAddNext()}
            </ul>
          </div>
        </div>
      );
    } else if (this.state.viewDiscounts === true) {
      return (
        <div className="container" style={{zIndex:0}}>
          <h4>Discounts</h4>
          <div className="table-responsive">
            <table className="table table-sm">
              <thead>
                <tr>
                  <th style={{textAlign:"center"}}>Code</th>
                  <th style={{textAlign:"center"}}>Start Date</th>
                  <th style={{textAlign:"center"}}>End Date</th>
                  <th style={{textAlign:"center"}}>Plans Included</th>
                  <th style={{textAlign:"center"}}>Percentage Off</th>
                  <th style={{textAlign:"center"}}>Value Off</th>
                  <th style={{textAlign:"center"}}></th>
                </tr>
              </thead>
              <tbody>
                {this.returnCurrentDiscounts()}
              </tbody>
            </table>
          </div>
        </div>
      );
  } else if (this.state.viewAffiliates === true) {
    return (
      <div className="container" style={{zIndex:0}}>
        <h4>Affiliates</h4>
        <div className="table-responsive">
          <table className="table table-sm">
            <thead>
              <tr>
                <th style={{textAlign:"center"}}>Name</th>
                <th style={{textAlign:"center"}}>Identifier</th>
                <th style={{textAlign:"center"}}>URL</th>
                <th style={{textAlign:"center"}}></th>
              </tr>
            </thead>
            <tbody>
              {this.returnCurrentAffiliates()}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

  handleSelect(info) {
    //console.log(info);
    //console.log(`selected ${info.key}`);
    switch (info.key) {
      case "1-1":
        this.setState({
          viewAllUsers:true,
          addUser:false,
          viewServiceSales:false,
          viewHardwareSales:false,
          removeUser:false,
          viewRouter:false,
          viewDiscounts:false,
          viewAffiliates:false,
          addRouter:false,
          removeRouter:false,
          viewMessages:false,
          numRecords:20,
          page:0,
          searchUsersText:"",
          currentUser:null,
          isUsersModalOpen: false,
          isNewUsersModalOpen:false,
          isDiscountModalOpen:false,
          isAffiliateModalOpen:false,
          newUser : {
            firstName:"",
            lastName:"",
            email:"",
            productMonths:12
          },
          currentDiscount:{
            code:"",
            startDate:null,
            endDate:null,
            percentageOff:"0",
            moneyOff:"0",
            monthsIncluded:[]
          },
          currentAffiliate:{
            affiliateName:"",
            affiliateIdentifier:"",
            affiliateURL:""
          },
          discountType:false,
          emailForm:false,
          viewDefaults:false
        },() => {
          this.getData()
        })
        break;
      case "1-2":
        this.setState({
          viewAllUsers:false,
          addUser:false,
          viewServiceSales:false,
          viewHardwareSales:false,
          removeUser:false,
          viewRouter:false,
          viewDiscounts:false,
          viewAffiliates:false,
          addRouter:false,
          removeRouter:false,
          viewMessages:false,
          numRecords:20,
          page:0,
          searchUsersText:"",
          currentUser:null,
          isUsersModalOpen: false,
          isNewUsersModalOpen:true,
          isDiscountModalOpen:false,
          isAffiliateModalOpen:false,
          newUser : {
            firstName:"",
            lastName:"",
            email:"",
            productMonths:12
          },
          currentDiscount:{
            code:"",
            startDate:null,
            endDate:null,
            percentageOff:"0",
            moneyOff:"0",
            monthsIncluded:[]
          },
          currentAffiliate:{
            affiliateName:"",
            affiliateIdentifier:"",
            affiliateURL:""
          },
          discountType:false,
          emailForm:false,
          viewDefaults:false
        },() => {
          this.getData()
        })
        break;
      case "3-1":
        this.setState({
          viewAllUsers:false,
          addUser:false,
          viewServiceSales:false,
          viewHardwareSales:false,
          removeUser:false,
          viewRouter:false,
          viewDiscounts:false,
          viewAffiliates:false,
          addRouter:false,
          removeRouter:false,
          viewMessages:true,
          numRecords:20,
          page:0,
          searchUsersText:"",
          currentUser:null,
          isUsersModalOpen: false,
          isNewUsersModalOpen:false,
          isDiscountModalOpen:false,
          isAffiliateModalOpen:false,
          newUser : {
            firstName:"",
            lastName:"",
            email:"",
            productMonths:12
          },
          currentDiscount:{
            code:"",
            startDate:null,
            endDate:null,
            percentageOff:"0",
            moneyOff:"0",
            monthsIncluded:[]
          },
          currentAffiliate:{
            affiliateName:"",
            affiliateIdentifier:"",
            affiliateURL:""
          },
          discountType:false,
          emailForm:false,
          viewDefaults:false
        },() => {
          this.getData()
        })
        break;
      case "4-1":
        this.setState({
          viewAllUsers:false,
          addUser:false,
          viewServiceSales:false,
          viewHardwareSales:false,
          removeUser:false,
          viewRouter:false,
          viewDiscounts:true,
          viewAffiliates:false,
          addRouter:false,
          removeRouter:false,
          viewMessages:false,
          numRecords:20,
          page:0,
          searchUsersText:"",
          currentUser:null,
          isUsersModalOpen: false,
          isNewUsersModalOpen:false,
          isDiscountModalOpen:false,
          isAffiliateModalOpen:false,
          newUser : {
            firstName:"",
            lastName:"",
            email:"",
            productMonths:12
          },
          currentDiscount:{
            code:"",
            startDate:null,
            endDate:null,
            percentageOff:"0",
            moneyOff:"0",
            monthsIncluded:[]
          },
          currentAffiliate:{
            affiliateName:"",
            affiliateIdentifier:"",
            affiliateURL:""
          },
          discountType:false,
          emailForm:false,
          viewDefaults:false
        },() => {
          this.getData()
        })
        break;
      case "4-2":
        this.setState({
          viewAllUsers:false,
          addUser:false,
          viewServiceSales:false,
          viewHardwareSales:false,
          removeUser:false,
          viewRouter:false,
          viewDiscounts:false,
          viewAffiliates:false,
          addRouter:false,
          removeRouter:false,
          viewMessages:false,
          numRecords:20,
          page:0,
          searchUsersText:"",
          currentUser:null,
          isUsersModalOpen: false,
          isNewUsersModalOpen:false,
          isDiscountModalOpen:true,
          isAffiliateModalOpen:false,
          newUser : {
            firstName:"",
            lastName:"",
            email:"",
            productMonths:12
          },
          currentDiscount:{
            code:"",
            startDate:null,
            endDate:null,
            percentageOff:"0",
            moneyOff:"0",
            monthsIncluded:[]
          },
          currentAffiliate:{
            affiliateName:"",
            affiliateIdentifier:"",
            affiliateURL:""
          },
          discountType:false,
          emailForm:false,
          viewDefaults:false
        },() => {
          this.getData()
        })
        break;
      case "5-1":
        this.setState({
          viewAllUsers:false,
          addUser:false,
          viewServiceSales:false,
          viewHardwareSales:false,
          removeUser:false,
          viewRouter:false,
          viewDiscounts:false,
          viewAffiliates:false,
          addRouter:false,
          removeRouter:false,
          viewMessages:false,
          numRecords:20,
          page:0,
          searchUsersText:"",
          currentUser:null,
          isUsersModalOpen: false,
          isNewUsersModalOpen:false,
          isDiscountModalOpen:false,
          isAffiliateModalOpen:false,
          newUser : {
            firstName:"",
            lastName:"",
            email:"",
            productMonths:12
          },
          currentDiscount:{
            code:"",
            startDate:null,
            endDate:null,
            percentageOff:"0",
            moneyOff:"0",
            monthsIncluded:[]
          },
          currentAffiliate:{
            affiliateName:"",
            affiliateIdentifier:"",
            affiliateURL:""
          },
          discountType:false,
          emailForm:true,
          checkEmail:false,
          viewDefaults:false
        },() => {
          this.getData()
        })
        break;
      case "6-1":
        this.setState({
          viewAllUsers:false,
          addUser:false,
          viewServiceSales:false,
          viewHardwareSales:false,
          removeUser:false,
          viewRouter:false,
          viewDiscounts:false,
          viewAffiliates:false,
          addRouter:false,
          removeRouter:false,
          viewMessages:false,
          numRecords:20,
          page:0,
          searchUsersText:"",
          currentUser:null,
          isUsersModalOpen: false,
          isNewUsersModalOpen:false,
          isDiscountModalOpen:false,
          isAffiliateModalOpen:false,
          newUser : {
            firstName:"",
            lastName:"",
            email:"",
            productMonths:12
          },
          currentDiscount:{
            code:"",
            startDate:null,
            endDate:null,
            percentageOff:"0",
            moneyOff:"0",
            monthsIncluded:[]
          },
          currentAffiliate:{
            affiliateName:"",
            affiliateIdentifier:"",
            affiliateURL:""
          },
          discountType:false,
          emailForm:false,
          checkEmail:false,
          viewDefaults:true
        },() => {
          this.getData()
        })
        break;
      case "7-1":
        this.setState({
          viewAllUsers:false,
          addUser:false,
          viewServiceSales:false,
          viewHardwareSales:true,
          removeUser:false,
          viewRouter:false,
          viewDiscounts:false,
          viewAffiliates:false,
          addRouter:false,
          removeRouter:false,
          viewMessages:false,
          numRecords:20,
          page:0,
          searchUsersText:"",
          currentUser:null,
          isUsersModalOpen: false,
          isNewUsersModalOpen:false,
          isDiscountModalOpen:false,
          isAffiliateModalOpen:false,
          newUser : {
            firstName:"",
            lastName:"",
            email:"",
            productMonths:12
          },
          currentDiscount:{
            code:"",
            startDate:null,
            endDate:null,
            percentageOff:"0",
            moneyOff:"0",
            monthsIncluded:[]
          },
          currentAffiliate:{
            affiliateName:"",
            affiliateIdentifier:"",
            affiliateURL:""
          },
          discountType:false,
          emailForm:false,
          checkEmail:false,
          viewDefaults:false
        },() => {
          this.getData()
        })
        break;
      case "7-2":
        this.setState({
          viewAllUsers:false,
          addUser:false,
          viewServiceSales:true,
          viewHardwareSales:false,
          removeUser:false,
          viewRouter:false,
          viewDiscounts:false,
          viewAffiliates:false,
          addRouter:false,
          removeRouter:false,
          viewMessages:false,
          numRecords:20,
          page:0,
          searchUsersText:"",
          currentUser:null,
          isUsersModalOpen: false,
          isNewUsersModalOpen:false,
          isDiscountModalOpen:false,
          isAffiliateModalOpen:false,
          newUser : {
            firstName:"",
            lastName:"",
            email:"",
            productMonths:12
          },
          currentDiscount:{
            code:"",
            startDate:null,
            endDate:null,
            percentageOff:"0",
            moneyOff:"0",
            monthsIncluded:[]
          },
          currentAffiliate:{
            affiliateName:"",
            affiliateIdentifier:"",
            affiliateURL:""
          },
          discountType:false,
          emailForm:false,
          checkEmail:false,
          viewDefaults:false
        },() => {
          this.getData()
        })
        break;
      case "8-1":
        this.setState({
          viewAllUsers:false,
          addUser:false,
          viewServiceSales:false,
          viewHardwareSales:false,
          removeUser:false,
          viewRouter:false,
          viewDiscounts:false,
          viewAffiliates:true,
          addRouter:false,
          removeRouter:false,
          viewMessages:false,
          numRecords:20,
          page:0,
          searchUsersText:"",
          currentUser:null,
          isUsersModalOpen: false,
          isNewUsersModalOpen:false,
          isDiscountModalOpen:false,
          isAffiliateModalOpen:false,
          newUser : {
            firstName:"",
            lastName:"",
            email:"",
            productMonths:12
          },
          currentDiscount:{
            code:"",
            startDate:null,
            endDate:null,
            percentageOff:"0",
            moneyOff:"0",
            monthsIncluded:[]
          },
          currentAffiliate:{
            affiliateName:"",
            affiliateIdentifier:"",
            affiliateURL:""
          },
          discountType:false,
          emailForm:false,
          checkEmail:false,
          viewDefaults:false
        },() => {
          this.getData()
        })
        break;
      case "8-2":
        this.setState({
          viewAllUsers:false,
          addUser:false,
          viewServiceSales:false,
          viewHardwareSales:false,
          removeUser:false,
          viewRouter:false,
          viewDiscounts:false,
          viewAffiliates:false,
          addRouter:false,
          removeRouter:false,
          viewMessages:false,
          numRecords:20,
          page:0,
          searchUsersText:"",
          currentUser:null,
          isUsersModalOpen: false,
          isNewUsersModalOpen:false,
          isDiscountModalOpen:false,
          isAffiliateModalOpen:true,
          newUser : {
            firstName:"",
            lastName:"",
            email:"",
            productMonths:12
          },
          currentDiscount:{
            code:"",
            startDate:null,
            endDate:null,
            percentageOff:"0",
            moneyOff:"0",
            monthsIncluded:[]
          },
          currentAffiliate:{
            affiliateName:"",
            affiliateIdentifier:"",
            affiliateURL:""
          },
          discountType:false,
          emailForm:false,
          checkEmail:false,
          viewDefaults:false
        },() => {
          this.getData()
        })
        break;
      default:

    }
  }

  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.getData = this.getData.bind(this);
    this.goNextPage = this.goNextPage.bind(this);
    this.goPreviousPage = this.goPreviousPage.bind(this);
    this.showCorrectTable = this.showCorrectTable.bind(this);
    this.returnCurrentUsers = this.returnCurrentUsers.bind(this);
    this.shouldAddPrevious = this.shouldAddPrevious.bind(this);
    this.shouldAddNext = this.shouldAddNext.bind(this);
    this.searchUsers = this.searchUsers.bind(this);
    this.searchMessages = this.searchMessages.bind(this);
    this.handleUserTextChange = this.handleUserTextChange.bind(this);
    this.currentUserForm = this.currentUserForm.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSaveUser = this.handleSaveUser.bind(this);
    this.handleMessagesTextChange = this.handleMessagesTextChange.bind(this);
    this.showSpinner = this.showSpinner.bind(this);
    this.resetRouters = this.resetRouters.bind(this);
    this.createNewUser = this.createNewUser.bind(this);
    this.createNewUserForm = this.createNewUserForm.bind(this);
    this.handleUserInputChange = this.handleUserInputChange.bind(this);
    this.returnCurrentDiscounts = this.returnCurrentDiscounts.bind(this);
    this.deleteThisDiscount = this.deleteThisDiscount.bind(this);
    this.deleteThisAffiliate = this.deleteThisAffiliate.bind(this);
    this.createNewDiscountForm = this.createNewDiscountForm.bind(this);
    this.createNewAffiliateForm = this.createNewAffiliateForm.bind(this);
    this.handleDiscountInputChange = this.handleDiscountInputChange.bind(this);
    this.handleAffiliateInputChange = this.handleAffiliateInputChange.bind(this);
    this.changeMonthlyPlans = this.changeMonthlyPlans.bind(this);
    this.changeDiscountType = this.changeDiscountType.bind(this);
    this.createDiscount = this.createDiscount.bind(this);
    this.createAffiliate = this.createAffiliate.bind(this);
    this.emailForm = this.emailForm.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.handleEmailChanged = this.handleEmailChanged.bind(this);
    this.cancelSendEmail = this.cancelSendEmail.bind(this);
    this.goOnSendIt = this.goOnSendIt.bind(this);
    this.updateDefaults = this.updateDefaults.bind(this);
    this.updateAppDefaults = this.updateAppDefaults.bind(this);
    this.handleDefaultsChange = this.handleDefaultsChange.bind(this);
    this.collectHardware = this.collectHardware.bind(this);
    this.collectSoftware = this.collectSoftware.bind(this);
    this.sendSalesEmail = this.sendSalesEmail.bind(this);
    this.makeid = this.makeid.bind(this);
  }

  makeid() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 8; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  collectHardware() {

  }

  collectSoftware() {

  }

  sendSalesEmail() {
    this.setState({
      isSubmitting:true
    },() => {
      this.props.sendSales()
    })
  }

  createNewUser() {
    //console.dir(this.state.newUser);
    if (this.state.newUser) {
      if (this.state.newUser.firstName.length <= 1) {
        this.notify(false,"You must supply a first name of more than one character")
        return
      } else if (this.state.newUser.lastName.length <= 1) {
        this.notify(false,"You must supply a last name of more than one character")
        return
      } else if (this.state.newUser.email.length <= 4) {
        this.notify(false,"You must supply an email")
        return
      } else if (!this.state.newUser.productMonths) {
        this.notify(false,"You must supply the number of months a user can be supplied")
        return
      }
    }
    this.setState({
      isSubmitting:true
    });
    this.props.createUser({user:this.state.newUser});
  }

  showSpinner() {
    if (this.state.isSubmitting) {
      return(
        <Loader fullPage loading text="Please wait..." textStyle={{color:"black"}} />
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    //console.log("Next props");
    //console.dir(nextProps);

    if (nextProps.state && nextProps.state.admin && nextProps.state.admin.defaultsUpdated === true) {
      this.setState({
        isSubmitting:false
      },() => {
        this.notify(true,"Defaults Updated");
        this.props.resetUserError()
        //this.props.getDefaults();
      });
      return
    } else if (nextProps.state && nextProps.state.admin && nextProps.state.admin.defaultsUpdatedError !== null ) {
      this.setState({
        isSubmitting:false
      },() => {
        this.notify(false,"Error updating defaults");
      });
      return
    }

    if (nextProps.state &&
      nextProps.state.defaults &&
      nextProps.state.defaults.length > 0 &&
      nextProps.state.defaults[0].deliveryTime &&
      nextProps.state.defaults[0].defaultShipping &&
      nextProps.state.defaults[0].nowtv1 &&
      nextProps.state.defaults[0].nowtv2 &&
      nextProps.state.defaults[0].annualPlanRouterCost &&
      nextProps.state.defaults[0].annualPlanServiceCost &&
      nextProps.state.defaults[0].shortTermPlanRouterCost &&
      nextProps.state.defaults[0].shortTermPlanServiceCost &&
      nextProps.state.defaults[0].midTermPlanRouterCost &&
      nextProps.state.defaults[0].midTermPlanServiceCost &&
      nextProps.state.defaults[0].monthlyPlanRouterCost &&
      nextProps.state.defaults[0].monthlyPlanServiceCost) {
      this.setState({
        nowtv1:nextProps.state.defaults[0].nowtv1,
        nowtv2:nextProps.state.defaults[0].nowtv2,
        nowtvStock:nextProps.state.defaults[0].nowtvStock,
        deliveryTime:nextProps.state.defaults[0].deliveryTime,
        defaultShipping:nextProps.state.defaults[0].defaultShipping,
        annualPlanRouterCost:nextProps.state.defaults[0].annualPlanRouterCost,
        annualPlanServiceCost:nextProps.state.defaults[0].annualPlanServiceCost,
        shortTermPlanRouterCost:nextProps.state.defaults[0].shortTermPlanRouterCost,
        shortTermPlanServiceCost:nextProps.state.defaults[0].shortTermPlanServiceCost,
        midTermPlanRouterCost:nextProps.state.defaults[0].midTermPlanRouterCost,
        midTermPlanServiceCost:nextProps.state.defaults[0].midTermPlanServiceCost,
        monthlyPlanRouterCost:nextProps.state.defaults[0].monthlyPlanRouterCost,
        monthlyPlanServiceCost:nextProps.state.defaults[0].monthlyPlanServiceCost
      });
    }
    if (nextProps.state && nextProps.state.admin && nextProps.state.admin.users) {
      this.setState( {
        isSubmitting:false,
        users:nextProps.state.admin.users,
        totalCount:nextProps.state.admin.totalCount
      })
    }
    if (nextProps.state && nextProps.state.admin && nextProps.state.admin.messages) {
      this.setState( {
        isSubmitting:false,
        messages:nextProps.state.admin.messages,
        totalCount:nextProps.state.admin.totalCount
      })
    }
    if (nextProps.state && nextProps.state.admin && nextProps.state.admin.user) {
      this.getData();
    }
    if (nextProps.state && nextProps.state.admin && nextProps.state.admin.resetRouters === true) {
      this.getData();
    }
    if (nextProps.state && nextProps.state.admin && nextProps.state.admin.createdUser) {
      this.setState( {
        isSubmitting:false,
        viewAllUsers:true,
        addUser:false,
        isNewUsersModalOpen:false
      },() => {
        this.notify(true,"User created, check your email for router instructions");
        this.getData();
      });
    }

    if (nextProps.state && nextProps.state.admin && nextProps.state.admin.createdUserError) {
      this.setState( {
        isSubmitting:false
      },() => {
        this.notify(false,"There was an error creating the user: " + nextProps.state.admin.createdUserError);
        this.props.resetUserError()
      })
    }
    if (nextProps.state && nextProps.state.admin && nextProps.state.admin.discounts) {
      this.setState( {
        discounts:nextProps.state.admin.discounts
      })
    }
    if (nextProps.state && nextProps.state.admin && nextProps.state.admin.affiliates) {
      this.setState( {
        affiliates:nextProps.state.admin.affiliates
      })
    }
    if (nextProps.state && nextProps.state.admin && nextProps.state.admin.createDiscountError) {
      this.setState( {
        isSubmitting:false
      },() => {
        this.notify(false,nextProps.state.admin.createDiscountError);
        this.props.resetUserError()
      });
    }
    if (nextProps.state && nextProps.state.admin && nextProps.state.admin.createAffilliateError) {
      this.setState( {
        isSubmitting:false
      },() => {
        this.notify(false,nextProps.state.admin.createAffilliateError);
        this.props.resetUserError()
      });
    }
    if (nextProps.state && nextProps.state.admin && nextProps.state.admin.deleteDiscountError) {
      this.setState( {
        isSubmitting:false
      },() => {
        this.notify(false,nextProps.state.admin.deleteDiscountError);
        this.props.resetUserError()
      });
    }
    if (nextProps.state && nextProps.state.admin && nextProps.state.admin.deleteAffiliateError) {
      this.setState( {
        isSubmitting:false
      },() => {
        this.notify(false,nextProps.state.admin.deleteAffiliateError);
        this.props.resetUserError()
      });
    }
    if (nextProps.state && nextProps.state.admin && nextProps.state.admin.sendEmailError) {
      this.setState( {
        isSubmitting:false,
        checkEmail:false
      },() => {
        this.notify(false,nextProps.state.admin.sendEmailError);
        this.props.resetUserError()
      });
      return
    }
    if (nextProps.state && nextProps.state.admin && nextProps.state.admin.sendEmailSuccess) {
      this.setState( {
        isSubmitting:false,
        checkEmail:false
      },() => {
        this.notify(true,"Your email has been sent to all users");
        this.refs.emailContent.value = ""
        this.props.resetUserError()
      });
    }
    if (nextProps.state && nextProps.state.admin && nextProps.state.admin.createdDiscount) {
      this.setState( {
        isSubmitting:false,
        viewDiscounts:true,
        isDiscountModalOpen:false
      },() => {
        this.notify(true,"Discount created successfully");
        this.props.resetUserError()
      });
    }
    if (nextProps.state && nextProps.state.admin && nextProps.state.admin.createdAffiliate) {
      this.setState( {
        isSubmitting:false,
        viewAffiliates:true,
        isAffiliateModalOpen:false
      },() => {
        this.notify(true,"Affiliate created successfully");
        this.props.resetUserError()
      });
    }
    if (nextProps.state && nextProps.state.admin && nextProps.state.admin.sentSale) {
      this.setState( {
        isSubmitting:false
      },() => {
        this.notify(true,"Sales Email Sent");
      });
    }
    if (nextProps.state && nextProps.state.admin && !nextProps.state.admin.sentSale) {
      if(nextProps.state.admin.sentSale === false ){
        this.setState( {
          isSubmitting:false
        },() => {
          this.notify(false,"Send Sales Email Failed");
          this.props.resetUserError()
        });
      } else if (nextProps.state.admin.sentSale === null) {
        return
      }
    }
  }

  componentDidMount() {
    this.getData();
    var code = this.makeid();
    var url = 'https://www.uktvrouter.com?aid='+code;
    this.setState({
      code:code,
      url:url
    });
  }

  handleSaveUser() {
    //console.dir(this.state.currentUser);
    this.props.saveUser({user:this.state.currentUser});
  }

  getData() {
    if (this.state.viewAllUsers === true) {
      this.setState({
        isSubmitting:true
      },() => {
        if (this.state.searchUsersText && this.state.searchUsersText.length > 0) {
          this.props.collectAllUsersSearch({skip:this.state.page,limit:this.state.numRecords,searchText:this.state.searchUsersText});
          this.props.collectDiscounts();
          this.props.collectAffiliates();
        } else {
          this.props.collectAllUsers({skip:this.state.page,limit:this.state.numRecords});
          this.props.collectDiscounts();
          this.props.collectAffiliates();
        }
      })

    } else if (this.state.addUser === true) {

    } else if (this.state.removeUser === true) {

    } else if (this.state.viewRouter === true) {

    } else if (this.state.addRouter === true) {

    } else if (this.state.removeRouter === true) {

    } else if (this.state.viewMessages === true) {
      this.setState({
        isSubmitting:true
      },() => {
        if (this.state.searchMessagesText && this.state.searchMessagesText.length > 0) {
          this.props.collectAllMessagesSearch({skip:this.state.page,limit:this.state.numRecords,searchText:this.state.searchMessagesText});
        } else {
          this.props.collectAllMessages({skip:this.state.page,limit:this.state.numRecords});
        }
      });
    }
  }

  resetRouters() {
    this.setState({
      isSubmitting:true
    },() => {
      this.props.resetRouters();
    })
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    var user = this.state.currentUser
    //console.log(name);
    switch (name) {
      case "currentUser.firstName":
        user.firstName = target.value
        this.setState({
          currentUser: user
        });
        break;
      case "currentUser.lastName":
        user.lastName = target.value
        this.setState({
          currentUser: user
        });
        break;
      case "currentUser.username":
        user.username = target.value
        this.setState({
          currentUser: user
        });
        break;
      case "currentUser.password":
        user.password = target.value
        this.setState({
          currentUser: user
        });
        break;
      case "currentUser.email":
        user.email = target.value
        this.setState({
          currentUser: user
        });
        break;
      default:

    }
  }

  handleUserInputChange(event) {
    const target = event.target;
    const name = target.name;
    var user = this.state.newUser
    //console.log(name);
    switch (name) {
      case "newUser.firstName":
        user.firstName = target.value
        this.setState({
          newUser: user
        });
        break;
      case "newUser.lastName":
        user.lastName = target.value
        this.setState({
          newUser: user
        });
        break;
      case "newUser.email":
        user.email = target.value
        this.setState({
          newUser: user
        });
        break;
      case "newUser.productMonths":
        user.productMonths = target.value
        this.setState({
          newUser: user
        });
        break;
      default:

    }
  }

  handleDiscountInputChange(event) {
    const target = event.target;
    const name = target.name;
    var discount = this.state.currentDiscount
    //console.log(name);
    //console.log(target.value);
    switch (name) {
      case "discount.code":
        discount.code = target.value
        this.setState({
          currentDiscount: discount
        });
        break;
      case "discount.startDate":
        discount.startDate = target.value
        this.setState({
          currentDiscount: discount
        });
        break;
      case "discount.endDate":
        discount.endDate = target.value
        this.setState({
          currentDiscount: discount
        });
        break;
      case "discount.moneyOff":
        discount.moneyOff = target.value
        this.setState({
          currentDiscount: discount
        });
        break;
      case "discount.percentageOff":
        discount.percentageOff = target.value
        this.setState({
          currentDiscount: discount
        });
        break;
      case "discount.monthsIncluded":
        discount.monthsIncluded = target.value
        this.setState({
          currentDiscount: discount
        });
        break;
      default:
      //console.log("Default");
    }
  }

  handleAffiliateInputChange(event) {
    const target = event.target;
    const name = target.name;
    var affiliate = this.state.currentAffiliate;
    console.log(name);
    console.log(target.value);
    switch (name) {
      case "affiliate:affiliateName":
        affiliate.affiliateName = target.value
        affiliate.affiliateIdentifier = this.state.code
        affiliate.affiliateURL = this.state.url
        this.setState({
          currentAffiliate: affiliate
        });
        break;
      default:
        console.log("Default");
    }
  }

  currentStartDateChanged(value) {
    //console.log(value);
    var discount = this.state.currentDiscount
    discount.startDate = value
    this.setState({
      currentDiscount: discount
    });
  }

  currentEndDateChanged(value) {
    //console.log(value);
    var discount = this.state.currentDiscount
    discount.endDate = value
    this.setState({
      currentDiscount: discount
    });
  }

  currentDateChanged(value) {
    //console.log(value);
    if (this.state.currentUser && this.state.currentUser.expires) {
      var user = this.state.currentUser
      user.expires = value
      this.setState({
        currentUser: user
      });
    }
  }

  handleDefaultsChange(event) {
    const target = event.target;
    const name = target.name;
    //console.log(name);
    //console.log(target.value);
    switch (name) {
      case "defaults.deliveryTime":
        this.setState({
          deliveryTime:target.value
        })
        break;
      case "defaults.defaultShipping":
        this.setState({
          defaultShipping:target.value
        })
        break;
      case "defaults.annualPlanRouterCost":
        this.setState({
          annualPlanRouterCost:target.value
        })
        break;
      case "defaults.annualPlanServiceCost":
        this.setState({
          annualPlanServiceCost:target.value
        })
        break;
      case "defaults.shortTermPlanRouterCost":
        this.setState({
          shortTermPlanRouterCost:target.value
        })
        break;
      case "defaults.shortTermPlanServiceCost":
        this.setState({
          shortTermPlanServiceCost:target.value
        })
        break;
      case "defaults.midTermPlanRouterCost":
        this.setState({
          midTermPlanRouterCost:target.value
        })
        break;
      case "defaults.midTermPlanServiceCost":
        this.setState({
          midTermPlanServiceCost:target.value
        })
        break;
      case "defaults.monthlyPlanRouterCost":
        this.setState({
          monthlyPlanRouterCost:target.value
        })
        break;
      case "defaults.nowtv1":
        this.setState({
          nowtv1:target.value
        })
        break;
      case "defaults.nowtv2":
        this.setState({
          nowtv2:target.value
        })
        break;
      case "defaults.nowtvStock":
        this.setState({
          nowtvStock:target.checked
        })
        break;
      default:
    }
  }

  updateAppDefaults(event) {
    if (this.state.deliveryTime &&
      this.props.defaults._id &&
      this.state.deliveryTime &&
      this.state.annualPlanServiceCost &&
      this.state.annualPlanRouterCost &&
      this.state.shortTermPlanRouterCost &&
      this.state.shortTermPlanServiceCost &&
      this.state.midTermPlanRouterCost &&
      this.state.midTermPlanServiceCost &&
      this.state.nowtv1 &&
      this.state.nowtv2 &&
      this.state.monthlyPlanRouterCost) {
      this.setState({
        isSubmitting:true
      },() => {
        this.props.updateAppDefaults({defaults:{
          id:this.props.defaults._id,
          deliveryTime:this.state.deliveryTime,
          defaultShipping:this.state.defaultShipping,
          annualPlanServiceCost:this.state.annualPlanServiceCost,
          annualPlanRouterCost:this.state.annualPlanRouterCost,
          shortTermPlanRouterCost:this.state.shortTermPlanRouterCost,
          shortTermPlanServiceCost:this.state.shortTermPlanServiceCost,
          midTermPlanRouterCost:this.state.midTermPlanRouterCost,
          midTermPlanServiceCost:this.state.midTermPlanServiceCost,
          monthlyPlanRouterCost:this.state.monthlyPlanRouterCost,
          nowtv1:this.state.nowtv1,
          nowtv2:this.state.nowtv2,
          nowtvStock:this.state.nowtvStock}
        })
      })
    } else {
      console.log("Settings Incorrect");
    }
  }

  updateDefaults() {
    if (this.state && this.state.deliveryTime && this.state.defaultShipping && this.state.viewDefaults ) {
      return (
        <form>
          <br/>
          <div className="row">
            <div className="col-md-6 col-sm-6 mt-0">
              <label htmlFor="newUser:firstName">Delivery Time</label>
              <input className="form-control" type="text" id="defaults.deliveryTime" name="defaults.deliveryTime" value={this.state.deliveryTime} onChange={this.handleDefaultsChange} required={true} autoComplete="false"/>
            </div>
            <div className="col-md-6 col-sm-6 mt-0">
              <label htmlFor="newUser:firstName">Default Shipping</label>
              <input className="form-control" type="text" id="defaults.defaultShipping" name="defaults.defaultShipping" value={this.state.defaultShipping} onChange={this.handleDefaultsChange} required={true} autoComplete="false"/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 mt-0">
              <label htmlFor="newUser:firstName">Annual Plan Router Cost</label>
              <input className="form-control" type="text" id="defaults.annualPlanRouterCost" name="defaults.annualPlanRouterCost" value={this.state.annualPlanRouterCost} onChange={this.handleDefaultsChange} required={true} autoComplete="false"/>
            </div>
            <div className="col-md-6 col-sm-6 mt-0">
              <label htmlFor="newUser:firstName">Annual Plan Service Cost</label>
              <input className="form-control" type="text" id="defaults.annualPlanServiceCost" name="defaults.annualPlanServiceCost" value={this.state.annualPlanServiceCost} onChange={this.handleDefaultsChange} required={true} autoComplete="false"/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 mt-0">
              <label htmlFor="newUser:firstName">6 Month Plan Router Cost</label>
              <input className="form-control" type="text" id="defaults.midTermPlanRouterCost" name="defaults.midTermPlanRouterCost" value={this.state.midTermPlanRouterCost} onChange={this.handleDefaultsChange} required={true} autoComplete="false"/>
            </div>
            <div className="col-md-6 col-sm-6 mt-0">
              <label htmlFor="newUser:firstName">6 Month Plan Service Cost</label>
              <input className="form-control" type="text" id="defaults.midTermPlanServiceCost" name="defaults.midTermPlanServiceCost" value={this.state.midTermPlanServiceCost} onChange={this.handleDefaultsChange} required={true} autoComplete="false"/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 mt-0">
              <label htmlFor="newUser:firstName">3 Month Plan Router Cost</label>
              <input className="form-control" type="text" id="defaults.shortTermPlanRouterCost" name="defaults.shortTermPlanRouterCost" value={this.state.shortTermPlanRouterCost} onChange={this.handleDefaultsChange} required={true} autoComplete="false"/>
            </div>
            <div className="col-md-6 col-sm-6 mt-0">
              <label htmlFor="newUser:firstName">3 Month Plan Service Cost</label>
              <input className="form-control" type="text" id="defaults.shortTermPlanServiceCost" name="defaults.shortTermPlanServiceCost" value={this.state.shortTermPlanServiceCost} onChange={this.handleDefaultsChange} required={true} autoComplete="false"/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 mt-0">
              <label htmlFor="newUser:firstName">Monthly Plan Router Cost</label>
              <input className="form-control" type="text" id="defaults.monthlyPlanRouterCost" name="defaults.monthlyPlanRouterCost" value={this.state.monthlyPlanRouterCost} onChange={this.handleDefaultsChange} required={true} autoComplete="false"/>
            </div>
            <div className="col-md-6 col-sm-6 mt-0">
              <label htmlFor="newUser:firstName">Monthly Plan Service Cost</label>
              <input className="form-control" type="text" id="defaults.monthlyPlanServiceCost" readOnly={true} name="defaults.monthlyPlanServiceCost" defaultValue={this.state.monthlyPlanServiceCost}  required={true} autoComplete="false"/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-4 mt-0">
              <label htmlFor="newUser:firstName">Roku Cost x 1</label>
              <input className="form-control" type="text" id="defaults.nowtv1" name="defaults.nowtv1" value={this.state.nowtv1} onChange={this.handleDefaultsChange} required={true} autoComplete="false"/>
            </div>
            <div className="col-md-6 col-sm-4 mt-0">
              <label htmlFor="newUser:firstName">Roku Cost x 2</label>
              <input className="form-control" type="text" id="defaults.nowtv2" name="defaults.nowtv2" value={this.state.nowtv2} onChange={this.handleDefaultsChange} required={true} autoComplete="false"/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <label className="checkbox float-left">
              <input type="checkbox" id="defaults.nowtvStock" name="defaults.nowtvStock" checked={this.state.nowtvStock} onChange={this.handleDefaultsChange}/>
              <i></i> <span className="fw-300">Roku Premiere In Stock?</span>
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <button type="button" className="btn btn-primary" name="updateDefaults" id="updateDefaults" onClick={this.updateAppDefaults}>Update Defaults</button>
            </div>
          </div>
        </form>
      );
    } else {
      return (
        <div/>
      );
    }
  }

  createNewUserForm() {
    if (this.state.isNewUsersModalOpen) {
      return (
        <form>
          <br/>
          <div className="row">
            <div className="col-md-6 col-sm-6 mt-0">
              <label htmlFor="newUser:firstName">First Name</label>
              <input className="form-control" type="text" id="newUser.firstName" name="newUser.firstName" value={this.state.newUser.firstName} onChange={this.handleUserInputChange} required={true} autoComplete="false"/>
            </div>
            <div className="col-md-6 col-sm-6 mt-0">
              <label htmlFor="newUser:lastName">Last Name</label>
              <input className="form-control" type="text" id="newUser.lastName" name="newUser.lastName" value={this.state.newUser.lastName} onChange={this.handleUserInputChange} required={true} autoComplete="false"/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <label htmlFor="newUser:email">Email</label>
              <input className="form-control" type="text" id="newUser.email" name="newUser.email" value={this.state.newUser.email} onChange={this.handleUserInputChange} required={true} autoComplete="false"/>
            </div>
            <div className="col-md-6 col-sm-6">
                <label style={{color:"#505556"}}>Select Product Months</label>
                <div className="fancy-form fancy-form-select">
                	<select className="form-control" id="newUser.productMonths" name="newUser.productMonths" value={this.state.newUser.productMonths} onChange={this.handleUserInputChange}>
                		<option value="">--- Select Number of Months ---</option>
                		<option value="1">1</option>
                		<option value="3">3</option>
                		<option value="6">6</option>
                    <option value="12">12</option>
                	</select>
                  <i className="fancy-arrow"></i>
                </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <button type="button" className="btn btn-primary" name="createUser" id="createUser" onClick={this.createNewUser}>Create User</button>
            </div>
          </div>
        </form>
      );
    } else {
      return (
        <div/>
      );
    }
  }

  changeMonthlyPlans(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    var plans = this.state.currentDiscount.monthsIncluded
    switch (name) {
      case "one":
        //console.log(value);
        if (contains.call(plans, 1) && value === false) {
          var index1 = plans.indexOf(1);
          if (index1 > -1) {
            plans.splice(index1, 1);
          }
        } else if (!contains.call(plans, 1) && value === true) {
          plans.push(1);
        }
        //console.log(plans);
        break;
      case "three":
        //console.log(value);
        if (contains.call(plans, 3) && value === false) {
          var index3 = plans.indexOf(3);
          if (index3 > -1) {
            plans.splice(index3, 1);
          }
        } else if (!contains.call(plans, 3) && value === true) {
          plans.push(3);
        }
        //console.log(plans);
        break;
      case "six":
        //console.log(value);
        if (contains.call(plans, 6) && value === false) {
          var index6 = plans.indexOf(6);
          if (index6 > -1) {
            plans.splice(index6, 1);
          }
        } else if (!contains.call(plans, 6) && value === true) {
          plans.push(6);
        }
        //console.log(plans);
        break;
      case "twelve":
        //console.log(value);
        if (contains.call(plans, 12) && value === false) {
          var index12 = plans.indexOf(12);
          if (index12 > -1) {
            plans.splice(index12, 1);
          }
        } else if (!contains.call(plans, 12) && value === true) {
          plans.push(12);
        }
        //console.log(plans);
        break;
      default:

    }
  }

  changeDiscountType(event) {
    const target = event.target;
    const name = target.name;
    //const value = target.type === 'checkbox' ? target.checked : target.value;
    //console.log(value);
    //console.log(name);
    switch (name) {
      case "value":
        this.setState({
          discountType:true
        })
        break;
      case "perentage":
        this.setState({
          discountType:false
        })
        break;
      default:
    }
  }

  createDiscount() {
    if (this.state.currentDiscount.code.length === 0) {
      this.notify(false,"You must create a unique code for the discount");
      return
    } else if (this.state.currentDiscount.monthsIncluded.length === 0) {
      this.notify(false,"You must apply at least one plan to the discount");
      return
    } else if (this.state.currentDiscount.startDate === null) {
      this.notify(false,"You must have a start date for the discount");
      return
    } else if (this.state.currentDiscount.endDate === null) {
      this.notify(false,"You must have an end date for the discount");
      return
    } else if (this.state.currentDiscount.endDate < this.state.currentDiscount.startDate) {
      this.notify(false,"Your start date must be before your end date");
      return
    } else if (this.state.currentDiscount.endDate < new Date()) {
      this.notify(false,"Your end date must be after today");
      return
    } else if (this.state.discountType && parseInt(this.state.currentDiscount.moneyOff,10) === 0) {
      this.notify(false,"Your € value discount must be greater than 0");
      return
    } else if (!this.state.discountType && parseInt(this.state.currentDiscount.percentageOff,10) === 0) {
      this.notify(false,"Your % discount must be greater than 0");
      return
    }
    var discount = this.state.currentDiscount
    if(this.state.discountType) {
      discount.percentageOff = null
      this.setState({
        currentDiscount:discount,
        isSubmitting:true
      },() => {
        //console.log(this.state.currentDiscount);
        this.props.createNewDiscount({discount:discount})
      })
    } else {
      discount.moneyOff = null
      this.setState({
        currentDiscount:discount,
        isSubmitting:true
      },() => {
        //console.log(this.state.currentDiscount);
        this.props.createNewDiscount({discount:discount})
      })
    }
  }

  createAffiliate() {
    if (this.state.currentAffiliate.affiliateName.length === 0) {
      this.notify(false,"You must create a unique name for the Affiliate");
      return
    } else if (this.state.currentAffiliate.affiliateIdentifier.length === 0) {
      this.notify(false,"You must create a code for this affiliate");
      return
    } else if (this.state.currentAffiliate.affiliateURL.length === 0) {
      this.notify(false,"You must create a URL for this affiliate");
      return
    }
    var affiliate = this.state.currentAffiliate;
    this.setState({
      currentAffiliate:affiliate,
      isSubmitting:true
    },() => {
      //console.log(this.state.currentDiscount);
      this.props.createNewAffiliate({affiliate:affiliate});
      var code = this.makeid();
      var url = 'https://www.uktvrouter.com?aid='+code;
      this.setState({
        code:code,
        url:url
      });
    })
  }

  createNewAffiliateForm() {
    if (this.state.isAffiliateModalOpen) {
      return (
        <form className="fancy-form">
          <br/>
          <div className="row">
            <div className="col-md-6 col-sm-6 mt-0">
              <label htmlFor="affiliate:affiliateName">Affiliate Name (Must Be Unique)</label>
              <input className="form-control" type="text" id="affiliate:affiliateName" name="affiliate:affiliateName" value={this.state.currentAffiliate.affiliateName} onChange={this.handleAffiliateInputChange} required={true} autoComplete="false"/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-3">
                <label style={{color:"#505556"}}>Affiliate Code</label>
                <input className="form-control" type="text" id="affiliate:affiliateIdentifier"  ref={(affiliateIdentifier) => this.affiliateIdentifier = affiliateIdentifier} name="affiliate:affiliateIdentifier" value={this.state.code} onChange={this.handleAffiliateInputChange} readOnly={true} required={true} autoComplete="false"/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6">
                <label style={{color:"#505556"}}>Affiliate URL</label>
                <input className="form-control" type="text" id="affiliate:affiliateURL" ref={(affiliateURL) => this.affiliateURL = affiliateURL} name="affiliate:affiliateURL" value={this.state.url} onChange={this.handleAffiliateInputChange} required={true} readOnly={true} autoComplete="false"/>
            </div>
          </div>
          <div className="row">
          </div>
          <div className="row">
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <button type="button" className="btn btn-primary" name="createAffiliate" id="createAffiliate" onClick={this.createAffiliate}>Create Affiliate</button>
            </div>
          </div>
        </form>
      );
    } else {
      return (
        <div/>
      );
    }
  }

  createNewDiscountForm() {
    if (this.state.isDiscountModalOpen) {
      var discount = this.state.currentDiscount
      var currentStartDate = new Date(this.state.currentDiscount.startDate)
      if (this.state.currentDiscount.startDate === null) {
        currentStartDate = new Date()
        discount.startDate = currentStartDate
        this.setState({
          currentDiscount: discount
        });
      }
      var currentEndDate = new Date(this.state.currentDiscount.endDate)
      if (this.state.currentDiscount.endDate === null) {
        //console.log("setting date");
        currentEndDate = new Date()
        currentEndDate.setMonth(currentEndDate.getMonth() + 1)
        discount.endDate = currentEndDate
        this.setState({
          currentDiscount: discount
        });
      }
      //console.log(currentStartDate);
      //console.log(currentEndDate);
      return (
        <form className="fancy-form">
          <br/>
          <div className="row">
            <div className="col-md-3 col-sm-3 mt-0">
              <label htmlFor="discount:code">Discount Code (Must Be Unique)</label>
              <input className="form-control" type="text" id="discount.code" name="discount.code" value={this.state.currentDiscount.code} onChange={this.handleDiscountInputChange} required={true} autoComplete="false"/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-3">
              <label htmlFor="newUser">Choose Plans in this Discount</label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2 col-sm-2">
              <label className="checkbox float-left">
                <input id="one" name="one" type="checkbox" onChange={this.changeMonthlyPlans} />
                <i></i> <span className="fw-300">Monthly Plan</span>
              </label>
            </div>
            <div className="col-md-2 col-sm-2">
              <label className="checkbox float-left">
                <input id="three" name="three" type="checkbox" onChange={this.changeMonthlyPlans} />
                <i></i> <span className="fw-300">3 Month Plan</span>
              </label>
            </div>
            <div className="col-md-2 col-sm-2">
              <label className="checkbox float-left">
                <input id="six" name="six" type="checkbox" onChange={this.changeMonthlyPlans}  />
                <i></i> <span className="fw-300">6 Month Plan</span>
              </label>
            </div>
            <div className="col-md-2 col-sm-2">
              <label className="checkbox float-left">
                <input id="twelve" name="twelve" type="checkbox" onChange={this.changeMonthlyPlans} />
                <i></i> <span className="fw-300">12 Month Plan</span>
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-3">
                <label style={{color:"#505556"}}>Start Date</label>
                <DateTimePicker
                  onCurrentDateChange={this.currentStartDateChanged.bind(this)}
                  currentDate={currentStartDate}
                  value={currentStartDate}
                  onChange={this.currentStartDateChanged.bind(this)}
                  time={false}
                  format='DD MMM YYYY'
                  dropUp={false}
                  placeholder="Enter Start Date"
                />
            </div>
            <div className="col-md-3 col-sm-3">
                <label style={{color:"#505556"}}>End Date</label>
                <DateTimePicker
                  onCurrentDateChange={this.currentEndDateChanged.bind(this)}
                  currentDate={currentEndDate}
                  value={currentEndDate}
                  onChange={this.currentEndDateChanged.bind(this)}
                  time={false}
                  format='DD MMM YYYY'
                  dropUp={true}
                  placeholder="Enter End Date"
                />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-3">
              <label className="radio">
              	<input type="radio" name="perentage" value="1" checked={!this.state.discountType} onChange={this.changeDiscountType}/>
              	<i></i> Percentage
              </label>
              <label className="radio">
                <input type="radio" name="value" value="2" checked={this.state.discountType} onChange={this.changeDiscountType}/>
                <i></i> Value
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-3 mt-0">
              <label htmlFor={this.state.discountType ? "discount:moneyOff" : "discount:percentageOff"}>Amount</label>
              {this.state.discountType ? <input className="form-control" type="text" id="discount.moneyOff" name="discount.moneyOff" value={this.state.currentDiscount.moneyOff} onChange={this.handleDiscountInputChange} required={true} autoComplete="false"/> : <input className="form-control" type="text" id="discount.percentageOff" name="discount.percentageOff" value={this.state.currentDiscount.percentageOff} onChange={this.handleDiscountInputChange} required={true} autoComplete="false"/>}
            </div>
            <div className="col-md-1 col-sm-1 mt-40 ml-0">
              <i className={this.state.discountType ? "fa fa-euro" : "fa fa-percent"}></i>
            </div>
          </div>
          <div className="row">
          </div>
          <div className="row">
          </div>
          <div className="row">
          </div>
          <div className="row">
          </div>
          <div className="row">
          </div>
          <div className="row">
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <button type="button" className="btn btn-primary" name="createDiscount" id="createDiscount" onClick={this.createDiscount}>Create Discount</button>
            </div>
          </div>
        </form>
      );
    } else {
      return (
        <div/>
      );
    }
  }

  currentUserForm() {
    if (this.state.currentUser) {
      var currentDate = new Date(this.state.currentUser.expires)
      //console.log(currentDate);
      return (
        <form>
          <br/>
          <div className="row">
            <div className="col-md-6 col-sm-6 mt-0">
              <label htmlFor="account:firstName">First Name</label>
              <input className="form-control" type="text" id="currentUser.firstName" name="currentUser.firstName" value={this.state.currentUser.firstName} onChange={this.handleInputChange} required={true} autoComplete="false"/>
            </div>
            <div className="col-md-6 col-sm-6 mt-0">
              <label htmlFor="currentUser:lastName">Last Name</label>
              <input className="form-control" type="text" id="currentUser.lastName" name="currentUser.lastName" value={this.state.currentUser.lastName} onChange={this.handleInputChange} required={true} autoComplete="false"/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <label htmlFor="currentUser:username">Username</label>
              <label>{this.state.currentUser.username}</label>
            </div>
            <div className="col-md-6 col-sm-6">
              <label htmlFor="currentUser:password">Password</label>
              <label>{this.state.currentUser.password}</label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <label htmlFor="currentUser:email">Email</label>
              <input className="form-control" type="text" id="currentUser.email" name="currentUser.email" value={this.state.currentUser.email} onChange={this.handleInputChange} required={true} autoComplete="false"/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12">
                <label style={{color:"#505556"}}>Expires</label>
                <DateTimePicker
                  onCurrentDateChange={this.currentDateChanged.bind(this)}
                  currentDate={currentDate}
                  value={currentDate}
                  onChange={this.currentDateChanged.bind(this)}
                  time={false}
                  format='DD MMM YYYY'
                  dropUp={true}
                  placeholder="Enter Expiry Date"
                />
            </div>
          </div>
        </form>
      );
    } else {
      return (
        <span>Loading..</span>
      );
    }
  }

  sendEmail(){
    if (this.state.emailContent.length > 0) {
      this.setState({
        checkEmail:true
      })
    }
  }

  cancelSendEmail() {
    this.setState({
      checkEmail:false
    })
  }

  goOnSendIt() {
    this.setState({
      isSubmitting:true
    })
    this.props.sendEmail({content:this.state.emailContent});
  }

  handleEmailChanged(event){
    const target = event.target;
    this.setState({
      emailContent:target.value
    })
  }

  emailForm(){
    if(this.state.emailForm) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 mt-0">
              <label htmlFor="send:email">Enter Email Content <span style={{color:'red'}}>(WARNING: THIS GOES TO EVERY CURRENT USER)</span></label>
              <textarea className="summernote form-control" ref="emailContent" onChange={this.handleEmailChanged} style={{height:300}} data-lang="en-GB"></textarea>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12">
            </div>
          </div>



          {this.state.checkEmail ? <div className="alert alert-danger mb-30">
                                    	<button type="button" className="close" data-dismiss="alert"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>

                                    	<h4>Send <strong>Email to everyone!!</strong></h4>

                                    	<p>
                                    		Are you absolutely sure you want to send this content to every user?
                                        <br/>
                                        <br/>
                                        There is no going back from here
                                    	</p>
                                      <div className="row">
                                        <div className="col-md-3 col-sm-3 mt-0">
                                          <button type="button" className="btn btn-secondary" name="cancelEmail" id="cancelEmail" onClick={this.cancelSendEmail}>Cancel</button>
                                        </div>
                                        <div className="col-md-3 col-sm-3 mt-0">
                                          <button type="button" className="btn btn-primary" name="sendEmail" id="sendEmail" onClick={this.goOnSendIt}>Send It!</button>
                                        </div>
                                      </div>
                                    </div> : <div className="row">
                                      <div className="col-md-12 col-sm-12">
                                        <br/>
                                        <button type="button" className="btn btn-primary" name="sendMail" id="sendMail" onClick={this.sendEmail}>Send email</button>
                                      </div>
                                    </div>
                                }
        </div>

      )
    }
  }

  render() {
    var commonMenu = (
      <Menu onSelect={this.handleSelect}>
        <SubMenu title={<span>Users</span>} key="1">
          <MenuItem key="1-1">View All Users</MenuItem>
          <MenuItem key="1-2">Create User</MenuItem>
        </SubMenu>
        <SubMenu title={<span>Messages</span>} key="3">
          <MenuItem key="3-1">View Messages</MenuItem>
        </SubMenu>
        <SubMenu title={<span>Discounts</span>} key="4">
          <MenuItem key="4-1">View Discounts</MenuItem>
          <MenuItem key="4-2">Create Discount</MenuItem>
        </SubMenu>
        <SubMenu title={<span>Email</span>} key="5">
          <MenuItem key="5-1">Email All Users</MenuItem>
        </SubMenu>
        <SubMenu title={<span>Defaults</span>} key="6">
          <MenuItem key="6-1">Update Defaults</MenuItem>
        </SubMenu>
        <SubMenu title={<span>Sales</span>} key="7">
          <MenuItem key="7-1">Hardware Sales</MenuItem>
          <MenuItem key="7-2">Service Sales</MenuItem>
        </SubMenu>
        <SubMenu title={<span>Affiliates</span>} key="8">
          <MenuItem key="8-1">View Affiliates</MenuItem>
          <MenuItem key="8-2">Create Affiliate</MenuItem>
        </SubMenu>
      </Menu>
    );


    var horizontalMenu = React.cloneElement(commonMenu, {
      mode: 'horizontal',
      motion: animation,
    });

    return (
      <div>
      <Header/>
      <section id="admin">
      <ToastContainer/>
      {this.showSpinner()}
      <div className="modal fade" id="termsModal" tabIndex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
              <h4 className="modal-title" id="myModal">Update User</h4>
							<button type="button" className="close" data-dismiss="modal" aria-hidden="true">
								&times;
							</button>
						</div>
						<div className="modal-body modal-short">
              {this.currentUserForm()}
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
							<button type="button" className="btn btn-primary" data-dismiss="modal" name="termsAgree" id="termsAgree" onClick={this.handleSaveUser}>Save</button>
						</div>
					</div>
				</div>
			</div>
      <div className="container">
        <header className="text-right mb-60">
          <button type="button" className="btn btn-success text-left mr-100" onClick={this.sendSalesEmail}>Send Sales Email</button>
          <button type="button" className="btn btn-danger" onClick={this.resetRouters}>Reset Routers</button>
        </header>
        <div>{horizontalMenu}</div>
        <br/>
        {this.showCorrectTable()}
        {this.createNewUserForm()}
        {this.createNewDiscountForm()}
        {this.createNewAffiliateForm()}
        {this.emailForm()}
        {this.updateDefaults()}
      </div>
		</section>
    <Footer/>
    </div>
  )}

}

function mapStateToProps(state) {
  //console.log("HEEEEELOOOOOO");
  //console.dir(state)
  return {
    state:state,
    defaults : state.defaults[0]
  };
}

export default connect(mapStateToProps,actions)(withRouter(Admin));
