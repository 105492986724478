
import React, {Component} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {connect} from 'react-redux';
import * as actions from '../actions';
import { withRouter } from 'react-router-dom';
import {bindActionCreators} from 'redux';
import { Accordion, AccordionItem } from 'react-sanfona';
import Header from './Header'
import Footer from './Footer'

class Faq extends Component {

  toastId = 1234;
  toastIdSuccess = 3456;

  notify = (success, value) => {
    if (success) {
      if (! toast.isActive(this.toastIdSuccess)) {
              this.toastId = toast.success(value, {
              position: toast.POSITION.BOTTOM_CENTER
          });
      }
    } else {
      if (! toast.isActive(this.toastId)) {
              this.toastId = toast.error(value, {
              position: toast.POSITION.BOTTOM_CENTER
          });
      }
    }

  }

  constructor(props){
    super(props);
    this.handleQuestion = this.handleQuestion.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentWillReceiveProps = (nextProps)=> {
    //console.dir(nextProps);
    if(nextProps) {
      if (nextProps && nextProps.askedQuestion && nextProps.askedAnswer) {
        this.notify(true,nextProps.askedAnswer);
        this.refs.questionForm.reset();
        this.props.resetQuestions();
      } else if (nextProps && !nextProps.askedQuestion && nextProps.askedAnswer) {
        //console.log("error");
        this.notify(false,nextProps.askedAnswer);
        this.props.resetQuestions();
      }
    }
    this.props.resetQuestions();
  }

  handleQuestion(values) {
    values.preventDefault()
    //console.log("Question");
    var errors = "";
    if (!this.state) {
      errors += 'Please complete the form\n'
      this.notify(false,errors);
      return;
    }
    if (!this.state.email) {
      errors += 'An email is Required\n'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)) {
      errors += 'Invalid email address\n'
    }
    if (!this.state.name) {
      errors += 'Your name is required\n'
    }
    if (!this.state.question) {
      errors += 'A question is required\n'
    }

    if (errors.length > 0) {
      this.notify(false,errors);
      return;
    }
    this.props.askQuestion({email:this.state.email,name:this.state.name,question:this.state.question},this.props.history);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  render() {
    const styles = {
        borderRadius: "5px",
        border: "0.5px solid #CCCCCC",
        padding: "5px",
        marginBottom:"5px",
        backgroundColor:"#FAFAFA"
    }
    return (
      <div>
      <Header/>
      <section>

        <div className="container">

          <div className="row mix-grid">


            <div className="col-md-12">

              <h2>Frequently Asked Questions</h2>
              <Accordion openNextAccordionItem={false}>

                <AccordionItem title={"1. What Channels can I watch live?"} style={styles}  expanded={false}>
                  <div className="toggle-content">
                    <p className="clearfix">
                      BBC1, BBC2, BBC3, BBC4, CBBC, CBeebies, News, Parliament, Alba, S4C, ITV1, ITV2, ITVBe, ITV3, ITV4, CITV.
                      <br/>
                      Via: <a href="http://www.tvguide.co.uk/" target="_blank" rel="noopener noreferrer" style={{color:"blue"}}>TV Guide</a> Channel 4, 4seven, Five. All your Sky channels are available on the SkyGo App on your phone or tablet/iPad but will not transfer to TV via mirroring. The only way to use your SkyGo on the TV screen is with a PC and a browser with Silverlight (with Microsoft Explorer) then connect with an HD lead.
                    </p>

                  </div>
                </AccordionItem>

                <AccordionItem title={"2. Can I watch Catch-Up TV?"} style={styles} expanded={false}>
                  <div className="toggle-content">
                    <p className="clearfix">
                      Yes, all the catch-up services are available plus you can use UK Netflix and Amazon Prime.
                    </p>
                  </div>
                </AccordionItem>

                <AccordionItem title={"3. Do I need an aerial or satellite dish?"}  style={styles} expanded={false}>
                  <div className="toggle-content">
                    <p className="clearfix">
                      A TV aerial is never required but you will need a Satellite dish if you are going to connect a Sky+ HD digibox or a Freesat box with Freetime.
                    </p>
                  </div>
                </AccordionItem>

                <AccordionItem title={"4. Can I use NOW TV or a NOW TV stick?"}  style={styles}  expanded={false}>
                  <div className="toggle-content">
                    <p className="clearfix">
                      Yes, you can easily get all the Sky content via a NOW TV box or a NOW TV stick. This also has the benefit of having most of the other Apps, BBC, ITV, etc preinstalled so you can use all from one remote control. The NOW TV stick is also a very cheap and effective option. Your smart TV may also have a NOW TV app available which will allow you to buy Sky content.
                    </p>
                  </div>
                </AccordionItem>

                <AccordionItem title={"5. What do I do if my TV is not smart?"}  style={styles}  expanded={false}>
                  <div className="toggle-content">
                    <p className="clearfix">
                      Easy, make sure you have an HDMI port available and buy either a NOW TV, stick or box. This will do everything apart from BT Sport for which you need a Chromecast, BT Sport app on your smartphone and BT account login.
                    </p>

                  </div>
                </AccordionItem>

                <AccordionItem title={"6. Do I need Internet?"}  style={styles}  expanded={false}>
                  <div className="toggle-content">
                    <p className="clearfix">
                      Yes, you must have an internet service with a router in your home.
                    </p>
                  </div>
                </AccordionItem>

                <AccordionItem title={"7. What internet speed do I need?"}  style={styles}  expanded={false}>
                  <div className="toggle-content">
                    <p className="clearfix">
                      Normally 2.5 Mbps will be sufficient for SD TV and 3.5 Mbps for HD. Most of the apps you use like BBC iPlayer, Netflix, ITV Hub etc are intelligent and adjust the quality automatically to ensure a smooth uninterrupted viewing experience.
                    </p>
                  </div>
                </AccordionItem>

                <AccordionItem title={"8. How easy is it to install?"}  style={styles}  expanded={false}>
                  <div className="toggle-content">
                    <p className="clearfix">
                      Very simple, there is one ethernet cable which you connect to one of the LAN ports on your own household router then connect this to the WAN port on the UK TV pre-configured router, connect the power lead and switch on.
                    </p>
                  </div>
                </AccordionItem>

                <AccordionItem title={"9. How do I connect my devices?"}  style={styles}  expanded={false}>
                  <div className="toggle-content">
                    <p className="clearfix">
                      You can use wifi, the network name and password are on the bottom of the UK TV router, we will also have emailed this to you when we receive your order. You can also connect with Ethernet cable to the 2 LAN ports on the UK TV router.
                    </p>
                  </div>
                </AccordionItem>

                <AccordionItem title={"10. How many devices can I connect?"}  style={styles}  expanded={false}>
                  <div className="toggle-content">
                    <p className="clearfix">
                      You can connect unlimited devices as long as you have sufficient bandwidth/internet speed to support them.
                    </p>
                  </div>
                </AccordionItem>

                <AccordionItem title={"11. Does the network have a UK IP?"}  style={styles}  expanded={false}>
                  <div className="toggle-content">
                    <p className="clearfix">
                      Your network will receive a fixed UK IP from our server.
                    </p>
                  </div>
                </AccordionItem>

                <AccordionItem title={"12. Will the router work with all ISPs (internet service providers)?"}  style={styles}  expanded={false}>
                  <div className="toggle-content">
                    <p className="clearfix">
                      Yes 99.8%. To quantify this, from 1000 routers sent out we may get 2 problems. This is because the ISP has firewall settings which can block connection to our server. The worst is Movistar in Spain but if you reboot the Movistar router 3 times it will then normally connect to our servers. In the highly unlikely event that you or our support team fail to get you connected we will refund the cost of the router and subscription on return of the goods and packaging in original condition.
                    </p>
                  </div>
                </AccordionItem>

                <AccordionItem title={"13. What are Apps?"}  style={styles}  expanded={false}>
                  <div className="toggle-content">
                    <p className="clearfix">
                      Apps are provided by the broadcasters for each type of device, so BBCiplayer, Netflix, ITV Hub, Sky Go, Amazon Prime video etc, provide apps which you download from the app store for your device. Then when the device is connected to the UK TV router network you can use the apps.
                    </p>
                  </div>
                </AccordionItem>

                <AccordionItem title={"14. Do I need to set up accounts for the Apps?"}  style={styles}  expanded={false}>
                  <div className="toggle-content">
                      Yes, most apps will ask you for email, password and UK postcode to create an account. You can use any UK postcode. You only need to do this once, most devices will then stay logged in.  Here is what mine looks like e.g:
                      <br/>
                      TV Accounts: click the links to create your accounts
                      <table className="table table-bordered">
                    		<thead>
                    			<tr>
                    				<th>Service</th>
                    				<th>Username</th>
                    				<th>Password</th>
                    			</tr>
                    		</thead>
                    		<tbody>
                    			<tr>
                    				<td><a href="https://account.bbc.com/register" target="_blank" rel="noopener noreferrer" style={{color:"blue"}}>BBC iPlayer</a></td>
                    				<td>xxxxxx@gmail.com</td>
                    				<td>TVuser@xxxY</td>
                    			</tr>
                    			<tr>
                    				<td><a href="https://www.itv.com/hub/user/signup" target="_blank" rel="noopener noreferrer" style={{color:"blue"}}>ITV Hub</a></td>
                    				<td>xxxxxx@gmail.com</td>
                    				<td>TVuser@xxxY</td>
                    			</tr>
                    			<tr>
                    				<td><a href="https://4id.channel4.com/registration" target="_blank" rel="noopener noreferrer" style={{color:"blue"}}>All 4</a></td>
                    				<td>xxxxxx@gmail.com</td>
                    				<td>TVuser@xxxY</td>
                    			</tr>
                    			<tr>
                    				<td><a href="https://www.my5.tv/" target="_blank" rel="noopener noreferrer" style={{color:"blue"}}>Demand 5</a></td>
                    				<td>xxxxxx@gmail.com</td>
                    				<td>TVuser@xxxY</td>
                    			</tr>
                    			<tr>
                    				<td><a href="https://www.netflix.com/gb/" target="_blank" rel="noopener noreferrer" style={{color:"blue"}}>Netflix</a></td>
                    				<td>xxxxxx@gmail.com</td>
                    				<td>TVuser@xxxY</td>
                    			</tr>
                    			<tr>
                    				<td><a href="https://www.amazon.co.uk/Prime-Video/b?ie=UTF8&node=3280626031" target="_blank" rel="noopener noreferrer" style={{color:"blue"}}>Amazon Prime Video</a></td>
                    				<td>xxxxxx@gmail.com</td>
                    				<td>TVuser@xxxY</td>
                    			</tr>
                          <tr>
                    				<td><a href="http://go.sky.com/" target="_blank" rel="noopener noreferrer" style={{color:"blue"}}>Sky Go</a></td>
                    				<td>xxxxxx@gmail.com</td>
                    				<td>TVuser@xxxY</td>
                    			</tr>
                          <tr>
                    				<td><a href="https://home.bt.com/login/loginform" target="_blank" rel="noopener noreferrer" style={{color:"blue"}}>BT Sports</a></td>
                    				<td>xxxxxx@gmail.com</td>
                    				<td>TVuser@xxxY</td>
                    			</tr>
                          <tr>
                    				<td><a href="https://www.nowtv.com/" target="_blank" rel="noopener noreferrer" style={{color:"blue"}}>Now TV</a></td>
                    				<td>xxxxxx@gmail.com</td>
                    				<td>TVuser@xxxY</td>
                    			</tr>
                    		</tbody>
                    	</table>
                  </div>
                </AccordionItem>

                <AccordionItem title={"15. Can I connect a Smart TV?"}  style={styles}  expanded={false}>
                  <div className="toggle-content">
                    <p className="clearfix">
                      Yes, all the UK apps will work on your Smart TV including live BBC and ITV when connected to the UK TV router either wireless or via Ethernet cable.
                    </p>
                  </div>
                </AccordionItem>

                <AccordionItem title={"16. Can I watch BT Sport?"}  style={styles}  expanded={false}>
                  <div className="toggle-content">
                    <p className="clearfix">
                      The best way to do this, if you have a BT Sport UK account, is to download their app to your smartphone whilst connected to the UK TV router, log in and mirror/cast to your Smart TV. If your TV does not support mirroring/casting then buy a chromecast in the UK, UK Amazon is best and then you can cast anything from your phone to the TV.
                    </p>
                  </div>
                </AccordionItem>

                <AccordionItem title={"17. Can I use Chromecast?"}  style={styles}  expanded={false}>
                  <div className="toggle-content">
                    <p className="clearfix">
                      Yes, but make sure you purchase it from any UK online store, Amazon, Argos etc.
                    </p>
                  </div>
                </AccordionItem>

                <AccordionItem title={"18. Which services are supported with my devices?"}  style={styles}  expanded={false}>
                  <div className="toggle-content">

                      Devices and Apps:
                      <br/>
                      <br/>
                      <br/>
                      <h3>Smart TV</h3>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>App</th>
                            <th>Live TV?</th>
                            <th>Catch up</th>
                            <th>On Demand</th>
                            <th>Pay or Free</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>BBC iPlayer</td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td>Free</td>
                          </tr>
                          <tr>
                            <td>ITV Hub</td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td>Free/or pay for no adds</td>
                          </tr>
                          <tr>
                            <td>All 4</td>
                            <td>Use TVGuide.co.uk</td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td>Free</td>
                          </tr>
                          <tr>
                            <td>Demand 5</td>
                            <td>Use TVGuide.co.uk</td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td>Free</td>
                          </tr>
                          <tr>
                            <td>Netflix</td>
                            <td>Not Applicable</td>
                            <td>Not Applicable</td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td>Paid</td>
                          </tr>
                          <tr>
                            <td>Amazon</td>
                            <td>Not Applicable</td>
                            <td>Not Applicable</td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td>Paid</td>
                          </tr>
                          <tr>
                            <td>BT Sport</td>
                            <td>Mirror / Cast</td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td>Paid</td>
                          </tr>
                          <tr>
                            <td>TV Guide.co.uk</td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-remove text-danger"></i></td>
                            <td>Free</td>
                          </tr>
                        </tbody>
                      </table>

                      <br/>
                      <br/>
                      <br/>
                      <h3>Smartphone and Tablets</h3>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>App</th>
                            <th>Live TV?</th>
                            <th>Catch up</th>
                            <th>On Demand</th>
                            <th>Pay or Free</th>
                            <th>Screen Cast to TV supported</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>BBC iPlayer</td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td>Free</td>
                            <td>Own App on Smart TV</td>
                          </tr>
                          <tr>
                            <td>ITV Hub</td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td>Free/or pay for no adds</td>
                            <td>Own App on Smart TV</td>
                          </tr>
                          <tr>
                            <td>All 4</td>
                            <td>Use TVGuide.co.uk</td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td>Free</td>
                            <td>Own App on Smart TV</td>
                          </tr>
                          <tr>
                            <td>Demand 5</td>
                            <td>Use TVGuide.co.uk</td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td>Free</td>
                            <td>Own App on Smart TV</td>
                          </tr>
                          <tr>
                            <td>Netflix</td>
                            <td>Not Applicable</td>
                            <td>Not Applicable</td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td>Paid</td>
                            <td>Own App on Smart TV</td>
                          </tr>
                          <tr>
                            <td>Amazon</td>
                            <td>Not Applicable</td>
                            <td>Not Applicable</td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td>Paid</td>
                            <td>Own App on Smart TV</td>
                          </tr>
                          <tr>
                            <td>*Sky Go</td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td>Paid</td>
                            <td>Not Supported</td>
                          </tr>
                          <tr>
                            <td>BT Sport</td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td>Paid</td>
                            <td><i className="fa fa-check text-success"></i></td>
                          </tr>
                          <tr>
                            <td>TV Guide.co.uk</td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-remove text-danger"></i></td>
                            <td>Free</td>
                            <td>better quality from TV browser</td>
                          </tr>
                        </tbody>
                      </table>
                      <small className="text-warning">*Phone must be on flight mode with wifi only connected to UK TV router</small>

                      <br/>
                      <br/>
                      <br/>
                      <h3>Laptops and Computers</h3>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>App</th>
                            <th>Live TV?</th>
                            <th>Catch up</th>
                            <th>On Demand</th>
                            <th>Pay or Free</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>BBC iPlayer</td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td>Free</td>
                          </tr>
                          <tr>
                            <td>ITV Hub</td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td>Free/or pay for no adds</td>
                          </tr>
                          <tr>
                            <td>All 4</td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td>Free</td>
                          </tr>
                          <tr>
                            <td>Demand 5</td>
                            <td>Use TVGuide.co.uk</td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td>Free</td>
                          </tr>
                          <tr>
                            <td>Netflix</td>
                            <td>Not Applicable</td>
                            <td>Not Applicable</td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td>Paid</td>
                          </tr>
                          <tr>
                            <td>Amazon</td>
                            <td>Not Applicable</td>
                            <td>Not Applicable</td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td>Paid</td>
                          </tr>
                          <tr>
                            <td>Sky Go</td>
                            <td>On Browser that supports Windows Silverlight, Microsoft Explorer</td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td>Paid</td>
                          </tr>
                          <tr>
                            <td>BT Sport</td>
                            <td>mirroring/casting from phone to smart TV or Chromecast</td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td>Paid</td>
                          </tr>
                          <tr>
                            <td>TV Guide.co.uk</td>
                            <td><i className="fa fa-check text-success"></i></td>
                            <td>Not Applicable</td>
                            <td>Not Applicable</td>
                            <td>Free</td>
                          </tr>
                        </tbody>
                      </table>

                  </div>
                </AccordionItem>

                <AccordionItem title={"19. How do I renew my subscription?"}  style={styles}  expanded={false}>
                  <div className="toggle-content">
                    <p className="clearfix">
                      You will have created a user account in making your initial purchase and given us your email address. We will email you 15 days before your subscription runs out and again before it goes off. All you have to do to add subscription time is login at <a href="http://www.uktvrouter.com/login" target="_blank"  rel="noopener noreferrer">www.uktvrouter.com</a> and choose which subscription plan suits you best.
                    </p>
                  </div>
                </AccordionItem>

                <AccordionItem title={"20. What subscription plans are available?"}  style={styles}  expanded={false}>
                  <div className="toggle-content">
                    <ul>
                      <li>Monthly recurring plan @ 10€/month</li>
                      <li>Three months @ 40€</li>
                      <li>Six months @ 60€</li>
                      <li>Twelve Months @ 110€</li>
                    </ul>
                  </div>
                </AccordionItem>

                <AccordionItem title={"21. Is it legal?"}  style={styles}  expanded={false}>
                  <div className="toggle-content">
                    <p className="clearfix">
                      Yes, what you are buying is a VPN network security device which happens to house the VPN servers in the UK. When connected to this network no one can see your local IP. As a by-product your public IP on any device connected to this network will be in the UK which will in turn stop any geo location blocking.
                    </p>
                  </div>
                </AccordionItem>

                <AccordionItem title={"22. Will the internet be slower?"}  style={styles}  expanded={false}>
                  <div className="toggle-content">
                    <p className="clearfix">
                      On a speed test against your ISP you will notice a slight drop, this is due to routing via the UK. As long as you have 2.5 Mbps it will be sufficient for SD TV and 3.5 Mbps for HD. Most of the apps you use like BBC iPlayer, Netflix, ITV Hub etc are intelligent and adjust the quality automatically to ensure a smooth uninterrupted viewing experience. If you have hi speed fibre optic you get more than sufficient bandwidth from the UK TV Router to stream on multiple devices.
                    </p>
                  </div>
                </AccordionItem>

                <AccordionItem title={"23. How long will dispatch take?"}  style={styles}  expanded={false}>
                  <div className="toggle-content">
                    <p className="clearfix">
                      Dispatch from our office is normally be within 1 working day of placing your order.
                    </p>
                  </div>
                </AccordionItem>

                <AccordionItem title={"24. How long will delivery take?"}  style={styles}  expanded={false}>
                  <div className="toggle-content">
                    <p className="clearfix">
                      You will receive an email from the courier with the tracking information. Delivery is normally 24 - 48 hrs to mainland Europe.
                    </p>
                  </div>
                </AccordionItem>

                <AccordionItem title={"25. What is your returns policy?"}  style={styles}  expanded={false}>
                  <div className="toggle-content">
                    <ul>
                      <li>If you have changed your mind by the time the goods reach you, you have 7 days to email us and request a refund, goods must then be returned un-opened and in their original packaging.</li>
                      <li>Please do not return goods without first contacting us for a returns number, please also state in your email the reason for return. In this case all postage fees will be paid by the customer and an additional 30€ fee will be retained for the configuration time and first month server charges.</li>
                      <li>Faulty goods may be returned & exchanged for new during the first 30 days from delivery date.</li>
                      <li>Please do not return goods without first contacting us for a returns number, please also state in your email the reason for return. If the goods are deemed to have been faulty when shipped then we will cover the postage.</li>
                    </ul>
                  </div>
                </AccordionItem>


              </Accordion>


            </div>



            <div className="container">
              <br/>
              <br/>
              <br/>
              <hr />
              <div className="col-md-12">
                <ToastContainer autoClose={3000}/>
                <h4><strong>Ask a question</strong></h4>
                <form ref="questionForm" className="sky-form clearfix" onSubmit={this.handleQuestion}>

                  <label className="input">
                    <i className="ico-prepend fa fa-user"></i>
                    <input type="text"  onChange={this.handleInputChange} name="name" id="name" ref="name" placeholder="Name"/>
                  </label>

                  <label className="input">
                    <i className="ico-prepend fa fa-envelope"></i>
                    <input type="text"  onChange={this.handleInputChange} name="email" id="email" ref="email" placeholder="Email"/>
                  </label>

                  <label className="textarea">
                    <i className="ico-prepend fa fa-comment"></i>
                    <textarea rows="3"  onChange={this.handleInputChange} name="question" id="question" ref="question" placeholder="Type your question..."></textarea>
                  </label>

                  <button type="submit" className="btn btn-primary btn-sm float-right">SUBMIT YOUR QUESTION</button>

                </form>

                <hr />

              </div>
            </div>


          </div>

        </div>
      </section>
      <Footer/>
      </div>
    );
  }

};


function mapStateToProps(state) {
  if (state && state.auth && state.auth.askedQuestion) {

  }
  const props = {
    askedQuestion : state.auth.askedQuestion,
    askedAnswer : state.auth.askedAnswer
  }
  //console.log(props);
  return props;
}

function mapDispatchToProps(dispatch) {
  return  bindActionCreators(actions, dispatch);
}

Faq = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Faq));

export default (Faq);
