import React, {Component} from 'react';
import IframeComm from 'react-iframe-comm';


class SecureIframe extends Component  {


  constructor(props){
    super(props);
    this.onReceiveMessage = this.onReceiveMessage.bind(this);
    this.onReady = this.onReady.bind(this);
    this.state = {
      attributes : {
              src: props.sourceURL,
              width: "100%",
              height: "100%",
              frameBorder: 0
      }
    }
  }

  onReceiveMessage = (event) => {
    if(event.data && event.data ===("3DS-authentication-complete")) {
      this.props.completed3DS()
    }
  };

  // iframe has loaded
  onReady = (event) => {
      //console.log("onReady");
      if(this.iframeSecure) {
        //console.dir(this.iframeSecure);
      }
  };


  render() {
   return (
     <IframeComm
         ref={(frame) => { this.iframeSecure = frame }}
         attributes={this.state.attributes}
         handleReady={this.onReady}
         handleReceiveMessage={this.onReceiveMessage}
     />
      )
    }
};


export default SecureIframe;
