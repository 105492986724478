import React, {Component} from 'react';
import {reduxForm, Field} from 'redux-form';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { withRouter } from 'react-router-dom';


class ResetPassword extends Component  {

  handleFormSubmit(formProps) {
    formProps.hash = this.props.match.params.hash;
    //console.log(formProps);
    this.props.savePassword(formProps, this.props.history);
    //console.log(this.state.form.registrationForm.values);
    //this.boundActionCreators.signupUser(formProps);
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          {this.props.errorMessage}
        </div>
      );
    }
  }

  render() {

    const { handleSubmit, submitting, fields: { password, passwordMatch }} = this.props
    return (
      <section>
      <div className="container">
                <label className="fs-20"><i className="glyphicon glyphicon-user"></i> Reset your Password</label>
                <div className="toggle-content">
                  <form className="m-0 sky-form" onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>

                    <fieldset>

                      <div className="row">

                        <div className="col-md-6 col-sm-6">
                          <label htmlFor="register:pass1">New Password *</label>
                          <label className="input mb-10">
                            <i className="ico-append fa fa-lock"></i>

                            <Field {...password} name="password" required="" type="password" component={renderField} className="err"/>
                            <b className="tooltip tooltip-bottom-right">Min. 6 characters</b>
                          </label>
                        </div>

                        <div className="col-md-6 col-sm-6">
                          <label htmlFor="register:pass2">New Password Again *</label>
                          <label className="input mb-10">
                            <i className="ico-append fa fa-lock"></i>
                            <Field {...passwordMatch} name="passwordMatch" required="" type="password" component={renderField} className="err"/>
                            <b className="tooltip tooltip-bottom-right">Type the password again</b>
                          </label>
                        </div>

                      </div>

                      <hr />

                    </fieldset>
                    {this.renderAlert()}
                    <div className="row">
                      <div className="col-md-12">
                        <button type="submit" className="btn btn-primary" disabled={submitting}><i className="fa fa-check"></i> RESET PASSWORD</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
  			</section>
    );
  }
};


const renderField = ({
  input,
  label,
  type,
  className,
  pattern,
  meta: { touched, error, warning }
}) => (
  <div>
      <input {...input} placeholder={label} type={type} className={className} pattern={pattern}/>
      {touched &&
        ((error && <span style={{color:'red'}}>{error}</span>) ||
          (warning && <span>{warning}</span>))}
  </div>
)

function validate(values) {
  const errors = {};

  if (!values.password) {
    errors.password = 'Password is required'
  } else if (values.password.length < 6) {
    errors.password = 'Must be 6 characters or more'
  } else if (!values.passwordMatch) {
    errors.passwordMatch = 'Confirm password is required'
  } else if (values.password !== values.passwordMatch) {
    errors.passwordMatch = 'Passwords do not match'
  }

  return errors;
}


const mapStateToProps = (state) => {
  console.log(state);
  if(state.form.resetPasswordForm) {
    return {
      formValues : state.form.resetPasswordForm.values,
      errorMessage : state.auth.errorMessage
    };
  } else {
    return {
      errorMessage : state.auth.errorMessage
    };
  }
  
};


ResetPassword = connect(
    mapStateToProps,
    actions
)(withRouter(ResetPassword));

export default reduxForm({
  validate,
  form: 'resetPasswordForm',
  fields: ['password', 'passwordMatch']
})(ResetPassword);
