import React, { Component } from "react";
import MediaPlayer from "./MediaPlayer";
import { isMobile } from "react-device-detect";
import Header from "./Header";
import Footer from "./Footer";

const mod = (num, max) => ((num % max) + max) % max;
const playlist = [
  {
    src:
      "https://eu2.contabostorage.com/0719335084e9416c88f52dc33b7e69a4:images/UKTVRouterExplainerVideoHowItWorks.mp4",
    label: " UK TV Router Explained ",
  },
  {
    src:
      "https://eu2.contabostorage.com/0719335084e9416c88f52dc33b7e69a4:images/UnboxingConnectingUKTVRouterInSpainv1.mp4",
    label: " Unboxing and Installing your UK TV Router ",
  },
  {
    src:
      "https://eu2.contabostorage.com/0719335084e9416c88f52dc33b7e69a4:images/DemoOfLiveAndCatchUpUKTVAbroad.mp4",
    label: " UK TV router with Smart TV Full demo video  ",
  },
  {
    src:
      "https://eu2.contabostorage.com/0719335084e9416c88f52dc33b7e69a4:images/HowToConnectSkyOnDemandWirelessInSpain.mp4",
    label: " Connecting your Sky+ HD to the network ",
  },
  {
    src:
      "https://eu2.contabostorage.com/0719335084e9416c88f52dc33b7e69a4:images/HowToWatchSkyOnDemandSpain.mp4",
    label: " Sky-On-Demand in Spain ",
  },
];

class Playlist extends Component {
  _handleTrackClick(track) {
    this.props.onTrackClick(track);
  }

  render() {
    const { tracks, currentTrack } = this.props;
    return (
      <aside className="media-playlist mt-30">
        <header className="media-playlist-header">
          <h3 className="media-playlist-title">Demonstration Videos</h3>
        </header>
        <ul className="media-playlist-tracks">
          {tracks.map((track) => (
            <li
              key={track.label}
              style={{ cursor: "pointer" }}
              className={`media-playlist-track ${
                track === currentTrack ? "is-active" : ""
              }`}
              onClick={this._handleTrackClick.bind(this, track)}
            >
              {track.label}
            </li>
          ))}
        </ul>
      </aside>
    );
  }
}

class Support extends Component {
  state = {
    currentTrack: {
      src:
        "https://eu2.contabostorage.com/0719335084e9416c88f52dc33b7e69a4:images/UKTVRouterExplainerVideoHowItWorks.mp4",
      label: " UK TV Router Explained ",
    },
    showMediaPlayer: true,
    repeatTrack: false,
    autoPlay: false,
  };

  _handleTrackClick = (track) => {
    this.setState({ autoPlay: true });
    if (this.refs.top) {
      this.refs.top.scrollIntoView({ behavior: "smooth" });
    }
    setTimeout(() => {
      this.setState({ currentTrack: track });
      setTimeout(
        () => {
          this.setState({ autoPlay: false });
        },
        isMobile ? 1000 : 1000
      );
    }, 500);
  };

  _navigatePlaylist = (direction) => {
    const newIndex = mod(
      playlist.indexOf(this.state.currentTrack) + direction,
      playlist.length
    );
    this.setState({ currentTrack: playlist[newIndex] });
  };

  componentDidMount() {
    //console.log("Called Here Too ******");
    const { hash } = window.location;
    if (hash !== "") {
      setTimeout(() => {
        const id = hash.replace("#", "");
        //console.log("Support ID is " + id );
        if (id === "top") {
          if (this.refs.top) {
            this.refs.top.scrollIntoView({ behavior: "smooth" });
          }
        }
      }, 1000);
    }
  }

  // render() {
  //   const { showMediaPlayer, currentTrack, repeatTrack, autoPlay } = this.state;
  //   return (
  //     <div>
  //       <Header />
  //       <div className="container-fluid" style={{ padding: "0 0 0 0" }}>
  //         <section ref="top" id="top">
  //           <div className="container">
  //             <header className="text-center mb-60">
  //               <h2>Support</h2>
  //               <p className="lead font-lato">
  //                 Here are a few videos to help you with some common setup
  //                 issues
  //               </p>
  //               <hr />
  //             </header>
  //             <Playlist
  //               tracks={playlist}
  //               currentTrack={currentTrack}
  //               onTrackClick={this._handleTrackClick}
  //             />
  //           </div>
  //         </section>
  //       </div>
  //       <Footer />
  //     </div>
  //   );
  // }
  render() {
    const { showMediaPlayer, currentTrack, repeatTrack, autoPlay } = this.state;
    return (
      <div>
        <Header />
        <div className="container-fluid" style={{ padding: "0 0 0 0" }}>
          <section ref="top" id="top">
            <div className="container">
              <header className="text-center mb-60">
                <h2>Support</h2>
                <p className="lead font-lato">
                  Here are a few videos to help you with some common setup
                  issues
                </p>
                <hr />
              </header>
              {showMediaPlayer && (
                <div className="media-player-wrapper col-lg-12 col-md-12 col-sm-12">
                  <MediaPlayer
                    ref={(c) => (this._mediaPlayer = c)}
                    src={currentTrack.src}
                    autoPlay={autoPlay}
                    loop={repeatTrack}
                    currentTrack={currentTrack.label}
                    repeatTrack={repeatTrack}
                    onPrevTrack={() => this._navigatePlaylist(-1)}
                    onNextTrack={() => this._navigatePlaylist(1)}
                    onRepeatTrack={() => {
                      this.setState({ repeatTrack: !repeatTrack });
                    }}
                    onPlay={() =>
                      !autoPlay && this.setState({ autoPlay: true })
                    }
                    onPause={() => this.setState({ autoPlay: false })}
                    onEnded={() => !repeatTrack && this._navigatePlaylist(1)}
                  />
                  <Playlist
                    tracks={playlist}
                    currentTrack={currentTrack}
                    onTrackClick={this._handleTrackClick}
                  />
                </div>
              )}
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Support;
