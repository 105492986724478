import React, {Component} from 'react';
import { withRouter } from 'react-router-dom'
import {isMobile} from 'react-device-detect';

class About extends Component {

  componentDidMount() {
    //console.log("Called Here ******");
    const { hash } = window.location;
    if (hash !== '') {
      setTimeout(() => {
        const id = hash.replace('#', '');
        //console.log("About ID is " + id );
        if (id === "about") {
          if(this.refs.about) {
            this.refs.about.scrollIntoView({ behavior: "smooth" });
            this.refs.about.scrollTop += 40;
          }
        }
      }, isMobile ? 1000 : 1000);
    }
  }

  render() {
    return (
      <section id="about" ref="about">
  				<div className="container">
  					<header className="text-center mb-60">
  						<h2>We Are UK TV Router</h2>
  						<p className="lead font-lato">We're here to entertain you!</p>
  						<hr />
  					</header>


  					<div className="row">

  						<div className="col-sm-6">
  							<img className="img-fluid" src="assets/images/uktvrouter/streaming-side.png" alt="" />
                <br/>
                <br/>
                <div className="row countTo-sm text-center">

  								<div className="col-xs-6 col-sm-4">
  									<i className="fa fa-users fs-20"></i> &nbsp;
  									<span className="countTo" data-speed="3000" style={{color:"#59BA41"}}>1000</span><span style={{fontSize:"20pt"}}>+</span>
  									<h6>HAPPY CLIENTS</h6>
  								</div>

  								<div className="col-xs-6 col-sm-4">
  									<i className="fa fa-globe fs-20"></i> &nbsp;
  									<span className="countTo" data-speed="3000" style={{color:"#774F38"}}>25</span><span style={{fontSize:"20pt"}}>+</span>
  									<h6>COUNTRIES SUPPORTED</h6>
  								</div>

  								<div className="col-xs-6 col-sm-4">
  									<i className="fa fa-television fs-20"></i> &nbsp;
  									<span className="countTo" data-speed="3000" style={{color:"#C02942"}}>180</span><span style={{fontSize:"20pt"}}>+</span>
  									<h6>CHANNEL CAPABILITY</h6>
  								</div>
  							</div>
  						</div>

  						<div className="col-sm-6">
  							<p className="dropcap">
                The UK TV Router is probably the best VPN router service in Europe. <b>It is not a Set Top Box with a remote control.</b> Its job is purely to provide you with a network with a UK IP address which allows you to connect your devices and they will appear to be in the UK, allowing you to use all the UK geo restricted Apps such as BBC iPlayer, ITV Hub, etc.
                </p>
                <p>
                Once you have a UK TV VPN Router in your home abroad, you won’t look back.
                </p>
                <ul>
                <li>Your own fixed UK IP from our own private networks</li>
                <li>Use: Smart TV, Amazon Fire Stick, Sky+ HD, Sky Q, Now TV, Play Station, Freesat, Smart phones, Tablets etc.</li>
                <li>Get live and Catch Up TV from, BBC, ITV, Sky, Sky Sport, BT Sport</li>
                <li>Guaranteed no server overload</li>
                <li>No services blocked</li>
                <li>Compatible with all internet connectable devices</li>
                <li>Easy, 2 minutes, plug and play installation. (instructions in the box)</li>
                <li>Connect unlimited devices anywhere, Spain, France, Italy, Europe, USA, Australia</li>
                <li>Post Brexit – be anywhere in Europe but still appear to be in the UK</li>
                <li>Get connected with UK TV Router</li>
                <li>You don’t need to believe us; just you read our verified customer trust pilot reviews at the foot of the page.</li>
                <li>How do I get one? – Scroll down to, or click on pricing, choose your preferred subscription, place your order and we will whizz one off to you with DHL in maximum 2 working days.</li>
                </ul>
  							<hr />


  						</div>
  					</div>
  				</div>
  			</section>
    );
  }
};

export default withRouter(About);
