import React, {Component} from 'react';
import {reduxForm, Field, SubmissionError} from 'redux-form';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { withRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import Header from './Header'
import Footer from './Footer'
// import {Link} from 'react-router-dom';

class Login extends Component {

  handleFormSubmit(formProps) {
    formProps.username = formProps.username.toLowerCase();
    //console.log(formProps);
    this.props.signinUser(formProps, this.props.history);
    //console.log(this.state.form.registrationForm.values);
    //this.boundActionCreators.signupUser(formProps);
  }

  state = {
    resetEmail:""
  }

  handleResetPassword(e) {
    e.preventDefault()
    const errors = {};
    if (!this.state.resetEmail) {
      errors.email = 'An email is Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.resetEmail)) {
      errors.email = 'Invalid email address'
    }
    if (errors.email !== undefined) {
      throw new SubmissionError({
        email: errors.email,
        _error: 'Reset failed!'
      })
    } else {
      //console.log(this.state.resetEmail);
      this.props.resetPassword({email:this.state.resetEmail}, this.props.history);
    }
  }

  constructor(props) {
    super(props);
    this.handleEmailChange = this.handleEmailChange.bind(this);
  }

  handleEmailChange(event) {
    const target = event.target;
    //const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    //console.log(name);
    //console.log(value);
    this.setState({
      resetEmail:value
    })
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops!</strong> {JSON.stringify(this.props.errorMessage)}
        </div>
      );
    }
  }

  renderReset() {
    if (this.props.resetMessage) {
      return (
        <div className="alert alert-warning float-left">
          {this.props.resetMessage}
        </div>
      );
    }
  }

  render() {
    const { handleSubmit, submitting, fields: { email, password }} = this.props
    return (
      <div>
      <Header/>
      <section>
          <div id="resetModal" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="resetModal" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">

                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                  <h4 className="modal-title" id="myModalLabel">Reset Password</h4>
                </div>

                <div className="modal-body">
                  <h4>To reset your password:</h4>
                  <p>Please enter your email and we will send you a reset link.</p>
                  <form className="sky-form" >
                    <div className="row">
                      <div className="col-md-12 col-sm-12 mt-0">
                        <label htmlFor="newUser:email">Enter your Email address</label>
                        <input className="form-control" type="text" id="resetEmail" name="resetEmail" value={this.state.resetEmail} onChange={this.handleEmailChange} required={true} autoComplete="false"/>
                      </div>
                    </div>
                    <div className="row">
                      <button type="submit" className="btn btn-primary  float-right" onClick={(event) => this.handleResetPassword(event)}>Send Email</button>
                    </div>
                  </form>
                </div>
                {this.renderReset()}
                <div className="modal-footer">
                  <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                </div>

              </div>
            </div>
          </div>

  				<div className="container" style={{paddingTop:"10px"}}>

  					<div className="row  justify-content-center">

  						<div className="col-md-6 col-sm-6">

  							<form className="sky-form boxed"  onSubmit={handleSubmit(this.handleFormSubmit.bind(this))} >

  								<header className="fs-18 mb-20">
  									I am a returning customer
  								</header>
                  <footer>

  									No account yet?
                    <Link to='/#pricing' className="external">
                         <span> Click here to buy a product first</span>
                    </Link>
  								</footer>
  								<fieldset className="m-0">

  									<label className="input mb-10">
  										<i className="ico-append fa fa-envelope"></i>
  										<Field {...email} name="username" label="email" required={true} type="text" component={renderField}/>
  										<b className="tooltip tooltip-bottom-right">Needed to verify your account</b>
  									</label>

  									<label className="input mb-10">
  										<i className="ico-append fa fa-lock"></i>
  										<Field {...password} label="Enter Password" name="password" required={true} type="password" component={renderField} />
  										<b className="tooltip tooltip-bottom-right">Only latin characters and numbers</b>
  									</label>

  								</fieldset>

  								<footer>
                    <a href={() => false} className="float-left rad-0 btn btn-warning ml-0" data-toggle="modal" data-target="#resetModal">Reset Password</a>
  									<button type="submit" disabled={submitting} className="btn btn-primary rad-0 float-right"><i className="fa fa-check"></i>LOG IN</button>
  								</footer>

  							</form>

  							{this.renderAlert()}

  						</div>

  					</div>


  				</div>
  			</section>
        <Footer/>
        </div>
    );
  }
};

const renderField = ({
  input,
  label,
  type,
  className,
  pattern,
  meta: { touched, error, warning }
}) => (
  <div>
      <input {...input} placeholder={label} type={type} className={className} pattern={pattern} required={true}/>
      {touched &&
        ((error && <span style={{color:'red'}}>{error}</span>) ||
          (warning && <span>{warning}</span>))}
  </div>
)


function validate(values) {
  const errors = {};

  if (!values.username) {
    errors.username = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)) {
    errors.username = 'Invalid email address'
  }

  if (!values.password) {
    errors.password = 'Password is required'
  }

  return errors;
}

const mapStateToProps = (state) => {
  //console.log("State " + state);
  if (state.form.loginForm) {
    return {
    formValues : state.form.loginForm.values,
    errorMessage : state.auth.errorMessage,
    resetMessage : state.auth.resetMessage
    }
  } else {
    return {
    errorMessage : state.auth.errorMessage,
    resetMessage : state.auth.resetMessage
    }
  }
};

Login = connect(
    mapStateToProps,
    actions
)(withRouter(Login));

export default reduxForm({
  validate,
  form: 'loginForm',
  fields: ['email', 'password']
})(Login);
