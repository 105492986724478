import {
  GET_DEFAULTS,
  GET_DEFAULTS_FAILED
} from '../actions/types';

export default function(state = {}, action) {
  switch (action.type) {
    case GET_DEFAULTS:
    if (action.payload.length > 0) {
      action.payload[0].affiliateID = null
      return action.payload || false;
    } else {
      return action.payload || false;
    }
    case GET_DEFAULTS_FAILED:
      return { ...state, defaultsMessage: "A server error has occurred" };
    default:
      return state;
  }
}
