import axios from "axios";

import {
  COLLECT_ME,
  FAILED_COLLECT_ME,
  UPDATE_USER,
  UPDATE_USER_FAILED,
  COLLECT_CARDS,
  FAILED_COLLECT_CARDS,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_FAILED,
  DELETE_CARD,
  PURCHASE_ADDITIONAL_ITEM,
  PURCHASE_ADDITIONAL_ITEM_FAILED,
  PURCHASE_ADDITIONAL_ITEM_URL,
  FAILED_ADD_CARD,
  PAYMENT_URL,
  CHECK_SUBSCRIPTION,
  CHECK_SESSION,
} from "../actions/types";

export const getMe = () => async (dispatch) => {
  var token = sessionStorage.getItem("jwtToken");
  console.log("COLLECT ME");
  axios.defaults.headers.common["Authorization"] = `JWT ${token}`;
  axios.defaults.headers.post["Accept"] = "application/json";
  axios
    .get("/api/user")
    .then(function(response) {
      //console.log("DATA RESPONSE");
      //console.dir(response);
      dispatch({ type: COLLECT_ME, payload: response.data });
    })
    .catch(function(error) {
      //console.log("DATA ERROR");
      //console.dir(error);
      dispatch({ type: FAILED_COLLECT_ME, payload: error.data });
    });
};

export const deleteMyCard = ({ card }) => async (dispatch) => {
  //console.log(card);
  var token = sessionStorage.getItem("jwtToken");
  //console.log("Delete My Card");
  axios.defaults.headers.common["Authorization"] = `JWT ${token}`;
  axios.defaults.headers.post["Accept"] = "application/json";
  axios.get("/api/user/card/remove/" + card).then(function(response) {
    //console.log("DATA RESPONSE");
    //console.dir(response);
    dispatch({ type: DELETE_CARD, payload: response.data });
  });
};

export const checkSubscription = () => async (dispatch) => {
  var token = sessionStorage.getItem("jwtToken");
  axios.defaults.headers.common["Authorization"] = `JWT ${token}`;
  axios.defaults.headers.post["Accept"] = "application/json";
  axios.get("/api/subcription/check").then(function(response) {
    console.log("DATA RESPONSE");
    console.dir(response);
    dispatch({ type: CHECK_SUBSCRIPTION, payload: response.data });
  });
};

export const checkSession = (sessionObject) => async (dispatch) => {
  console.log(sessionObject);
  var token = sessionStorage.getItem("jwtToken");
  axios.defaults.headers.common["Authorization"] = `JWT ${token}`;
  axios.defaults.headers.post["Accept"] = "application/json";
  axios.post("/api/session/check", sessionObject).then(function(response) {
    console.log("DATA RESPONSE");
    console.dir(response);
    dispatch({ type: CHECK_SESSION, payload: response.data });
  });
};

export const cancelMySubscription = ({ subscriptionId }) => async (
  dispatch
) => {
  //console.log(subscriptionId);
  var token = sessionStorage.getItem("jwtToken");
  //console.log("Cancel My Subscription");
  axios.defaults.headers.common["Authorization"] = `JWT ${token}`;
  axios.defaults.headers.post["Accept"] = "application/json";
  axios
    .get("/api/cancelSubscription/" + subscriptionId)
    .then(function(response) {
      //console.log("DATA RESPONSE");
      //console.dir(response);
      dispatch({ type: CANCEL_SUBSCRIPTION, payload: response.data });
    })
    .catch(function(error) {
      //console.log("DATA ERROR");
      //console.dir(error);
      dispatch({ type: CANCEL_SUBSCRIPTION_FAILED, payload: error.data });
    });
};

export const updateUser = ({ user }) => async (dispatch) => {
  var token = sessionStorage.getItem("jwtToken");
  //console.log("COLLECT ALL USERS");
  axios.defaults.headers.common["Authorization"] = `JWT ${token}`;
  axios.defaults.headers.post["Accept"] = "application/json";
  axios
    .post("/api/userUpdate", { user })
    .then(function(response) {
      //console.log("DATA RESPONSE");
      //console.dir(response);
      dispatch({ type: UPDATE_USER, payload: response.data });
    })
    .catch(function(error) {
      //console.log("DATA ERROR");
      //console.dir(error);
      dispatch({ type: UPDATE_USER_FAILED, payload: error.data });
    });
};

export const collectCards = () => async (dispatch) => {
  var token = sessionStorage.getItem("jwtToken");
  //console.log("COLLECT ALL USERS");
  axios.defaults.headers.common["Authorization"] = `JWT ${token}`;
  axios.defaults.headers.post["Accept"] = "application/json";
  axios
    .get("/api/user/cards")
    .then(function(response) {
      //console.log("DATA RESPONSE");
      //console.dir(response);
      dispatch({ type: COLLECT_CARDS, payload: response.data });
    })
    .catch(function(error) {
      //console.log("DATA ERROR");
      //console.dir(error);
      dispatch({ type: FAILED_COLLECT_CARDS, payload: error.data });
    });
};

export const addCard = ({ cardToken }) => async (dispatch) => {
  var token = sessionStorage.getItem("jwtToken");
  //console.log("COLLECT ALL USERS");
  axios.defaults.headers.common["Authorization"] = `JWT ${token}`;
  axios.defaults.headers.post["Accept"] = "application/json";
  axios
    .post("/api/user/card", { cardToken })
    .then(function(response) {
      //console.log("DATA RESPONSE");
      //console.dir(response);
      dispatch({ type: COLLECT_CARDS, payload: response.data });
    })
    .catch(function(error) {
      //console.log("DATA ERROR");
      console.dir(error);
      if (error.response.status === 401) {
        dispatch({
          type: FAILED_ADD_CARD,
          payload: error.response.data.error.raw,
        });
      } else {
        dispatch({ type: FAILED_COLLECT_CARDS, payload: error.response.data });
      }
    });
};

export const checkPurchase = (details) => async (dispatch) => {
  //console.log(details);
  var token = sessionStorage.getItem("jwtToken");
  //console.log("Purchase additional Item");
  axios.defaults.headers.common["Authorization"] = `JWT ${token}`;
  axios.defaults.headers.post["Accept"] = "application/json";
  axios
    .post("/api/checkPurchase", details)
    .then(function(response) {
      console.log("DATA RESPONSE");
      console.dir(response);
      dispatch({ type: PURCHASE_ADDITIONAL_ITEM, payload: response.data });
    })
    .catch(function(error) {
      console.dir(error.response.data);
      dispatch({
        type: PURCHASE_ADDITIONAL_ITEM_FAILED,
        payload: error.response.data,
      });
    });
};

export const purchaseItem = (details) => async (dispatch) => {
  //console.log(details);
  var token = sessionStorage.getItem("jwtToken");
  //console.log("Purchase additional Item");
  axios.defaults.headers.common["Authorization"] = `JWT ${token}`;
  axios.defaults.headers.post["Accept"] = "application/json";
  axios
    .post("/api/stripe/purchase", details)
    .then(function(response) {
      console.log("DATA RESPONSE");
      console.dir(response.data);
      if (response.data.url) {
        dispatch({ type: PAYMENT_URL, payload: response.data });
      } else {
        dispatch({ type: PURCHASE_ADDITIONAL_ITEM, payload: response.data });
      }
    })
    .catch(function(error) {
      //console.log("DATA ERROR");
      //console.dir(error);
      if (
        error.response &&
        error.response.status &&
        error.response.status === 402
      ) {
        console.log("Have 3D Secure URL");
        //console.log(error.response.data.url);
        dispatch({
          type: PURCHASE_ADDITIONAL_ITEM_URL,
          payload: error.response.data,
        });
      } else {
        dispatch({
          type: PURCHASE_ADDITIONAL_ITEM_FAILED,
          payload: error.response.data,
        });
      }
    });
};
