import React, {Component} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {connect} from 'react-redux';
import * as actions from '../actions';
import { withRouter } from 'react-router-dom';
import Header from './Header'
import Footer from './Footer'

class Contact extends Component {

  toastId = 1234;
  toastIdSuccess = 3456;

  notify = (success, value) => {
    if (success) {
      if (! toast.isActive(this.toastIdSuccess)) {
              this.toastId = toast.success(value, {
              position: toast.POSITION.BOTTOM_CENTER
          });
      }
    } else {
      if (! toast.isActive(this.toastId)) {
              this.toastId = toast.error(value, {
              position: toast.POSITION.BOTTOM_CENTER
          });
      }
    }

  }

  constructor(props){
    super(props);
    this.handleQuestion = this.handleQuestion.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentWillReceiveProps = (nextProps)=> {
    //console.dir(nextProps);
    if(nextProps) {
      if (nextProps && nextProps.contactedUs && nextProps.contactedUsAnswer) {
        this.notify(true,nextProps.contactedUsAnswer);
        this.refs.questionForm.reset();
        this.props.resetContactUs();
      } else if (nextProps && !nextProps.contactedUs && nextProps.contactedUsAnswer) {
        //console.log("error");
        this.notify(false,nextProps.contactedUsAnswer);
        this.props.resetContactUs();
      }
    }
    this.props.resetContactUs();
  }

  handleQuestion(values) {
    values.preventDefault()
    //console.log("Question");
    var errors = "";
    if (!this.state) {
      errors += 'Please complete the form\n'
      this.notify(false,errors);
      return;
    }
    if (!this.state.email) {
      errors += 'An email is Required\n'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)) {
      errors += 'Invalid email address\n'
    }
    if (!this.state.name) {
      errors += 'Your name is required\n'
    }
    if (!this.state.question) {
      errors += 'A question is required\n'
    }

    if (errors.length > 0) {
      this.notify(false,errors);
      return;
    }
    //console.log({email:this.state.email,name:this.state.name,question:this.state.question});
    this.props.contactUs({email:this.state.email,name:this.state.name,question:this.state.question},this.props.history);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }


  render() {

    return (
      <div>
      <Header/>
      <div className="section">
        <div className="container">
          <br/>
          <br/>
          <br/>
          <hr />
          <div className="col-md-12">
            <ToastContainer autoClose={3000}/>
            <h4><strong>Contact Us</strong></h4>
            <form ref="questionForm" className="sky-form clearfix" onSubmit={this.handleQuestion}>

              <label className="input">
                <i className="ico-prepend fa fa-user"></i>
                <input type="text"  onChange={this.handleInputChange} name="name" id="name" ref="name" placeholder="Name"/>
              </label>

              <label className="input">
                <i className="ico-prepend fa fa-envelope"></i>
                <input type="text"  onChange={this.handleInputChange} name="email" id="email" ref="email" placeholder="Email"/>
              </label>

              <label className="textarea">
                <i className="ico-prepend fa fa-comment"></i>
                <textarea rows="3"  onChange={this.handleInputChange} name="question" id="question" ref="question" placeholder="Type your question..."></textarea>
              </label>

              <button type="submit" className="btn btn-primary btn-sm float-right">SUBMIT YOUR QUESTION</button>

            </form>

            <hr />

          </div>
        </div>
      </div>
      <Footer/>
      </div>
    )
  }

}
function mapStateToProps(state) {
  if (state && state.auth && state.auth.contactedUs) {

  }
  const props = {
    contactedUs : state.auth.contactedUs,
    contactedUsAnswer : state.auth.contactedUsAnswer
  }
  //console.log(props);
  return props;
}

export default connect(mapStateToProps,actions)(withRouter(Contact));
